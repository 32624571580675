import axios from 'axios';
import { API_URL } from '../config/url_envoiriment';

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Se precisar enviar cookies para o backend
  mode: "cors", // Importante para habilitar CORS
});


export const createSession = async (email, password) => {
  return api.post('/login', { email, password });
}

export const forgot = async (email) => {
  console.log(`email para recuperar senha: ${email}`);
  return api.post('/login/forgot', { email });
}
// ....................................USERS..........................
export const getUser = async () => {
  try {
    const response = await api.get('/user')
    // console.log(response)
    return response;
  } catch (error) {
    console.error("Error fetching persons:", error);
    throw error;
  }
};

export const getUserById = async (user_id) => {
  return api.get(`/user/${user_id}`)
}

export const createUser = async (userFormData) => {
  return api.post('/user', userFormData);
}

export const updateUser = async (user_id, formData) => {
  try {
    const response = await api.put(`/user/${user_id}`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async (user_id) => {
  return api.delete(`/user/${user_id}`);
};



export const showUser = async (id) => {
  return api.get(`/user/${id}`)
}


export const resetPassword = async (email, tempPassword, newPassword, confirmNewPassword) => {
  return api.put('/login/resetPassword', { email, tempPassword, newPassword, confirmNewPassword });
}

export const singnUp = async (email, first_name, last_name) => {
  return api.post('/sign', { email, first_name, last_name });
}

export const countNewUser = async () => {
  try {
    const response = await api.get("/user/cnu");
    // console.log("Count User: " + response)
    return response.data;
  } catch (error) {
    throw error;
  }
}

// ..................Persons.................................................................
export const getPerson = async () => {
  try {
    const response = await api.get('/person')
    // console.log(response)
    return response;
  } catch (error) {
    console.error("Error fetching persons:", error);
    throw error;
  }
};

export const showPerson = async (person_id) => {
  return api.get(`/person/${person_id}`)
}

export const createPerson = async (formData) => {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const response = await api.post('/person/', formData, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getPersonById = async (person_id) => {
  return api.get(`/person/${person_id}`)
}


export const updatePerson = async (person_id, formData) => {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const response = await api.put(`/person/${person_id}`, formData, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deletePerson = async (person_id) => {
  try {
    const response = await api.delete(`/person/${person_id}`);
    return response.data;
  } catch (error) {
    throw error;
  }

}

export const getPersonsByType = async (person_type) => {
  return api.get("/persons", { params: { person_type } });
};

export const getPersonList = async () => {
  return api.get('/person/list')
}

export const deletePersonList = async (person_id) => {
  return api.delete(`/person/list${person_id}`);
}

export const getAllPerson = async () => {
  try {
    const response = await api.get('/person')
    // console.log(response)
    return response;
  } catch (error) {
    console.error("Error fetching persons:", error);
    throw error;
  }
};

//........... EMPRESAS - COMPANIES.............................................................................
export const getCompany = async () => {
  try {
    const response = await api.get('/company')
    return response.data;
  } catch (error) {
    console.error("Error fetching company: ", error);
    throw error;
  }
};

export const getCompanyById = async (company_id) => {
  try {
    const response = await api.get(`/company/${company_id}`)
    return response.data;
  } catch (error) {
    console.error("Error fetching company by id: ", error);
    throw error;
  }
}

export const showCompany = async (company_id) => {
  try {
    const response = await api.get(`/company/${company_id}`)
    return response.data;
  } catch (error) {
    console.error("Error fetching company by id: ", error);
    throw error;
  }
}

export const createCompany = async (formData) => {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const response = await api.post('/company/', formData, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCompany = async (company_id, formData) => {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const response = await api.put(`/company/${company_id}`, formData, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteCompany = async (company_id) => {
  return api.delete(`/company/${company_id}`);
}

// .......APPOINTMENTS.............................................................
export const getAppointment = async () => {
  return api.get('/appointment')
}

export const getAppointmentById = async (appointment_id) => {
  const response = await api.get(`/appointment/${appointment_id}`);
  return response.data;
}


export const ShowAppointment = async (appointment_id) => {
  // console.log('Show Appointment to show:' + appointment_id);
  return api.get(`/appointment/${appointment_id}`)
}


export const createAppointment = async (formData) => {
  try {
    const response = await api.post('/appointment', formData);
    return response.data;
  } catch (error) {
    console.error("Error creating appointment:", error);
    throw error;
  }
};

export const updateAppointment = async (appointment_id, formData) => {
  try {
    const response = await api.put(`/appointment/${appointment_id}`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getAppointmentByPersonId = async (person_id, person_type) => {
  return api.get(`/appointment/${person_id}/${person_type}`);
}


export const deleteAppointment = async (id) => {
  return api.delete(`/appointment/${id}`);
}


// ...........PATIENTS...........................................

export const getPatient = async () => {
  return api.get("/patient");
};

export const deletePatient = async (patient_id) => {
  return api.delete(`/patient/${patient_id}`);
};

// ...........DOCTORS...........................................

export const getDoctor = async () => {
  return api.get(`/doctor`);
};

// ...........PROFESSIONAL..........................................

export const getProfessional = async () => {
  try {
    const response = await api.get('/professional')
    return response.data;
  } catch (error) {
    console.error("Error fetching professional: ", error);
    throw error;
  }
};
// export const getProfessional = async () => {
//   return api.get(`/ professional`);
// };

export const getAllProfessionals = async () => {
  try {
    const response = await api.get('/professional/professional/all')
    return response.data;
  } catch (error) {
    console.error("Error fetching all professionals: ", error);
    throw error;
  }
};
// export const getAllProfessionals = async () => {
//   return api.get('/professional/professional/all');
// };

// User Logs.........................................................................
export const getUserLogs = async (user_id) => {
  try {
    const response = await api.get(`/user/logs/${user_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching logs: ", error);
    throw error;
  }
};

export const getUserInfo = async (user_id) => {
  try {
    const response = await api.get(`/user/logs/${user_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching info: ", error);
    throw error;
  }
};

// TACO FOODS API........................................................................................................

export const getAllTacoFoods = async () => {
  try {
    const response = await api.get('/nutridata/atfd');
    // console.log(JSON.stringify(response));
    return response.data;

  } catch (error) {
    console.error("Error fetching  foods: ", error);
    throw error;
  }
};

export const getTacoFoodById = async (food_id) => {
  try {
    const response = await api.get(`/nutridata/atfd/${food_id}`);
    // console.log(JSON.stringify(response));
    return response.data;

  } catch (error) {
    console.error("Error fetching  foods: ", error);
    throw error;
  }
};

export const getFoodWherProteinIsHigher = async () => {
  try {
    const response = await api.get('/nutridata/proteinIsHigher');
    return response.data;
  } catch (error) {
    console.error("Error fetching  foods: ", error);
    throw error;
  }
}

// .......FOODS.....................................................................
export const getAllFoodsAndTacoFoods = async () => {
  try {
    const response = await api.get('/food/foods/allFoods');
    // console.log(JSON.stringify(response));
    return response.data;

  } catch (error) {
    console.error("Error fetching  foods: ", error);
    throw error;
  }
};


// .......ANTHROPOMETRY.....................................................................
export const createAnthropometry = async (formData) => {
  try {
    const response = await api.post('/anthropometry', formData);
    // console.log(formData);
    return response.data;
  } catch (error) {
    console.error("Error creating anthropometry:", error);
    throw error;
  }
};

export const getAnthropometryByPatientId = async (patient_id) => {
  try {
    const response = await api.get(`/anthropometry/${patient_id}`);
    // console.log("Resposta completa da API:", JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar antropometria:", error);
    throw error; // Garante que o erro será tratado no `catch` da ação
  }
}

//..........CONSULTAS / CONSULTATIONS .........................................
export const getConsultation = async () => {
  try {
    const response = await api.get('/consultation');
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error("Error fetching consultations:", error);
    throw error;
  }
};

export const getConsultationById = async (consultation_id) => {
  try {
    const response = await api.get(`/consultation/${consultation_id} `);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(`Error fetching consultation with ID ${consultation_id}: `, error);
    throw error;
  }
};

export const showConsultation = async (consultation_id) => {
  try {
    // console.log('Show Consultation to show: ' + consultation_id);
    const response = await api.get(`/consultation/${consultation_id} `);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(`Error showing consultation with ID ${consultation_id}: `, error);
    throw error;
  }
};

export const createConsultation = async (formData) => {
  try {
    const response = await api.post('/consultation', formData);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error("Error creating consultation:", error);
    throw error;
  }
};

export const updateConsultation = async (consultation_id, formData) => {
  try {
    const response = await api.put(`/consultation/update/${consultation_id} `, formData);
    return response.data;
  } catch (error) {
    console.error(`Error updating consultation with ID ${consultation_id}: `, error);
    throw error;
  }
};


export const getConsultationByPersonId = async (person_id, person_type) => {
  try {
    const response = await api.get(`/consultation/${person_id}/${person_type}`);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(`Error fetching consultations for person ID ${person_id} and type ${person_type}:`, error);
    throw error;
  }
};

export const getConsultationByPatientId = async (patient_id) => {
  try {
    const response = await api.get(`/consultation/patient/${patient_id}}`);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(`Error fetching consultation by patient with ID ${patient_id}:`, error);
    throw error;
  }
};


export const getConsultationByAppointmentId = async (appointment_id) => {
  try {
    const response = await api.get(`/consultation/appointment/${appointment_id}}`);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(`Error fetching consultation by appointment with ID ${appointment_id}:`, error);
    throw error;
  }
};

export const deleteConsultation = async (id) => {
  try {
    const response = await api.delete(`/consultation/${id}`);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(`Error deleting consultation with ID ${id}:`, error);
    throw error;
  }
};

// Função para finalizar consulta
// export const completeConsultation = async (consultation_id) => {
//   try {
//     const response = await api.put(`/consultation/complete/${consultation_id}`);
//     return response.data;
//   } catch (error) {
//     throw new Error(error.response?.data?.message || 'Erro ao finalizar consulta.');
//   }
// };

export const completeConsultation = async (consultation_id, status) => {
  try {
    const response = await api.put(`/consultation/complete/${consultation_id}`, { status });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao finalizar consulta.');
  }
};


//..........Dietas / Diet .........................................
export const createDiet = async (formData) => {
  try {
    const response = await api.post('/diet/', formData);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(error.success, error.message, error.error.message);
    throw error;
  }
};

export const getDietById = async (diet_id) => {
  try {
    const response = await api.get(`/diet/${diet_id}`);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(`Error getting diet with ID ${diet_id}:`, error);
    throw error;
  }
};

export const getDietByPatientId = async (patient_id) => {
  try {
    const response = await api.get(patient_id);
    // console.log(`o retorno de dietbypatientId: ${JSON.stringify(response)}`);
    return response;
  } catch (error) {
    console.error(`Error getting diet with ID ${patient_id}:`, error);
    throw error;
  }
};

export const updateDiet = async (diet_id, formData) => {
  try {
    // console.log("Enviando para a API:", diet_id, formData)
    const response = await api.put(`/diet/${diet_id}`, formData);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error("Error creating diet:", error);
    throw error;
  }
};

export const deleteDiet = async (diet_id) => {
  try {
    const response = await api.delete(`/diet/${diet_id}`);
    // console.log(JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error(`Error deleting diet with ID ${diet_id}:`, error);
    throw error;
  }
};
