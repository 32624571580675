import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPersonById } from '../../service/api';


export const fetchPatientDetails = createAsyncThunk(
  'patients/fetchPatientDetails',
  async (patient_id) => {
    const response = await getPersonById((patient_id));
    return response.data; // Retorna apenas os dados relevantes
  }
);
