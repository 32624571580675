import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, deleteUserById } from '../../../redux/actions/userActions';
import { setCurrentUser } from '../../../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { MdDelete, MdCreate } from 'react-icons/md';
import '../styles/GetUser.css';

const GetUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginUser } = useContext(AuthContext);

  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const { users = [], loading, error } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setEllipsis((prev) => prev.length === 5 ? '.....' : '.'.repeat(prev.length + 1));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [loading]);

  const handleUpdateUser = (user_id) => {
    dispatch(setCurrentUser(user_id));
    navigate(`/home/user/update/${user_id}`);
  };

  const handleShowUser = (user_id) => {
    dispatch(setCurrentUser(user_id));
    navigate(`/home/user/show/${user_id}`);
  };

  const handleDeleteUser = (user_id, first_name, last_name) => {
    setUserToDelete(user_id);
    setMessage(`Tem certeza que deseja remover o registro de: ${first_name} ${last_name}?`);
    setShowConfirmModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    setShowConfirmModal(false); // Hide the confirmation modal

    try {
      const resultAction = await dispatch(deleteUserById(userToDelete));

      if (deleteUserById.fulfilled.match(resultAction)) {
        const message = resultAction.payload.message || 'Usuário deletado com sucesso!';
        setMessage(message);

        // Update the local users state to remove the deleted user
        const updatedUsers = users.filter(user => user.user_id !== userToDelete);
        dispatch({ type: 'users/fetchUsers/fulfilled', payload: updatedUsers });

        setShowModal(true); // Show the result modal
      } else {
        const errorMsg = resultAction.error?.message || 'Erro ao deletar usuário. Tente novamente mais tarde.';
        setMessage(errorMsg);
        setShowModal(true); // Show the result modal
      }
    } catch (error) {
      setMessage(error.message || 'Erro ao deletar usuário. Tente novamente mais tarde.');
      setShowModal(true); // Show the result modal
    } finally {
      setIsDeleting(false);
      setTimeout(() => {
        setShowModal(false);
        // You can remove the navigate call since we're updating the state directly.
      }, 2000); // Wait for 2 seconds before hiding the modal
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
    setUserToDelete(null);
  };

  const handleSearch = (text) => setSearchText(text);

  const filteredUser = users.filter(user =>
    `${user.user_id} ${user.first_name} ${user.last_name} ${user.email} ${user.roles}`
      .toLowerCase()
      .includes(searchText.trim().toLowerCase())
  );

  const currentItems = filteredUser.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const hasRole = (role) => loginUser.roles?.includes(role);

  if (loading) return <div>Loading{ellipsis}</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {showConfirmModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}
      {showModal && (
        <ModalForms
          message={message}
          onConfirm={() => setShowModal(false)}
        />
      )}


      <div className="col-md-12">
        <h3>Usuários do Sistema</h3>
        <SearchBar onSearch={handleSearch} />
      </div>

      <div className="boxGetUser">
        <table className='getUserTable'>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Email</td>
              <td>Permissão</td>
              {hasRole('Manager') && <td>Editar</td>}
              {hasRole('Admin') && <td>Ações</td>}
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <td colSpan="8" className="rowMessage">Não localizado nenhum registro correspondente</td>
              </tr>
            ) : (
              currentItems.map((user, user_id) => (
                <tr key={user.user_id}>
                  <td>
                    <button className='tdBtn' onClick={() => handleShowUser(user.user_id)}>
                      {user.first_name} {user.last_name}
                    </button>
                  </td>
                  <td>{user.email}</td>
                  <td>{user.roles}</td>
                  {hasRole('Manager') && (
                    <td className="update">
                      <button className='icoBtn' onClick={() => handleUpdateUser(user.user_id)} data-tip="Editar">
                        <MdCreate className='icon' />
                      </button>
                    </td>
                  )}
                  {hasRole('Admin') && (
                    <td className="delete">
                      <button className='icoBtn' onClick={() => handleUpdateUser(user.user_id)} data-tip="Editar">
                        <MdCreate className='icon' />
                      </button>
                      <button
                        className='icoBtn'
                        onClick={() => handleDeleteUser(user.user_id, user.first_name, user.last_name)}
                        data-tip="Deletar"
                      >
                        <MdDelete className='icon' />
                      </button>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredUser.length / itemsPerPage) }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

    </>
  );
};

export default GetUser;
