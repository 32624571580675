import { createSlice } from '@reduxjs/toolkit';
import {
  fetchConsultations,
  fetchConsultationById,
  fetchConsultationByPersonId,
  fetchConsultationByPatientId,
  fetchConsultationByAppointmentId,
  deleteConsultationById,
  updateConsultationById,
  createNewConsultation,
  putCompleteConsultation,
} from '../actions/consultationActions';

const consultationSlice = createSlice({
  name: 'consultations',
  initialState: {
    consultations: [],
    loading: false,
    error: null,
    currentConsultation: [],
  },
  reducers: {
    setCurrentConsultation: (state, action) => {
      state.currentConsultation = action.payload;
    },
    clearConsultation: (state) => {
      state.consultations = [];
      state.currentConsultation = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConsultations.fulfilled, (state, action) => {
        state.loading = false;
        state.consultations = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(fetchConsultations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchConsultationById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConsultationById.fulfilled, (state, action) => {
        // console.log('Dados retornados pela API:', action.payload);
        state.loading = false;
        state.currentConsultation = action.payload || null;
      })
      .addCase(fetchConsultationById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchConsultationByPersonId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConsultationByPersonId.fulfilled, (state, action) => {
        state.loading = false;
        state.consultations = action.payload;
      })
      .addCase(fetchConsultationByPersonId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchConsultationByPatientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConsultationByPatientId.fulfilled, (state, action) => {
        state.loading = false;
        state.currentConsultation = action.payload; // Atualiza as consultas
      })
      .addCase(fetchConsultationByPatientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Erro ao buscar consultas';
      })
      .addCase(fetchConsultationByAppointmentId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConsultationByAppointmentId.fulfilled, (state, action) => {
        state.loading = false;
        state.consultations = action.payload;
      })
      .addCase(fetchConsultationByAppointmentId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteConsultationById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteConsultationById.fulfilled, (state, action) => {
        state.loading = false;
        state.consultations = state.consultations.filter(consultation => consultation.consultation_id !== action.payload);
      })
      .addCase(deleteConsultationById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateConsultationById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateConsultationById.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.consultation_id) {
          state.consultations = state.consultations.map((consultation) =>
            consultation.consultation_id === action.payload.consultation_id
              ? action.payload
              : consultation
          );
        } else {
          state.message = "Consulta atualizada com sucesso!";
        }
      })
      .addCase(updateConsultationById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createNewConsultation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewConsultation.fulfilled, (state, action) => {
        state.loading = false;
        // console.log('Action payload:', action.payload); 
        if (!Array.isArray(state.consultations)) {
          state.consultations = [];
        }
        state.consultations.push(action.payload);
      })
      .addCase(createNewConsultation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(putCompleteConsultation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putCompleteConsultation.fulfilled, (state, action) => {
        state.loading = false;
        // Atualize o estado da consulta se necessário
        if (state.currentConsultation) {
          state.currentConsultation.status = 'completed';
        }
      })
      .addCase(putCompleteConsultation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCurrentConsultation, clearConsultation } = consultationSlice.actions;
export default consultationSlice.reducer;
