import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAppointmentById, fetchAppointments } from '../../../redux/actions/appointmentsActions';
import { fetchConsultationByAppointmentId } from '../../../redux/actions/consultationActions';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { MdDelete, MdCreate } from 'react-icons/md';
import { FaUserMd, FaCalendar, FaHospitalAlt, FaUser } from "react-icons/fa";
import { FaUserClock, FaClock } from "react-icons/fa6";
import { clearAppointment } from '../../../redux/slices/appointmentSlice';
import { fetchAppointmentById } from '../../../redux/actions/appointmentsActions';

import '../styles/GetAppointment.css';

function GetAppointment() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const icoUpdate = <MdCreate className='icon' />;
  const icoTrash = <MdDelete className='icon' />;
  const icoDoctor2 = <FaUserMd className='icon' />
  const icoHome = <MdCreate className='mobileIcon' />
  const icoDoctor = <FaUserMd className='mobileIcon' />
  const icoTime = <FaUserClock className='mobileIcon' />
  const icoStatus = <FaClock className='mobileIcon' />
  const icoCalendar = <FaCalendar className='mobileIcon' />
  const icoHospital = <FaHospitalAlt className='mobileIcon' />
  const icoPatient = <FaUser className='mobileIcon' />

  const [appointmentIdToDelete, setAppointmentIdToDelete] = useState('');
  const [filteredAppointment, setFilteredAppointment] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const updateBtn = "Editar";
  const deleteBtn = "Deletar";
  const detailsBtn = "Clique para detalhes";
  const startConsult = "Iniciar Consulta";
  const returnConsult = "Acessar Consulta";

  const { appointments = [], loading, error } = useSelector((state) => state.appointments);  // Garantir que appointments seja um array


  const statusOrder = ["in_progress", "confirmed", "scheduled", "changed", "completed", "cancelled"];

  useEffect(() => {
    dispatch(fetchAppointments());
  }, [dispatch]);

  useEffect(() => {
    if (appointments && appointments.length) {
      setFilteredAppointment(appointments);
    }
  }, [appointments]);

  const handleDelete = async (appointment_id) => {
    setAppointmentIdToDelete(appointment_id);
    setMessage(`Tem certeza que deseja remover o registro de: ${appointment_id}`);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await dispatch(deleteAppointmentById(appointmentIdToDelete));
      setShowModal(true);
      setMessage(`Removido o registro ID: ${appointmentIdToDelete} com sucesso :)`);
      setTimeout(() => {
        setShowModal(false);
        setIsDeleting(false);
      }, 2000);
    } catch (error) {
      setMessage(`Erro ao tentar deletar o registro: ${error}`);
      setIsDeleting(false);
    }
  }

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleUpdate = (appointment_id) => {
    navigate(`/home/appointment/update/${appointment_id}`);
  };

  const handleShowAppointment = (appointment_id) => {
    navigate(`/home/appointment/show/${appointment_id}`);
  };

  // const handleStartConsult = (appointment_id) => {
  //   console.log("ID do agendamento recebido:", appointment_id);
  //   if (!appointment_id) {
  //     console.error("O appointment_id está indefinido.");
  //     return;
  //   }
  //   dispatch(clearAppointment());
  //   dispatch(fetchAppointmentById(appointment_id));
  //   navigate(`/home/consultation/create/${appointment_id}`);
  // };

  const handleStartConsult = (appointment_id, patient_id) => {
    // console.log("ID do agendamento ao iniciar consulta:", appointment_id);
    if (!appointment_id) {
      console.error("O appointment_id está indefinido.");
      return;
    }
    dispatch(clearAppointment());
    dispatch(fetchAppointmentById(appointment_id));
    navigate(`/home/consultation/create/${appointment_id}`, {
      state: {
        appointment_id,
        patient_id, // Inclua o ID do paciente para uso em CreateConsultation
      },
    });
  };

  const handleFetchConsultation = async (appointment_id) => {
    try {
      const response = await dispatch(fetchConsultationByAppointmentId(appointment_id));
      const consultationId = response.payload?.consultation_id;
      if (consultationId) {
        navigate(`/home/consultation/show/${consultationId}`);
      } else {
        alert('Nenhuma consulta encontrada para este agendamento.');
      }
    } catch (error) {
      console.error('Erro ao buscar consulta:', error);
      alert('Erro ao buscar consulta. Tente novamente.');
    }
  };



  const formatStatus = (status) => {
    switch (status) {
      case 'scheduled':
        return 'Agendado';
      case 'confirmed':
        return 'Confirmado';
      case 'changed':
        return 'Alterado';
      case 'in_progress':
        return 'Em Andamento';
      case 'completed':
        return 'Realizada';
      case 'cancelled':
        return 'Cancelado';
      default:
        return 'Agendado';
    }
  }

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    const filtered = appointments.filter(appointment =>
      `${appointment.company_name} ${appointment.patient_name} ${appointment.professional_name} ${formatStatus(appointment.status)}`.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredAppointment(filtered);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const getPageButtonClass = (index) => (currentPage === index + 1 ? 'active' : '');

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }

    return () => clearInterval(interval);
  }, [loading]);

  const formatDate = (date) => {
    const d = new Date(date);
    return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
  };


  function formatTime(time) {
    if (!time) return '';  // Verifica se 'time' é indefinido ou null e retorna uma string vazia
    return time.slice(0, 5);
  }

  // const currentItems = filteredAppointment.slice(indexOfFirstItem, indexOfLastItem);

  // Ordenar agendamentos pelo status
  const sortAppointmentsByStatus = (appointments) => {
    return [...appointments].sort((a, b) => {
      const statusIndexA = statusOrder.indexOf(a.status);
      const statusIndexB = statusOrder.indexOf(b.status);

      // Ordenar por status primeiro
      if (statusIndexA !== statusIndexB) {
        return statusIndexA - statusIndexB;
      }

      // Em caso de empate no status, manter ordenação por data (já vem ordenado do banco)
      return 0;
    });
  };

  const currentItems = sortAppointmentsByStatus(
    filteredAppointment.slice(indexOfFirstItem, indexOfLastItem)
  );

  const hasRole = (role) => loginUser.roles && loginUser.roles.includes(role);

  return (
    <>
      {showModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}

      <div className="col-md-12">
        <h3>Agendamentos</h3>
        <SearchBar onSearch={handleSearch} />
      </div>

      <div className="boxGetAppointment">
        <table className='getAppointmentTable'>
          <thead>
            <tr>
              <td>Paciente</td>
              <td>Clínica</td>
              <td>Profissional</td>
              <td>Data do Agendamento</td>
              <td>Data da Consulta</td>
              <td>Hora da Consulta</td>
              <td>Status</td>
              {hasRole('Manager') && <td>Ações</td>}
              {hasRole('Admin') && <td>Ações</td>}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="rowMessage" colSpan="7">Aguarde{ellipsis}</td>
              </tr>
            ) : currentItems.length === 0 ? (
              <tr>
                <td className="rowMessage" colSpan="7">Não localizado nenhum registro correspondente</td>
              </tr>
            ) : (
              currentItems.map((appointment) => (
                <tr
                  key={appointment.appointment_id}
                  className={`tr 
      ${appointment.status === 'cancelled' ? 'cancelled-line' : ''} 
      ${appointment.status === 'scheduled' ? 'scheduled-line' : ''} 
      ${appointment.status === 'changed' ? 'changed-line' : ''}
      ${appointment.status === 'completed' ? 'completed-line' : ''} 
      ${appointment.status === 'confirmed' ? 'confirmed-line' : ''} 
      ${appointment.status === 'in_progress' ? 'in_progress-line' : ''}`}
                >
                  <td><a onClick={() => handleShowAppointment(appointment.appointment_id)} data-tip={detailsBtn}>
                    {icoPatient}{appointment.patient_name}</a></td>
                  <td><a onClick={() => handleShowAppointment(appointment.appointment_id)} data-tip={detailsBtn}>
                    {icoHospital}{appointment.company_name}</a></td>
                  <td><a onClick={() => handleShowAppointment(appointment.appointment_id)} data-tip={detailsBtn}>
                    {icoDoctor} {appointment.professional_name}</a></td>
                  <td>{icoHome} {formatDate(appointment.scheduling_date)}</td>
                  <td>{icoCalendar} {formatDate(appointment.appointment_date)}</td>
                  <td>{icoTime} {formatTime(appointment.appointment_time)}</td>
                  <td>{icoStatus} {formatStatus(appointment.status)}</td>

                  {/* Botões de ação com regras específicas */}
                  {hasRole('Coordinator') || hasRole('User') ? (
                    <td className="actions">
                      {appointment.status !== 'in_progress' && (
                        <button
                          className={
                            appointment.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                          }
                          type="button"
                          onClick={() => handleUpdate(appointment.appointment_id)}
                          data-tip={updateBtn}
                        >
                          {icoUpdate}
                        </button>
                      )}
                      {appointment.status !== 'in_progress' && (
                        <button
                          className={
                            appointment.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                          }
                          type="button"
                          onClick={() => handleDelete(appointment.appointment_id)}
                          data-tip={deleteBtn}
                        >
                          {icoTrash}
                        </button>
                      )}
                    </td>
                  ) : null}

                  {hasRole('Manager') ? (
                    <td className="actions">
                      {appointment.status !== 'in_progress' && (
                        <button
                          // className="icoBtn"
                          className={
                            appointment.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                          }
                          type="button"
                          onClick={() => handleUpdate(appointment.appointment_id)}
                          data-tip={updateBtn}
                        >
                          {icoUpdate}
                        </button>
                      )}
                      {appointment.status !== 'in_progress' && (
                        <button
                          // className="icoBtn"
                          className={
                            appointment.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                          }
                          type="button"
                          onClick={() => handleDelete(appointment.appointment_id)}
                          data-tip={deleteBtn}
                        >
                          {icoTrash}
                        </button>
                      )}
                    </td>
                  ) : null}

                  {hasRole('Admin') ? (
                    <td className="actions">
                      {appointment.status === 'in_progress' && (
                        <button
                          className="icoBtnBlue"
                          type="button"
                          onClick={() => handleFetchConsultation(appointment.appointment_id)}
                          data-tip={returnConsult}
                        >
                          {icoDoctor2}
                        </button>
                      )}
                      {appointment.status !== 'in_progress' && (
                        <>
                          {appointment.status !== 'completed' && appointment.status !== 'cancelled' && (
                            <button
                              className={
                                appointment.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                              }
                              type="button"
                              onClick={() => {
                                // console.log("ID do agendamento ao iniciar consulta:", appointment.appointment_id);
                                handleStartConsult(appointment.appointment_id, appointment.patient_id); // Passe também o patient_id
                              }}
                              data-tip={startConsult}
                            >
                              {icoDoctor2}
                            </button>
                          )}
                          <button
                            className={
                              appointment.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                            }
                            type="button"
                            onClick={() => handleUpdate(appointment.appointment_id)}
                            data-tip={updateBtn}
                          >
                            {icoUpdate}
                          </button>
                          <button
                            className={
                              appointment.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                            }
                            type="button"
                            onClick={() => handleDelete(appointment.appointment_id)}
                            data-tip={deleteBtn}
                          >
                            {icoTrash}
                          </button>
                        </>
                      )}
                    </td>
                  ) : null}



                </tr>
              ))
            )}
          </tbody>
        </table>

        <div className="pagination">
          {Array.from({ length: Math.ceil((searchText === '' ? appointments.length : filteredAppointment.length) / itemsPerPage) }, (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)} className={getPageButtonClass(index)} >
              {index + 1}
            </button>
          ))}
        </div>

      </div>
    </>
  );
}

export default GetAppointment;
