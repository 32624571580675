import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateDietById, fetchDietById } from '../../../redux/actions/dietActions';
import { fetchAllFoodsAndTacoFoods } from '../../../redux/actions/foodActions';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import Select from 'react-select';
import '../styles/UpdateDiet.css';
import '../../Appointment/styles/ReactSelect.css';

const UpdateDiet = () => {
  const location = useLocation();
  // const { diet_id: paramDietId } = useParams();
  // Recupera os valores enviados no estado da navegação
  const { consultation_id, patient_id, appointment_id, diet_id } = location.state || {};

  // const finalDietId = diet_id || paramDietId;

  // Valores padrão para fallback
  // const finalConsultationId = consultation_id || paramDietId; // Exemplo de fallback


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const { foodsAndTacoFoods = [], loading: foodsLoading, error: foodsError } = useSelector((state) => state.foodsAndTacoFoods);
  const { currentDiet = {}, loading: dietLoading, error: dietError } = useSelector((state) => state.diets);

  // console.log('Update dietas recebidas no estado:' + JSON.stringify(currentDiet));

  const [dietTotalCalories, setDietTotalCalories] = useState(0);

  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    if (!isFetched) {
      dispatch(fetchAllFoodsAndTacoFoods());
      // dispatch(fetchDietById(diet_id));
      setIsFetched(true);
    }
  }, [dispatch, isFetched]);

  // useEffect(() => {
  //   if (paramDietId) {
  //     console.log("ID da Dieta vindo de UpdateConsultation por parâmetro:", paramDietId);
  //     // dispatch(fetchDietById(diet_id));
  //   }
  // }, [dispatch, paramDietId]);

  // useEffect(() => {
  //   if (!finalDietId || !patient_id || !appointment_id) {
  //     // console.error("Dados insuficientes para carregar a Dieta.");
  //     navigate('/home/consultation/get', { replace: true });
  //     return;
  //   }

  //   dispatch(fetchDietById(finalDietId));
  // }, [dispatch, finalDietId, patient_id, appointment_id, consultation_id, navigate]);

  const formatDietName = (diet_name) => {
    switch (diet_name) {
      case 'low_carb':
        return 'Dieta Low Carb';
      case 'keto':
        return 'Dieta Cetogênica';
      case 'caloric_deficit':
        return 'Dieta de Déficit Calórico';
      case 'intermittent_fasting':
        return 'Dieta do Jejum Intermitente';
      case '21_day_diet':
        return 'Dieta de 21 Dias';
      case 'detox':
        return 'Dieta Detox';
      case '7_day_detox':
        return 'Dieta Detox de 7 Dias';
      case 'hypercaloric':
        return 'Dieta Hipercalórica';
      case 'hypertrophic':
        return 'Dieta Hipertófica';
      case 'bulking':
        return 'Dieta Bulking';
      case 'protein_diet':
        return 'Dieta da Proteína';
      case 'lean_gain':
        return 'Dieta para Ganho Lean (Massa Magra)';
      case 'mediterranean':
        return 'Dieta Mediterrânea';
      case 'mediterranean_adapted':
        return 'Dieta Mediterrânea Adaptada';
      case 'antiinflammatory':
        return 'Dieta Antiinflamatória';
      case 'antioxidant':
        return 'Dieta Antioxidante';
      case 'high_fiber':
        return 'Dieta Rica em Fibras';
      case 'cardiovascular':
        return 'Dieta de Saúde Cardiovascular';
      case 'immune_boost':
        return 'Dieta para Imunidade';
      case 'vegan':
        return 'Dieta Vegana';
      case 'vegetarian':
        return 'Dieta Vegetariana';
      case 'flexitarian':
        return 'Dieta Flexitariana';
      case 'pescatarian':
        return 'Dieta Pescetariana';
      case 'paleo':
        return 'Dieta Paleo';
      case 'dash':
        return 'Dieta DASH (Hipertensão)';
      case 'fodmap':
        return 'Dieta FODMAP (Distúrbios Intestinais)';
      case 'diabetes':
        return 'Dieta para Diabetes';
      case 'low_sodium':
        return 'Dieta Hipossódica';
      case 'renal':
        return 'Dieta Renal (Insuficiência Renal)';
      default:
        return '';
    }
  };

  const formatDietGroup = (group) => {
    switch (group) {
      case 'weightLoss':
        return 'Perda de Peso';
      case 'weightGain':
        return 'Ganho de Peso';
      case 'generalHealth':
        return 'Saúde Geral';
      case 'vegetarianLifestyles':
        return 'Estilos Vegetarianos';
      case 'healthSpecific':
        return 'Saúde Específica';
      default:
        return 'Outros';
    }
  };


  // const initialValues = useMemo(() => {
  //   if (!currentDiet || Array.isArray(currentDiet) && currentDiet.length === 0) {
  //     return {
  //       diet: '',
  //       objective: '',
  //       diet_total_calories: '',
  //       meals: [
  //         {
  //           name: '',
  //           time: '',
  //           total_calories: '',
  //           foods: [
  //             {
  //               food_id: null,
  //               taco_food_id: null,
  //               food_name: '',
  //               quantity: '',
  //               unit_measurement: '',
  //               calories: '',
  //             },
  //           ],
  //         },
  //       ],
  //     };
  //   }


  //   const dietData = Array.isArray(currentDiet) ? currentDiet[0] : currentDiet;


  //   const meals = dietData?.meals?.map((meal) => ({
  //     name: meal.name || '',
  //     time: meal.time || '',
  //     total_calories: meal.total_calories || '',
  //     appointment_id: meal.appointment_id || '',
  //     foods: meal.foods?.map((food) => ({
  //       food_id: food.food_id || null,
  //       taco_food_id: food.taco_food_id || null,
  //       food_name: food.food_name || '',
  //       quantity: food.quantity || '',
  //       unit_measurement: food.unit_measurement || '',
  //       kcal: food.calories || 0,
  //     })) || [],
  //   })) || [];

  //   return {
  //     diet: dietData.name ? dietData.name : '',
  //     objective: dietData.objective || '',
  //     diet_total_calories: dietData.diet_total_calories || '',
  //     meals,
  //   };
  // }, [currentDiet]);


  // useEffect(() => {
  //   console.log("currentDiet recebido do Redux:", currentDiet);
  //   if (currentDiet?.meals) {
  //     console.log("Meals carregadas:", currentDiet.meals);
  //   }
  //   console.log("Meals NÃO CARREGADAS:", currentDiet.meals);
  // }, [currentDiet]);

  // useEffect(() => {
  //   console.log('Dados da dieta carregados:', currentDiet);
  //   console.log('Valores iniciais:', initialValues);
  // }, [currentDiet, initialValues]);


  const initialValues = useMemo(() => {
    if (!currentDiet || Array.isArray(currentDiet) && currentDiet.length === 0) {
      return {
        diet: '',
        objective: '',
        diet_total_calories: '',
        meals: [{ name: '', time: '', total_calories: '', foods: [] }],
      };
    }

    const dietData = Array.isArray(currentDiet) ? currentDiet[0] : currentDiet;
    return {
      diet: dietData.name || '',
      objective: dietData.objective || '',
      diet_total_calories: dietData.diet_total_calories || '',
      meals: dietData?.meals?.map((meal) => ({
        name: meal.name || '',
        time: meal.time || '',
        total_calories: meal.total_calories || '',
        // foods: meal.foods || [],
        foods: meal.foods?.map((food) => ({
          food_id: food.food_id || null,
          taco_food_id: food.taco_food_id || null,
          food_name: food.food_name || '',
          quantity: food.quantity || '',
          unit_measurement: food.unit_measurement || '',
          kcal: food.calories || 0,
        })) || [],
      })) || [],
    };
  }, [currentDiet]);

  useEffect(() => {
    if (!dietLoading && currentDiet?.diet_total_calories) {
      // Atualiza dietTotalCalories apenas quando os dados forem carregados
      setDietTotalCalories(currentDiet.diet_total_calories);
    }
  }, [currentDiet, dietLoading]);

  // const calculateTotalCalories = (meals) => {
  //   return meals.reduce((total, meal) => total + (parseFloat(meal.total_calories) || 0), 0);
  // };
  const calculateTotalCalories = (meals) => {
    const totalFromMeals = meals.reduce((total, meal) => total + (parseFloat(meal.total_calories) || 0), 0);
    // Se não houver alteração, usa o valor carregado inicialmente
    return totalFromMeals > 0 ? totalFromMeals : currentDiet.diet_total_calories || 0;
  };

  const combinedFoods = useMemo(() => {
    return foodsAndTacoFoods.map((food) => ({
      value: food.food_id || food.taco_id,
      label: `${food.food_name} (${food.taco_id ? 'TACO' : 'Customizado'})`,
      kcal: food.kcal,
      source: food.taco_id ? 'taco_food' : 'foods',
    }));
  }, [foodsAndTacoFoods]);

  if (foodsLoading || dietLoading) {
    return <div>Carregando dados...</div>;
  }

  if (foodsError || dietError) {
    return <div>Erro ao carregar dados: {foodsError || dietError}</div>;
  }


  const availableMeals = [
    { value: 'breakfast', label: 'Café da Manhã' },
    { value: 'morning_snack', label: 'Lanche da Manhã' },
    { value: 'lunch', label: 'Almoço' },
    { value: 'afternoon_snack', label: 'Lanche da Tarde' },
    { value: 'dinner', label: 'Jantar' },
    { value: 'supper', label: 'Ceia' },
  ];

  const availableDiets = {
    weightLoss: [
      { value: 'low_carb', label: 'Dieta Low Carb', objective: 'Reduzir carboidratos para perda de peso.' },
      { value: 'keto', label: 'Dieta Cetogênica', objective: 'Induzir cetose para queima de gordura.' },
      { value: 'caloric_deficit', label: 'Dieta de Déficit Calórico', objective: 'Reduzir calorias para emagrecimento sustentável.' },
      { value: 'intermittent_fasting', label: 'Dieta do Jejum Intermitente', objective: 'Focar em períodos de jejum para regulação metabólica.' },
      { value: '21_day_diet', label: 'Dieta de 21 Dias', objective: 'Plano temporário para perda de peso rápida.' },
      { value: 'detox', label: 'Dieta Detox', objective: 'Eliminação de toxinas e renovação celular.' },
      { value: '7_day_detox', label: 'Dieta Detox de 7 Dias', objective: 'Renovação do corpo com alimentos leves e saudáveis.' },
    ],
    weightGain: [
      { value: 'hypercaloric', label: 'Dieta Hipercalórica', objective: 'Aumentar o consumo calórico para ganho de peso.' },
      { value: 'hypertrophic', label: 'Dieta Hipertófica', objective: 'Foco no ganho de massa muscular e hipertrofia.' },
      { value: 'bulking', label: 'Dieta Bulking', objective: 'Ganho de peso em preparação para hipertrofia.' },
      { value: 'protein_diet', label: 'Dieta da Proteína', objective: 'Aumentar o consumo de proteínas para ganho muscular.' },
      { value: 'lean_gain', label: 'Dieta para Ganho Lean (Massa Magra)', objective: 'Ganho de massa magra com pouca gordura.' },
    ],
    generalHealth: [
      { value: 'mediterranean', label: 'Dieta Mediterrânea', objective: 'Saúde geral baseada na culinária mediterrânea.' },
      { value: 'mediterranean_adapted', label: 'Dieta Mediterrânea Adaptada', objective: 'Promover saúde cardiovascular com alimentos integrais.' },
      { value: 'antiinflammatory', label: 'Dieta Antiinflamatória', objective: 'Reduzir inflamações com alimentos funcionais.' },
      { value: 'antioxidant', label: 'Dieta Antioxidante', objective: 'Melhorar a saúde celular com antioxidantes.' },
      { value: 'high_fiber', label: 'Dieta Rica em Fibras', objective: 'Aumentar a ingestão de fibras para saúde digestiva.' },
      { value: 'cardiovascular', label: 'Dieta de Saúde Cardiovascular', objective: 'Proteger o coração com alimentos saudáveis.' },
      { value: 'immune_boost', label: 'Dieta para Imunidade', objective: 'Fortalecer o sistema imunológico.' },
    ],
    vegetarianLifestyles: [
      { value: 'vegan', label: 'Dieta Vegana', objective: 'Estilo de vida sem consumo de alimentos de origem animal.' },
      { value: 'vegetarian', label: 'Dieta Vegetariana', objective: 'Dieta baseada em vegetais, sem carne.' },
      { value: 'flexitarian', label: 'Dieta Flexitariana', objective: 'Dieta vegetariana com consumo ocasional de carne.' },
      { value: 'pescatarian', label: 'Dieta Pescetariana', objective: 'Dieta vegetariana com inclusão de peixes.' },
      { value: 'paleo', label: 'Dieta Paleo', objective: 'Foco em alimentos naturais e integrais.' },
    ],
    healthSpecific: [
      { value: 'dash', label: 'Dieta DASH (Hipertensão)', objective: 'Reduzir a pressão arterial com alimentos ricos em nutrientes.' },
      { value: 'fodmap', label: 'Dieta FODMAP (Distúrbios Intestinais)', objective: 'Melhorar a digestão e reduzir desconfortos intestinais.' },
      { value: 'diabetes', label: 'Dieta para Diabetes', objective: 'Controlar os níveis de glicose no sangue.' },
      { value: 'low_sodium', label: 'Dieta Hipossódica', objective: 'Reduzir o consumo de sódio para saúde cardiovascular.' },
      { value: 'renal', label: 'Dieta Renal (Insuficiência Renal)', objective: 'Controlar nutrientes para a saúde dos rins.' },
    ],
  };

  const measureUnits = [
    { value: 'g', label: 'g - Gramas' },
    { value: 'kg', label: 'kg - Quilogramas' },
    { value: 'un', label: 'un - Unidades' },
    { value: 'ml', label: 'ml - Mililitros' },
    { value: 'l', label: 'l - Litros' },
    { value: 'soupe_spon', label: 'colher de sopa (15 ml)' },
    { value: 'tea_spon', label: 'colher de chá (5 ml)' },
    { value: 'dessert_spon', label: 'colher de sobremesa (10 ml)' },
    { value: 'cup', label: 'xícara (240 ml)' },
    { value: 'slice', label: 'fatia' },
    { value: 'piece', label: 'peça' },
    { value: 'bottle', label: 'garrafa' },
    { value: 'can', label: 'lata' },
    { value: 'pack', label: 'pacote' },
    { value: 'drop', label: 'gota' },
    { value: 'pinch', label: 'pitada' },
    { value: 'tray', label: 'bandeja' },
    { value: 'bar', label: 'barra' },
    { value: 'box', label: 'caixa' },
  ];


  const validationSchema = Yup.object({
    diet: Yup.string().required('O nome da dieta é obrigatório'),
    objective: Yup.string().required('O objetivo da dieta é obrigatório'),
    meals: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Selecione uma refeição'),
        time: Yup.string().required('O horário da refeição é obrigatório'),
        total_calories: Yup.number().min(1, 'Calorias inválidas').required('O total de calorias é obrigatório'),
        foods: Yup.array().of(
          Yup.object().shape({
            quantity: Yup.number().min(1, 'Quantidade inválida').required('A quantidade é obrigatória'),
            unit_measurement: Yup.string().required('A unidade de medida é obrigatória'),
          })
        ),
      })
    ),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // console.log('Submitted Values:', values);

      // Verifica se os valores essenciais estão presentes
      if (!consultation_id || !patient_id || !appointment_id || !diet_id) {
        throw new Error('Dados insuficientes: Certifique-se de que consultation_id, patient_id, appointment_id e diet_id estão disponíveis.');
      }

      const formData = {
        diet_id,
        consultation_id,
        name: values.diet,
        objective: values.objective,
        // diet_total_calories: dietTotalCalories,
        diet_total_calories: calculateTotalCalories(values.meals),
        meals: values.meals.map((meal) => ({
          name: meal.name,
          time: meal.time,
          total_calories: meal.total_calories,
          foods: meal.foods.map((food) => ({
            food_id: food.food_id || null,
            taco_food_id: food.taco_food_id || null,
            name: food.food_name,
            quantity: food.quantity,
            unit_measurement: food.unit_measurement,
            calories: food.kcal || 0,
          })),
        })),
      };

      console.log(`update diete formdata: ${JSON.stringify(formData)}`);

      await dispatch(updateDietById({ diet_id, formData })).unwrap();
      setMessage('Dieta atualizada com sucesso!');
      setShowModal(true);
      setTimeout(() => {
        navigate(`/home/consultation/update/${consultation_id}`, {
          state: {
            consultation_id,
            patient_id,
            appointment_id,
            diet_id,
          },
        });
      }, 2000);
    } catch (error) {
      console.error('Erro ao atualizar dieta:', error.response?.data || error.message);
      setMessage('Erro ao atualizar dieta. Tente novamente.');
      setShowModal(true);
    } finally {
      setSubmitting(false);
      setTimeout(() => {
        // navigate('/home/diets');
        setShowModal(false);
      }, 2000);
    }
  };

  return (
    <div className="dietMain">
      <h3>Editar Dieta</h3>
      {/* <p>Paciente ID: {patient_id}</p>
      <p>Agendamento ID: {appointment_id}</p>
      <p>Consulta ID: {consultation_id}</p>
      <p>Dieta ID: {diet_id}</p> */}
      <div className="dietForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, setFieldValue }) => {
            const handleMealCaloriesChange = (mealIndex, value) => {
              // Atualiza o valor da refeição em Formik
              setFieldValue(`meals[${mealIndex}].total_calories`, value);

              // Recalcula o total de calorias
              const updatedMeals = [...values.meals];
              updatedMeals[mealIndex].total_calories = value;
              const newTotalCalories = calculateTotalCalories(updatedMeals);

              // Atualiza o estado local e o Formik
              setDietTotalCalories(newTotalCalories);
              setFieldValue("diet_total_calories", newTotalCalories);
            };
            return (
              <Form>
                <div className="dietLine editDiet">
                  <div className="diet-form-group">
                    <label>Dieta:</label>
                    <Field
                      as="select"
                      name="diet"
                      value={values.diet}
                      className="SelectField"
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        // Encontra a dieta selecionada com base no valor escolhido
                        const selectedDiet = Object.values(availableDiets)
                          .flat()
                          .find((diet) => diet.value === selectedValue);
                        // Atualiza os campos no Formik
                        setFieldValue('diet', selectedValue); // Atualiza o campo diet
                        setFieldValue('objective', selectedDiet?.objective || '');
                      }}
                    >
                      <option value="">Selecione</option>
                      {Object.entries(availableDiets).map(([category, diets]) => (
                        <optgroup key={category} label={formatDietGroup(category)}>
                          {diets.map((dietOption) => (
                            <option key={dietOption.value} value={dietOption.value}>
                              {dietOption.label}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </Field>
                    <ErrorMessage name="diet" component="div" className="error-message" />
                  </div>

                  <div className="diet-form-group">
                    <label>Objetivo:</label>
                    <Field name="objective" className="objectiveField" type="text" />
                    <ErrorMessage name="objective" component="div" className="error-message" />
                  </div>

                  <div className="diet-form-group">
                    <label>Calorias Totais desta Dieta:</label>
                    <Field className="diet_total_calories" name="diet_total_calories" type="text" readOnly />
                    <ErrorMessage name="diet_total_calories" component="div" className="error-message" />
                  </div>

                  <div className="diet-form-group">
                    {/* <label>Calorias Totais desta Dieta:</label> */}
                    <Field
                      className="diet_total_calories"
                      name="diet_total_calories"
                      type="number"
                      value={dietTotalCalories}
                      readOnly
                      hidden={true}
                    />
                    <ErrorMessage name="diet_total_calories" component="div" className="error-message" />
                  </div>
                </div>

                <FieldArray name="meals">
                  {({ push, remove, replace }) => (
                    <>
                      {values.meals.map((meal, mealIndex) => (
                        <div key={mealIndex} className="meal-group">
                          <div className="dietLine">
                            <div className="diet-form-group">
                              <label>Refeição:</label>
                              <Field
                                name={`meals[${mealIndex}].name`}
                                as="select"
                                className="fieldSelect"
                                value={values.meals[mealIndex]?.name || ''} // Certifica-se de que o valor atual está sincronizado
                                onChange={(e) => { setFieldValue(`meals[${mealIndex}].name`, e.target.value); }}

                              >
                                <option value="">Selecione</option>
                                {availableMeals.map((mealOption) => (
                                  <option key={mealOption.value} value={mealOption.label}>
                                    {mealOption.label}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage name={`meals[${mealIndex}].name`} component="div" className="error-message" />
                            </div>

                            <div className="diet-form-group">
                              <label>Horário:</label>
                              <Field id="time-meal" name={`meals[${mealIndex}].time`} type="time" />
                              <ErrorMessage name={`meals[${mealIndex}].time`} component="div" className="error-message" />
                            </div>

                            <div className="diet-form-group">
                              <label>Calorias Totais desta Refeição:</label>
                              <Field
                                name={`meals[${mealIndex}].total_calories`}
                                type="number"
                                onChange={(e) => handleMealCaloriesChange(mealIndex, parseFloat(e.target.value) || 0 || '')}
                              />
                              <ErrorMessage name={`meals[${mealIndex}].total_calories`} component="div" className="error-message" />
                            </div>

                            <div className="dropdown-list">
                              <label>Adicionar Alimento:</label>
                              <Select
                                options={combinedFoods}
                                value={values.meals[mealIndex].selectFood || null}
                                onChange={(selectedOption) => {
                                  if (selectedOption) {
                                    const newFood = {
                                      food_id: selectedOption.source === 'foods' ? selectedOption.value : null,
                                      taco_food_id: selectedOption.source === 'taco_food' ? selectedOption.value : null,
                                      food_name: selectedOption.label,
                                      quantity: '',
                                      unit_measurement: '',
                                      kcal: selectedOption.kcal || 0,
                                    };

                                    setFieldValue(
                                      `meals[${mealIndex}].foods`,
                                      [...values.meals[mealIndex].foods, newFood]
                                    );

                                    setFieldValue(`meals[${mealIndex}].selectFood`, null);
                                  }
                                }}
                                isClearable
                                placeholder="Buscar alimento..."
                              />
                            </div>

                            <ul className="food-list">
                              {meal.foods.map((food, foodIndex) => (
                                <li key={foodIndex}>
                                  <div className="dietLine">
                                    <div className="diet-form-group">
                                      <label>Quantidade:</label>
                                      <Field
                                        className="foodListField"
                                        name={`meals[${mealIndex}].foods[${foodIndex}].quantity`}
                                        type="number"
                                      />
                                      <ErrorMessage
                                        name={`meals[${mealIndex}].foods[${foodIndex}].quantity`}
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>

                                    <div className="diet-form-group">
                                      <label>Unid. Medida:</label>
                                      <Field
                                        className="foodListField"
                                        as="select"
                                        name={`meals[${mealIndex}].foods[${foodIndex}].unit_measurement`}
                                      >
                                        <option value="">Selecione</option>
                                        {measureUnits.map((units) => (
                                          <option key={units.value} value={units.label}>
                                            {units.label}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name={`meals[${mealIndex}].foods[${foodIndex}].unit_measurement`}
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>

                                    <div className="diet-form-group foodName">
                                      <label>Alimento:</label>
                                      <strong>{`${food.food_name || "Novo Alimento"} - ${food.kcal || 0} kcal`}</strong>
                                    </div>

                                    <button
                                      className='btnIcoAddRm'
                                      type="button"
                                      onClick={() =>
                                        setFieldValue(
                                          `meals[${mealIndex}].foods`,
                                          meal.foods.filter((_, idx) => idx !== foodIndex)
                                        )
                                      }
                                    >
                                      <FaMinusCircle /> Remover
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="mealButton">
                            <button
                              className='removeMeal'
                              type="button"
                              onClick={() => remove(mealIndex)}
                            >
                              Remover Refeição
                            </button>
                          </div>

                        </div>
                      ))}

                      <button
                        className='addMeal'
                        type="button"
                        onClick={() => push({ name: '', time: '', total_calories: '', foods: [] })}
                      >
                        Adicionar Refeição
                      </button>

                    </>
                  )}
                </FieldArray>

                <div className="dietButtons">
                  <button className='sendBtn' type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Atualizando...' : 'Atualizar Dieta'}
                  </button>

                  <button className="backBtn" type="button" onClick={() => navigate(-1)}>
                    Cancelar
                  </button>
                </div>
              </Form>
            )
          }}

        </Formik>
        {showModal && <ModalForms message={message} onConfirm={() => setShowModal(false)} />}
      </div>
    </div>
  );
};

export default UpdateDiet;
