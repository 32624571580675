import React, { useState, useEffect } from 'react';
// import { AuthContext } from '../../context/auth';
import { forgot } from '../../service/api';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './styles/Forgot.css';

import bckgVideo from '../../assets/video/oranges.mp4';

const Forgot = () => {
  // const { forgot } = useContext(AuthContext);

  const [forgotUser, setForgotUser] = useState({
    email: '',
  });

  const [loading, setLoading] = useState(false); // Estado de carregamento

  const [message, setMessage] = useState('');

  const [ellipsis, setEllipsis] = useState('.....');

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  // Função para validar o email
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email) && email.indexOf('@') > 2;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifica se o campo email está preenchido corretamente
    if (!validateEmail(forgotUser.email)) {
      setMessage('O campo email deve ser preenchido corretamente');
      return;
    }

    setLoading(true); // Define estado de carregamento como verdadeiro

    try {
      const isAuthenticated = await forgot(forgotUser.email);
      if (isAuthenticated) {
        setMessage('Cheque sua caixa de mensagens para prosseguir'); // Define mensagem específica para sucesso
      } else {
        setMessage('Erro ao processar seu email'); // Define mensagem de erro
      }
    } catch (error) {
      setMessage('Erro ao processar seu email'); // Define mensagem de erro
      console.error('forgot error:', error);
    } finally {
      setLoading(false); // Define estado de carregamento como falso
      setForgotUser({ email: '' }); // Reseta o formulário de login após a tentativa
    }
  };

  useEffect(() => {
    if (message) {
      ressetMessage();
    }
  }, [message]);

  const ressetMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const handleChange = (e) => {
    setForgotUser({
      ...forgotUser,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500); // Atualiza a cada 500ms
    } else {
      setEllipsis('.....'); // Reseta as reticências quando não está carregando
    }

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar ou quando loading mudar
  }, [loading]);

  return (
    <>
      <div className="forgotVideoBox">
        <video src={bckgVideo} muted autoPlay loop />
      </div>

      <div className='forgot-container'>
        <div className="forgotBox" >
          <div className="forgotTitle">
            <span className=''>Digite seu email cadastrado</span>
          </div>
          <Form onSubmit={handleSubmit} className="" >
            {loading && <div className="message"> Aguarde{ellipsis}</div>}
            {message && <div className="message"> {message} </div>}
            <Form.Group>
              <Form.Label className="form-label" htmlFor="email"> Email </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                className="form-control"
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Nome"
                value={forgotUser.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="forgotBtn">
              <Button className="form-btn btn" variant="primary" type="submit"> Enviar </Button>
            </Form.Group>
            <div className="exitLink">
              <a href="/">sair</a>
            </div>
          </Form>
        </div>
      </div>

      <div className="footerLogin">
        <div className='year'>2007 - <span>{currentYear}</span></div> {/* Usando o ano atual */}
        <div>&copy; todos os direitos reservados</div>
        <div>powered by <span className='brand'>apit solutions</span></div>
      </div>
    </>
  );
}

export default Forgot;
