import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createNewAppointment } from '../../../redux/actions/appointmentsActions';
import { fetchCompanies, fetchPatients, fetchAllProfessionals } from '../../../redux/actions/relatedEntitiesActions';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import Select from 'react-select'; // Importando o componente React Select

import '../styles/CreateAppointment.css';
import '../styles/ReactSelect.css';

const CreateAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [timeRange, setTimeRange] = useState({ start: '08:00', end: '18:00' });

  const { companies, patients, professionals, loading, error: loadError } = useSelector((state) => state.relatedEntities);

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchPatients());
    dispatch(fetchAllProfessionals());
  }, [dispatch]);

  const getCurrentDate = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const initialValues = {
    company_id: '',
    person_id: '',
    patient_id: '',
    professional_id: '',
    doctor_id: '',
    nutritionist_id: '',
    appointment_date: '',
    appointment_time: '',
    scheduling_date: getCurrentDate(),
    status: 'scheduled',
    notes: '',
  };

  const formatSpeciality = (speciality) => {
    switch (speciality) {
      case 'general_clinic':
        return 'Clinica Geral';
      case 'physiotherapyst':
        return 'Fisioterapia';
      case 'nutritionist':
        return 'Nutrição';
      default:
        return '';
    }
  };

  const formatPersonType = (person_type) => {
    switch (person_type) {
      case 'nutritionist':
        return 'Nutricionista';
      case 'doctor':
        return 'Médico';
      case 'professional':
        return 'Profissional';
      default:
        return '';
    }
  };

  const validationSchema = Yup.object({
    company_id: Yup.number().required('Empresa é necessária'),
    patient_id: Yup.number().required('Paciente é necessário'),
    professional_id: Yup.number().required('Profissional é necessário'),
    appointment_date: Yup.date()
      .nullable()
      .required('Data do compromisso é necessária')
      .test('is-today-or-later', 'A data de agendamento não pode ser anterior a hoje.', (value) => {
        const today = moment().startOf('day');
        const appointmentDate = moment(value);
        return appointmentDate.isSameOrAfter(today);
      }),
    appointment_time: Yup.string()
      .required('Hora do compromisso é necessária')
      .test('is-within-range', `O horário deve estar entre ${timeRange.start} e ${timeRange.end}.`, (value) => {
        const [hour, minute] = value.split(':');
        const appointmentTime = moment().set({ hour, minute, second: 0, millisecond: 0 });
        const startTime = moment().set({ hour: timeRange.start.split(':')[0], minute: timeRange.start.split(':')[1], second: 0, millisecond: 0 });
        const endTime = moment().set({ hour: timeRange.end.split(':')[0], minute: timeRange.end.split(':')[1], second: 0, millisecond: 0 });
        return appointmentTime.isBetween(startTime, endTime, null, '[]');
      })
      .test('is-time-valid', 'O horário não pode ser anterior ao horário atual.', function (value) {
        const appointmentDate = moment(this.parent.appointment_date).format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');

        if (appointmentDate === today) {
          const [hour, minute] = value.split(':');
          const appointmentTime = moment().set({ hour, minute, second: 0, millisecond: 0 });
          const currentTime = moment();
          return appointmentTime.isSameOrAfter(currentTime);
        }
        return true;
      }),
    scheduling_date: Yup.date().required('Data de agendamento é necessária'),
    status: Yup.string().required('Status é necessário')
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/appointment/get');
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await dispatch(createNewAppointment(values)).unwrap();
      setMessage("Compromisso criado com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        resetForm();
        navigate('/home/appointment/get');
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      const errorMsg = (error.response && error.response.data && error.response.data.message)
        ? error.response.data.message
        : 'Erro ao enviar formulário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
    }
    setSubmitting(false);
  };

  const selectOptions = (items, labelKey = 'name') => items.map(item => ({
    value: item.person_id || item.company_id,
    label: item[labelKey],
  }));

  const statusOptions = [
    { value: '', label: 'Selecione' },
    { value: 'scheduled', label: 'Agendado' },
    { value: 'confirmed', label: 'Confirmado' },
    { value: 'changed', label: 'Alterado' },
    { value: 'cancelled', label: 'Cancelado' },
  ];

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar dados: {error}</div>;
  }

  return (
    <>
      <div>
        <h3>Novo Compromisso</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form className='appointmentForm' onKeyDown={handleKeyDown}>
              <div className="appointmentMain">
                <div className="appointment-form-group">
                  <label htmlFor="company_id">Empresa:</label>
                  <Select
                    name="company_id"
                    options={selectOptions(companies, 'company_name')}
                    onChange={option => setFieldValue('company_id', option ? option.value : '')}
                    classNamePrefix="custom-select"
                    placeholder="Selecione a empresa"
                  />
                  <ErrorMessage name="company_id" component="div" className="error-message" />
                </div>

                <div className="appointment-form-group">
                  <label htmlFor="professional_id">Profissional:</label>
                  <Select
                    name="professional_id"
                    options={selectOptions(professionals, 'name')}
                    onChange={option => setFieldValue('professional_id', option ? option.value : '')}
                    classNamePrefix="custom-select"
                    placeholder="Selecione o profissional"
                    formatOptionLabel={option => (
                      <div>
                        {option.label}
                        {option.person_type && ` - ${formatPersonType(option.person_type)}`}
                        {option.speciality && ` - ${formatSpeciality(option.speciality)}`}
                      </div>
                    )}
                  />
                  <ErrorMessage name="professional_id" component="div" className="error-message" />
                </div>

                <div className="appointment-form-group">
                  <label htmlFor="patient_id">Paciente:</label>
                  <Select
                    name="patient_id"
                    options={selectOptions(patients)}
                    onChange={option => setFieldValue('patient_id', option ? option.value : '')}
                    classNamePrefix="custom-select"
                    placeholder="Selecione o paciente"
                  />
                  <ErrorMessage name="patient_id" component="div" className="error-message" />
                </div>

                <div className="appointment-form-group">
                  <label htmlFor="appointment_date">Data do Compromisso:</label>
                  <Field type="date" name="appointment_date" id="appointment_date" />
                  <ErrorMessage name="appointment_date" component="div" className="error-message" />
                </div>

                <div className="appointment-form-group">
                  <label htmlFor="appointment_time">Hora do Compromisso:</label>
                  <Field type="time" name="appointment_time" id="appointment_time" />
                  <ErrorMessage name="appointment_time" component="div" className="error-message" />
                </div>
                <div className="appointment-form-group">
                  <label htmlFor="status">Status:</label>
                  <Select
                    name="status"
                    id="status"
                    options={statusOptions}
                    onChange={option => setFieldValue('status', option ? option.value : '')}
                    classNamePrefix="custom-select"
                    placeholder="Selecione"
                  />
                  <ErrorMessage name="status" component="div" className="error-message" />
                </div>

                {/* <div className="appointment-form-group">
                  <label htmlFor="status">Status:</label>
                  <Field as="select" className="sle" name="status" id="status">
                    <option value="">Selecione</option>
                    <option value="scheduled">Agendado</option>
                    <option value="confirmed">Confirmado</option>
                    <option value="changed">Alterado</option>
                    <option value="cancelled">Cancelado</option>
                  </Field>
                  <ErrorMessage name="status" component="div" className="error-message" />
                </div> */}

                <div className="appointment-form-group">
                  <label htmlFor="notes">Notas:</label>
                  <Field as="textarea" name="notes" id="notes" />
                  <ErrorMessage name="notes" component="div" className="error-message" />
                </div>

                <div className="formButtons">
                  <button className='sendBtn' type="submit" disabled={isSubmitting}>Enviar</button>
                  <button className='backBtn' onClick={handleBack}>Cancelar</button>
                </div>

              </div>
            </Form>
          )}
        </Formik>
        {showModal && (
          <ModalForms
            message={message}
            onConfirm={() => setShowModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default CreateAppointment;
