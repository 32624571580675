// Slice
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchPersons,
  fetchPersonById,
  deletePersonById,
  updatePersonById,
  createNewPerson,
  fetchPersonList,
} from '../actions/personActions';
const personSlice = createSlice({
  name: 'persons',
  initialState: {
    persons: [],
    currentPerson: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentPerson: (state, action) => {
      state.currentPerson = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPersons.fulfilled, (state, action) => {
        state.loading = false;
        state.persons = action.payload;
      })
      .addCase(fetchPersons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPersonById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPersonById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentPerson = action.payload;
      })
      .addCase(fetchPersonById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deletePersonById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePersonById.fulfilled, (state, action) => {
        state.loading = false;
        state.persons = state.persons.filter(person => person.id !== action.payload);
      })
      .addCase(deletePersonById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePersonById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePersonById.fulfilled, (state, action) => {
        state.loading = false;
        // Atualize a lógica aqui para evitar o erro de `undefined`
        if (action.payload) {
          state.persons = state.persons.map(person => person.id === action.payload.id ? action.payload : person);
        }
      })
      .addCase(updatePersonById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createNewPerson.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewPerson.fulfilled, (state, action) => {
        state.loading = false;
        state.persons.push(action.payload);
      })
      .addCase(createNewPerson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPersonList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPersonList.fulfilled, (state, action) => {
        state.loading = false;
        state.persons = action.payload;
      })
      .addCase(fetchPersonList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      ;
  },
});

export const { setCurrentPerson } = personSlice.actions;
export default personSlice.reducer;