import evolutionIcon from '../../../assets/img/shortcut_icon/evolution.png';
import draIcon from '../../../assets/img/draIcon.png';
import allUsers from '../../../assets/img/shortcut_icon/allUsers.png';
import allAppointment from '../../../assets/img/appointment.png';
import newAppointment from '../../../assets/img/consult.png';
import allPersons from '../../../assets/img/shortcut_icon/allPersons.png';
import newPerson from '../../../assets/img/shortcut_icon/newPerson.png';
import allCompanies from '../../../assets/img/shortcut_icon/allCompanies.png';
import newCompany from '../../../assets/img/shortcut_icon/newCompany.png';
import newUser from '../../../assets/img/shortcut_icon/newUser.png';
import allFoods from '../../../assets/img/shortcut_icon/allFoods.png';
import anthropometry from '../../../assets/img/shortcut_icon/anthropometry.png';
import consultation from '../../../assets/img/draIcon.png';

export const cardsConfig = [
  {
    imgSrc: allPersons,
    label: "Todas as Pessoas",
    navigateTo: "/home/person/get",
    requiredRole: ["Admin", "Manager", "Coordinator", "User"] // Acessível apenas para Admin
  },
  {
    imgSrc: newPerson,
    label: "Nova Pessoa",
    navigateTo: "/home/person/create",
    requiredRole: ["Admin", "Manager"] // Acessível apenas para Admin e Manager
  },
  {
    imgSrc: allCompanies,
    label: "Todos as Empresas",
    navigateTo: "/home/company/get",
    requiredRole: ['Admin', 'Manager', 'Coordinator', 'User'] // Acessível a todos
  },
  {
    imgSrc: newCompany,
    label: "Nova Empresa",
    navigateTo: "/home/company/create",
    requiredRole: ["Admin", "Manager", "Coordinator"] // Acessível apenas para Admin e Manager
  },
  {
    imgSrc: allAppointment,
    label: "Todos os Agendamentos",
    navigateTo: "/home/appointment/get",
    requiredRole: ["Admin", "Manager", "User"] // Acessível a todos
  },
  {
    imgSrc: newAppointment,
    label: "Novo Agendamento",
    navigateTo: "/home/appointment/create",
    requiredRole: ["Admin", "Manager", "Coordinator"]
  },
  {
    imgSrc: allUsers,
    label: "Todos os usuários",
    navigateTo: "/home/user/get",
    requiredRole: ['Admin'] // Acessível ao nível intermediário
  },
  {
    imgSrc: newUser,
    label: "Novo usuário",
    navigateTo: "/home/user/create",
    requiredRole: ["Admin"] // Acessível apenas para Admin e Manager
  },
  {
    imgSrc: allFoods,
    label: "Todos os Alimentos",
    navigateTo: "/home/nutridata/get",
    requiredRole: ["Admin", "Manager", "User"] // Acessível a todos
  },
  {
    imgSrc: anthropometry,
    label: "Antropometria",
    navigateTo: "/home/anthropometry/create",
    requiredRole: ['Admin', 'Manager', 'Coordinator'] // Acessível ao nível intermediário
  },
  {
    imgSrc: anthropometry,
    label: "Antropometria Por Paciente",
    navigateTo: "/home/anthropometry/get",
    requiredRole: ['Admin', 'Manager', 'Coordinator'] // Acessível ao nível intermediário
  },
  {
    imgSrc: consultation,
    label: "Todas as Consultas",
    navigateTo: "/home/consultation/get",
    requiredRole: ['Admin'] // Acessível ao nível intermediário
  },
  // {
  //   imgSrc: draIcon,
  //   label: "Todos os profissionais",
  //   navigateTo: "/home/user/get",
  //   requiredRole: ["Admin", "Manager"] // Acessível apenas para Admin e Manager
  // },
  // {
  //   imgSrc: draIcon,
  //   label: "Novo Profissional",
  //   navigateTo: "/home/user/get",
  //   requiredRole: ["Admin", "Manager"] // Acessível apenas para Admin e Manager
  // },
];
