// Slice
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchPatientDetails,
  // fetchPatients,
  // fetchPatientById,
  // deletePatientById,
  // updatePatientById,
  // createNewPatient,
} from '../actions/patientActions';

const patientSlice = createSlice({
  name: 'patients',
  initialState: {
    currentPatient: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentPatient: (state, action) => {
      state.currentPatient = action.payload;
    },
    clearCurrentPatient: (state) => {
      state.currentPatient = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatientDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentPatient = action.payload; // Armazena os detalhes do paciente
      })
      .addCase(fetchPatientDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // .addCase(fetchPatients.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchPatients.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.patients = action.payload;
      // })
      // .addCase(fetchPatients.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(fetchPatientById.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchPatientById.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.currentPatient = action.payload;
      // })
      // .addCase(fetchPatientById.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(deletePatientById.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(deletePatientById.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.patients = state.patients.filter(patient => patient.id !== action.payload);
      // })
      // .addCase(deletePatientById.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(updatePatientById.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(updatePatientById.fulfilled, (state, action) => {
      //   state.loading = false;
      //   if (action.payload) {
      //     state.patients = state.patients.map(patient => patient.id === action.payload.id ? action.payload : patient);
      //   }
      // })
      // .addCase(updatePatientById.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      // .addCase(createNewPatient.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(createNewPatient.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.patients.push(action.payload);
      // })
      // .addCase(createNewPatient.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      ;
  },
});

export const { setCurrentPatient, clearCurrentPatient } = patientSlice.actions;
export default patientSlice.reducer;
