import { createSlice } from '@reduxjs/toolkit';
import { fetchCompanies, fetchPatients, fetchAllProfessionals, fetchAllPersons, fetchPersonById } from '../actions/relatedEntitiesActions';

const initialState = {
  companies: [],
  patients: [],
  professionals: [],
  person: null,
  currentPerson: null, // Estado para uma única pessoa
  loading: false,
  error: null,
};

const relatedEntitiesSlice = createSlice({
  name: 'relatedEntities',
  initialState,
  reducers: {
    setCurrentPerson: (state, action) => {
      state.currentPatient = action.payload;
    },
    clearCurrentPerson: (state) => {
      state.currentPatient = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.companies = action.payload || [];
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPatients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatients.fulfilled, (state, action) => {
        state.loading = false;
        state.patients = action.payload || [];
      })
      .addCase(fetchPatients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllProfessionals.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllProfessionals.fulfilled, (state, action) => {
        state.loading = false;
        state.professionals = action.payload || [];
      })
      .addCase(fetchAllProfessionals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllPersons.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPersons.fulfilled, (state, action) => {
        state.loading = false;
        state.professionals = action.payload;
      })
      .addCase(fetchAllPersons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPersonById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPersonById.fulfilled, (state, action) => {
        state.loading = false;
        state.person = action.payload; // Armazena os dados da pessoa
      })
      .addCase(fetchPersonById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Erro ao carregar dados da pessoa.';
      })
      ;
  },
});

export const { setCurrentPerson, clearCurrentPerson } = relatedEntitiesSlice.actions;

export default relatedEntitiesSlice.reducer;

