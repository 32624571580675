// src/redux/actions/personActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createDiet, getDietById, getDietByPatientId, updateDiet, deleteDiet } from '../../service/api';



export const createNewDiet = createAsyncThunk(
  'diets/createNewDiet',

  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await createDiet(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Erro ao criar dieta');
    }
  });

export const fetchDietById = createAsyncThunk(
  'diets/fetchDietById',
  async ({ diet_id }) => {
    const response = await getDietById(diet_id);
    return response;
  }
);

export const fetchDietByPatientId = createAsyncThunk(
  'diets/fetchByPatientId',
  async (patient_id, { rejectWithValue }) => {
    try {
      const response = await getDietByPatientId(`/diet/patient/${patient_id}`);
      return response.data; // Assumindo que as dietas estão no campo "data"
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Erro ao buscar dietas');
    }
  }
);

export const updateDietById = createAsyncThunk(
  'diets/updateDietById',
  async ({ diet_id, formData }) => {
    // console.log("dados da dieta enviados para o backend" + diet_id, formData)
    const response = await updateDiet(diet_id, formData);
    return response.data;
  });

// Delete diet
export const deleteDietById = createAsyncThunk(
  'diets/deleteDietById',
  async (diet_id) => {
    const response = await deleteDiet(diet_id);
    return response.data;
  }
);
