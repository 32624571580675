import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { fetchConsultationByPatientId } from '../../../redux/actions/consultationActions';
import { fetchPatients, fetchAllProfessionals } from '../../../redux/actions/relatedEntitiesActions';
import Select from 'react-select';

import { FaUserDoctor } from "react-icons/fa6";
import { MdOutlineSick } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";
import { MdHome } from 'react-icons/md';

import Accordion from '../../../components/Accordion/Accordion';
import '../styles/GetConsultationByPatientId.css';
import '../../../Mobile.css';

import { clearConsultation } from '../../../redux/slices/consultationSlice';
import { clearCurrentPatient } from '../../../redux/slices/patientSlice';

function GetConsultationPatientById() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const returnBtn = "Retornar";

  const { patients = [], loadingPatients, errorPatients } = useSelector((state) => state.relatedEntities);
  const { professionals = [], loadingProfessionals } = useSelector((state) => state.relatedEntities);
  const { currentConsultation = [], loadingConsultations, errorConsultations } = useSelector((state) => state.consultations || { consultations: [] });


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(currentConsultation)
    ? currentConsultation.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const [loadedProfessionals, setLoadedProfessionals] = useState({});
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchAllProfessionals());
    dispatch(clearConsultation());
    dispatch(clearCurrentPatient());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPatient) {
      dispatch(clearConsultation());
      dispatch(fetchConsultationByPatientId(selectedPatient));
    }
  }, [selectedPatient, dispatch]);

  const handlePatientChange = (selectedOption) => {
    setSelectedPatient(selectedOption);
    dispatch(clearConsultation());
    if (selectedOption) {
      dispatch(fetchConsultationByPatientId(selectedOption.value));
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/dashboard');
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Data inválida';
    const date = new Date(dateString);
    if (Number.isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  // Função para calcular a Idade Ano Meses e Dias
  const calculateAge = (patientBirthDate) => {
    const today = new Date();
    const birthDate = new Date(patientBirthDate);
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (days < 0) {
      months--;
      const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      days += lastMonth.getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return `${pluralize(years, 'Ano', 'Anos')} ${pluralize(months, 'mês', 'meses')} e ${pluralize(days, 'dia', 'dias')}`;
  };
  const pluralize = (count, singular, plural) => {
    return `${count} ${count === 1 ? singular : plural}`;
  };
  //
  const formatStatus = (status) => {
    switch (status) {
      case 'agended':
        return 'Agendado';
      case 'in_progress':
        return 'Em Andamento';
      case 'altered':
        return 'Alterada';
      case 'cancelled':
        return 'Cancelada';
      default:
        return '';
    }
  }


  return (
    <>
      <h3>Buscar Consultas por Paciente</h3>
      <div className="getConsultationsForm">
        <div className="getConsultations-form-group">
          <h5 className="consultationsTitle">Paciente:</h5>
          <Select
            name="patient_id"
            options={patients.map(patient => ({
              value: patient.person_id,
              label: patient.name,
            }))}
            onChange={handlePatientChange}
            classNamePrefix="custom-select"
            value={selectedPatient}
            placeholder={!selectedPatient ? "Selecione o paciente" : ""}
            isLoading={loadingPatients}
            isClearable
          />
          {errorPatients && <div className="error-message">Erro ao carregar pacientes: {errorPatients}</div>}
          <button type="button" className="returnDashboard" onClick={handleBack} data-tip={returnBtn}><MdHome /></button>
        </div>
      </div>

      <div className="getConsultations">
        {loadingConsultations ? (
          <div>Carregando consultas...</div>
        ) : errorConsultations ? (
          <div className="error-message">Erro ao carregar consultas: {errorConsultations}</div>
        ) : currentConsultation.length === 0 && selectedPatient ? (
          <div>Nenhuma consulta encontrada para o paciente selecionado.</div>
        ) : selectedPatient && (
          <div>
            <div className="getAccordion">
              <div>
                <h5 className='consultationTitle'>Consultas Anteriores</h5>
                <div className="row-getMeasures">
                  {currentItems.map((consults) => {
                    return (
                      <Accordion
                        key={consults.consult_date}
                        title={
                          <div className="row-getConsultationsBox">
                            <div className="colAntBox">
                              <label htmlFor="date" className="labelAnt">Data da Consulta</label>
                              <div className="rowAntBox">
                                <LuCalendarClock /> {formatDate(consults.consult_date)}
                              </div>
                            </div>
                            <div className="colAntBox">
                              <label htmlFor="date" className="labelAnt">Paciente</label>
                              <div className="rowAntBox">
                                <MdOutlineSick /> {consults.patient_first_name || 'N/A'} {consults.patient_last_name || 'N/A'}
                              </div>
                            </div>
                            <div className="colAntBox">
                              <label htmlFor="date" className="labelAnt">Profissional</label>
                              <div className="rowAntBox">
                                <FaUserDoctor /> {consults.professional_first_name || 'N/A'} {consults.professional_last_name || 'N/A'}
                              </div>
                            </div>
                            <div className="colAntBox">
                              <label htmlFor="date" className="labelAnt">Status</label>
                              <div className="rowAntBox">
                                {formatStatus(consults.status) || 'N/A'}
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div className="row-Consultations">
                          <h5 className='consultationTitle'>Detalhes da Consulta</h5>
                          <div className="row-box">
                            <div className="row-line">
                              <label>Paciente</label>
                              <div className="return-data">{consults.patient_first_name} {consults.patient_last_name}</div>
                            </div>

                            <div className="row-line">
                              <label>Data de Nascimento</label>
                              <div className="return-data">{formatDate(consults.consult_date)}</div>
                            </div>

                            <div className="row-line">
                              <label>Idade</label>
                              <div className="return-data">{calculateAge(consults.consult_date)}</div>
                            </div>

                            <div className="row-line">
                              <label>Altura</label>
                              <div className="return-data">{consults.height ? `${consults.height} cm` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>Peso Atual</label>
                              <div className="return-data">{consults.current_weight ? `${consults.current_weight} kg` : 'N/A'}</div>
                            </div>
                            <div className="row-line">
                              <label>Dieta Atribuída</label>
                              <div className="return-data">{consults.diets.diet_name || 'N/A'}</div>
                            </div>

                            <div className="row-line"></div>
                            <div className="row-line"></div>


                            <div className="row-line">
                              <label>Diagnóstico</label>
                              <div className="return-data">{consults.diagnosis || 'N/A'}</div>
                            </div>
                            <div className="row-line">
                              <label>Procedimentos</label>
                              <div className="return-data">{consults.procedures || 'N/A'}</div>
                            </div>
                            <div className="row-line">
                              <label>Observações</label>
                              <div className="return-data">{consults.observations || 'N/A'}</div>
                            </div>
                          </div>

                        </div>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GetConsultationPatientById;
