import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createNewDiet } from '../../../redux/actions/dietActions';
import { fetchAllFoodsAndTacoFoods } from '../../../redux/actions/foodActions';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { MdHome } from 'react-icons/md';
import Select from 'react-select';

import '../styles/CreateDiet.css';
import '../../Appointment/styles/ReactSelect.css';

const CreateDiet = () => {
  // const { patient_id } = useParams();
  const location = useLocation();

  // Recuperar o state do agendamento e do paciente
  const appointment_id = location.state?.appointment_id;
  const patient_id = location.state?.patient_id;
  const consultation_id = location.state?.consultation_id;
  // const { appointment_id, patient_id, consultation_id } = location.state || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const { foodsAndTacoFoods = [], loading, error } = useSelector((state) => state.foodsAndTacoFoods);
  const [isFetched, setIsFetched] = useState(false);

  const returnBtn = "Retornar";
  const [selectedFood, setSelectedFood] = useState('');
  const [dietTotalCalories, setDietTotalCalories] = useState(0);
  const [mealTotalCalories, setMealTotalCalories] = useState(0);


  // console.log('appointment_id recebido no estado: ' + appointment_id);

  useEffect(() => {
    if (!appointment_id) {
      // console.error('Erro: appointment_id não foi fornecido!');
    }
    if (!patient_id) {
      // console.error('Erro: patient_id não foi fornecido!');
    }
    if (!consultation_id) {
      // console.error('Erro: consultation_id não foi fornecido!');
    }
  }, [appointment_id, patient_id, consultation_id]);

  // Efeito para carregar os dados caso não estejam disponíveis
  useEffect(() => {
    if (!isFetched && !loading) {
      // console.log('Foods ainda não carregados, carregando...');
      dispatch(fetchAllFoodsAndTacoFoods())
        .unwrap()
        .then((data) => {
          // console.log('Dados recebidos via dispatch:', data);
          setIsFetched(true); // Atualiza a variável de controle
        })
        .catch((error) => {
          console.error('Erro ao buscar alimentos:', error);
          setIsFetched(true); // Evita que o loop continue mesmo em caso de erro
        });
    }
  }, [dispatch, loading, isFetched]);


  const initialValues = {
    patient_id: patient_id || '',
    appointment_id: Number.parseInt(appointment_id) || '',
    diet: '',
    objective: '',
    meals: [
      {
        name: '',
        time: '',
        total_calories: '',
        foods: [], // Inicializado como array vazio
        selectFood: null, // Adicione este campo para cada refeição     
      },
    ],
  };

  // console.log("Valores iniciais:", initialValues);


  // Combinar alimentos de `foods` e `taco_food` em uma única lista para o Select
  const combinedFoods = useMemo(() => {
    return foodsAndTacoFoods.map((food) => ({
      value: food.food_id || food.taco_id, // Único identificador
      label: `${food.food_name} (${food.taco_id ? 'TACO' : 'Customizado'})`,
      kcal: food.kcal,
      source: food.taco_id ? 'taco_food' : 'foods',
    }));
  }, [foodsAndTacoFoods]);

  // useEffect(() => {
  //   if (loading) {
  //     console.log('Carregando alimentos...');
  //   } else if (foodsAndTacoFoods.length > 0) {
  //     console.log('Alimentos carregados:', foodsAndTacoFoods);
  //     console.log('Alimentos combinados para Select:', combinedFoods);
  //     console.log('ID do agendamento recebido:', appointment_id);
  //   } else {
  //     console.log('Foods ainda não carregados');
  //   }
  // }, [foodsAndTacoFoods, combinedFoods, loading, appointment_id]);


  if (loading) {
    return <div>Carregando alimentos...</div>;
  }

  if (error) {
    return <div>Erro ao carregar alimentos: {error}</div>;
  }



  const availableMeals = [
    { value: 'breakfast', label: 'Café da Manhã' },
    { value: 'morning_snack', label: 'Lanche da Manhã' },
    { value: 'lunch', label: 'Almoço' },
    { value: 'afternoon_snack', label: 'Lanche da Tarde' },
    { value: 'dinner', label: 'Jantar' },
    { value: 'supper', label: 'Ceia' },
  ];

  const availableDiets = {
    weightLoss: [
      { value: 'low_carb', label: 'Dieta Low Carb', objective: 'Reduzir carboidratos para perda de peso.' },
      { value: 'keto', label: 'Dieta Cetogênica', objective: 'Induzir cetose para queima de gordura.' },
      { value: 'caloric_deficit', label: 'Dieta de Déficit Calórico', objective: 'Reduzir calorias para emagrecimento sustentável.' },
      { value: 'intermittent_fasting', label: 'Dieta do Jejum Intermitente', objective: 'Focar em períodos de jejum para regulação metabólica.' },
      { value: '21_day_diet', label: 'Dieta de 21 Dias', objective: 'Plano temporário para perda de peso rápida.' },
      { value: 'detox', label: 'Dieta Detox', objective: 'Eliminação de toxinas e renovação celular.' },
      { value: '7_day_detox', label: 'Dieta Detox de 7 Dias', objective: 'Renovação do corpo com alimentos leves e saudáveis.' },
    ],
    weightGain: [
      { value: 'hypercaloric', label: 'Dieta Hipercalórica', objective: 'Aumentar o consumo calórico para ganho de peso.' },
      { value: 'hypertrophic', label: 'Dieta Hipertófica', objective: 'Foco no ganho de massa muscular e hipertrofia.' },
      { value: 'bulking', label: 'Dieta Bulking', objective: 'Ganho de peso em preparação para hipertrofia.' },
      { value: 'protein_diet', label: 'Dieta da Proteína', objective: 'Aumentar o consumo de proteínas para ganho muscular.' },
      { value: 'lean_gain', label: 'Dieta para Ganho Lean (Massa Magra)', objective: 'Ganho de massa magra com pouca gordura.' },
    ],
    generalHealth: [
      { value: 'mediterranean', label: 'Dieta Mediterrânea', objective: 'Saúde geral baseada na culinária mediterrânea.' },
      { value: 'mediterranean_adapted', label: 'Dieta Mediterrânea Adaptada', objective: 'Promover saúde cardiovascular com alimentos integrais.' },
      { value: 'antiinflammatory', label: 'Dieta Antiinflamatória', objective: 'Reduzir inflamações com alimentos funcionais.' },
      { value: 'antioxidant', label: 'Dieta Antioxidante', objective: 'Melhorar a saúde celular com antioxidantes.' },
      { value: 'high_fiber', label: 'Dieta Rica em Fibras', objective: 'Aumentar a ingestão de fibras para saúde digestiva.' },
      { value: 'cardiovascular', label: 'Dieta de Saúde Cardiovascular', objective: 'Proteger o coração com alimentos saudáveis.' },
      { value: 'immune_boost', label: 'Dieta para Imunidade', objective: 'Fortalecer o sistema imunológico.' },
    ],
    vegetarianLifestyles: [
      { value: 'vegan', label: 'Dieta Vegana', objective: 'Estilo de vida sem consumo de alimentos de origem animal.' },
      { value: 'vegetarian', label: 'Dieta Vegetariana', objective: 'Dieta baseada em vegetais, sem carne.' },
      { value: 'flexitarian', label: 'Dieta Flexitariana', objective: 'Dieta vegetariana com consumo ocasional de carne.' },
      { value: 'pescatarian', label: 'Dieta Pescetariana', objective: 'Dieta vegetariana com inclusão de peixes.' },
      { value: 'paleo', label: 'Dieta Paleo', objective: 'Foco em alimentos naturais e integrais.' },
    ],
    healthSpecific: [
      { value: 'dash', label: 'Dieta DASH (Hipertensão)', objective: 'Reduzir a pressão arterial com alimentos ricos em nutrientes.' },
      { value: 'fodmap', label: 'Dieta FODMAP (Distúrbios Intestinais)', objective: 'Melhorar a digestão e reduzir desconfortos intestinais.' },
      { value: 'diabetes', label: 'Dieta para Diabetes', objective: 'Controlar os níveis de glicose no sangue.' },
      { value: 'low_sodium', label: 'Dieta Hipossódica', objective: 'Reduzir o consumo de sódio para saúde cardiovascular.' },
      { value: 'renal', label: 'Dieta Renal (Insuficiência Renal)', objective: 'Controlar nutrientes para a saúde dos rins.' },
    ],
  };

  const measureUnits = [
    { value: 'g', label: 'g - Gramas' },
    { value: 'kg', label: 'kg - Quilogramas' },
    { value: 'un', label: 'un - Unidades' },
    { value: 'ml', label: 'ml - Mililitros' },
    { value: 'l', label: 'l - Litros' },
    { value: 'soupe_spon', label: 'colher de sopa (15 ml)' },
    { value: 'tea_spon', label: 'colher de chá (5 ml)' },
    { value: 'dessert_spon', label: 'colher de sobremesa (10 ml)' },
    { value: 'cup', label: 'xícara (240 ml)' },
    { value: 'slice', label: 'fatia' },
    { value: 'piece', label: 'peça' },
    { value: 'bottle', label: 'garrafa' },
    { value: 'can', label: 'lata' },
    { value: 'pack', label: 'pacote' },
    { value: 'drop', label: 'gota' },
    { value: 'pinch', label: 'pitada' },
    { value: 'tray', label: 'bandeja' },
    { value: 'bar', label: 'barra' },
    { value: 'box', label: 'caixa' },
  ];

  const formatDietName = (diet_name) => {
    switch (diet_name) {
      case 'low_carb':
        return 'Dieta Low Carb';
      case 'keto':
        return 'Dieta Cetogênica';
      case 'caloric_deficit':
        return 'Dieta de Déficit Calórico';
      case 'intermittent_fasting':
        return 'Dieta do Jejum Intermitente';
      case '21_day_diet':
        return 'Dieta de 21 Dias';
      case 'detox':
        return 'Dieta Detox';
      case '7_day_detox':
        return 'Dieta Detox de 7 Dias';
      case 'hypercaloric':
        return 'Dieta Hipercalórica';
      case 'hypertrophic':
        return 'Dieta Hipertófica';
      case 'bulking':
        return 'Dieta Bulking';
      case 'protein_diet':
        return 'Dieta da Proteína';
      case 'lean_gain':
        return 'Dieta para Ganho Lean (Massa Magra)';
      case 'mediterranean':
        return 'Dieta Mediterrânea';
      case 'mediterranean_adapted':
        return 'Dieta Mediterrânea Adaptada';
      case 'antiinflammatory':
        return 'Dieta Antiinflamatória';
      case 'antioxidant':
        return 'Dieta Antioxidante';
      case 'high_fiber':
        return 'Dieta Rica em Fibras';
      case 'cardiovascular':
        return 'Dieta de Saúde Cardiovascular';
      case 'immune_boost':
        return 'Dieta para Imunidade';
      case 'vegan':
        return 'Dieta Vegana';
      case 'vegetarian':
        return 'Dieta Vegetariana';
      case 'flexitarian':
        return 'Dieta Flexitariana';
      case 'pescatarian':
        return 'Dieta Pescetariana';
      case 'paleo':
        return 'Dieta Paleo';
      case 'dash':
        return 'Dieta DASH (Hipertensão)';
      case 'fodmap':
        return 'Dieta FODMAP (Distúrbios Intestinais)';
      case 'diabetes':
        return 'Dieta para Diabetes';
      case 'low_sodium':
        return 'Dieta Hipossódica';
      case 'renal':
        return 'Dieta Renal (Insuficiência Renal)';
      default:
        return '';
    }
  };

  const formatDietGroup = (group) => {
    switch (group) {
      case 'weightLoss':
        return 'Perda de Peso';
      case 'weightGain':
        return 'Ganho de Peso';
      case 'generalHealth':
        return 'Saúde Geral';
      case 'vegetarianLifestyles':
        return 'Estilos Vegetarianos';
      case 'healthSpecific':
        return 'Saúde Específica';
      default:
        return 'Outros';
    }
  };
  // apenas números nos campos
  function onlyNumbers(e, setFieldValue, fieldName) {
    const { value } = e.target;

    // Substituir vírgula por ponto
    if (e.key === ',') {
      e.preventDefault();
      setFieldValue(fieldName, `${value}.`);
      return;
    }

    // Permitir apenas números, ponto, backspace, delete, setas e tab
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', '.', 'Tab'];
    if (!((e.key >= '0' && e.key <= '9') || allowedKeys.includes(e.key))) {
      e.preventDefault();
      return;
    }

    // Evitar múltiplos pontos
    if (e.key === '.' && value.includes('.')) {
      e.preventDefault();
      return;
    }

    // Limitar a duas casas decimais usando regex
    const regex = /^\d+(\.\d{0,2})?$/; // Permite números com até duas casas decimais
    const simulatedValue = value + e.key; // Simula o próximo valor com a tecla pressionada

    if (e.key !== 'Backspace' && e.key !== 'Delete' && !regex.test(simulatedValue)) {
      e.preventDefault();
      return;
    }
  }

  const collectFormikErrors = (errors, prefix = '') => {
    let messages = [];
    for (const key in errors) {
      if (typeof errors[key] === 'string') {
        messages.push(`${prefix}${errors[key]}`);
      } else if (typeof errors[key] === 'object') {
        messages = messages.concat(
          collectFormikErrors(errors[key], `${prefix}${key}.`)
        );
      }
    }
    return messages;
  };


  const validationSchema = Yup.object({
    appointment_id: Yup.string()
      .required('O ID do agendamento é obrigatório.')
      .test('log', 'Logging appointment_id', (value) => {
        // console.log('Validando appointment_id:', value);
        return true;
      }),
    patient_id: Yup.string()
      .required('O ID do paciente é obrigatório.')
      .test('log', 'Logging patient_id', (value) => {
        // console.log('Validando patient_id:', value);
        return true;
      }),
    diet: Yup.string().required('O nome da dieta é obrigatório'),
    objective: Yup.string().required('O objetivo da dieta é obrigatório'),
    meals: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Selecione uma refeição'),
        time: Yup.string().required('O horário da refeição é obrigatório'),
        total_calories: Yup.number().min(1, 'Calorias inválidas').required('O total de calorias é obrigatório'),
        foods: Yup.array().of(
          Yup.object().shape({
            // food_id: Yup.number().required('O ID do alimento é obrigatório'),
            quantity: Yup.number().min(1, 'Quantidade inválida').required('A quantidade é obrigatória'),
            unit_measurement: Yup.string().required('A unidade de medida é obrigatória'),
            // calories: Yup.number().min(1, 'Calorias inválidas').required('As calorias são obrigatórias'),

          })
        ),
      })
    ),
  });

  const handleClickBack = () => {
    navigate(`/home/consultation/update/${appointment_id}`);
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(`/home/consultation/create/${appointment_id}`);
  }
  const handleCancelDiet = () => {
    navigate(location.state?.returnPath || '/home/appointment/get');
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {

    if (!values || !values.patient_id || !values.appointment_id) {
      console.error('Erro: Dados ausentes em values.', values);
      setMessage('Erro: Campos obrigatórios ausentes.');
      setShowModal(true);
      setSubmitting(false);
      return;
    }
    // console.log('Meals:', values.meals);
    // console.log('Calculated Calories:', values.meals.reduce((acc, meal) => acc + (parseFloat(meal.total_calories) || 0), 0));


    try {
      const formData = {
        patient_id: Number(values.patient_id) || null,
        appointment_id: Number(values.appointment_id),
        consultation_id: consultation_id ? Number(consultation_id) : null, // Adiciona se estiver disponível
        name: values.diet,
        objective: values.objective,
        diet_total_calories: values.meals.reduce((acc, meal) => acc + (parseFloat(meal.total_calories) || 0), 0),
        meals: values.meals.map((meal) => ({
          name: meal.name,
          time: meal.time,
          total_calories: meal.total_calories,
          foods: meal.foods.map((food) => ({
            food_id: food.food_id || null,
            taco_food_id: food.taco_food_id || null,
            name: food.food_name,
            quantity: food.quantity,
            unit_measurement: food.unit_measurement,
            calories: food.kcal || 0,
          })),
        })),
      };

      // console.log('Dieta enviada para API:', JSON.stringify(formData, null, 2));

      await dispatch(createNewDiet({ formData })).unwrap();
      setMessage('Dieta criada com sucesso!');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        // resetForm();
        // navigate(location.state?.returnPath || `/home/consultation/create/${appointment_id}`);
        navigate(`/home/consultation/update/${appointment_id}`, {
          state: {
            consultation_id,
            patient_id,
            appointment_id,
          },
        });

      }, 2000);
    } catch (error) {
      console.error('Erro ao criar dieta:', error);
      setMessage('Erro ao criar dieta. Tente novamente.');
      setShowModal(true);
      setTimeout(() => setShowModal(false), 2500);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="dietMain">
      <h3>Nova Dieta</h3>
      {/* {appointment_id ? (
        <div>Relacionado ao Agendamento:
          <div>Agendamento id: {appointment_id}</div>
          <div>Paciente id: {patient_id}</div>
          <div>Consulta id: {consultation_id}</div>
        </div>
      ) : (
        <p>ID do agendamento não fornecido.</p>
      )} */}
      <div className="dietForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, touched, errors, isValid, setFieldValue }) => (
            <Form>
              {/* {console.log("Estado do Formik:", { values, errors, touched, isValid, isSubmitting })} */}

              <div className="dietLine createDiet">
                <div className="diet-form-group">
                  <label>Dieta:</label>
                  <Field
                    as="select"
                    name="diet"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedDiet = Object.values(availableDiets)
                        .flat()
                        .find((diet) => diet.value === selectedValue);

                      // Atualiza os campos "diet" e "objective"
                      setFieldValue('diet', selectedValue);
                      setFieldValue('objective', selectedDiet?.objective || '');
                    }}
                  >
                    <option value="">Selecione</option>
                    {Object.entries(availableDiets).map(([category, diets]) => (
                      <optgroup key={category} label={formatDietGroup(category)}>
                        {diets.map((dietOption) => (
                          <option key={dietOption.value} value={dietOption.value}>
                            {dietOption.label}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </Field>
                  <ErrorMessage name="diet" component="div" className="error-message" />
                </div>

                <div className="diet-form-group">
                  <label>Objetivo:</label>
                  <Field
                    className="objectiveField"
                    id="objectiveField"
                    name="objective"
                    type="text"
                    readOnly
                  />
                  <ErrorMessage name="objective" component="div" className="error-message" />
                </div>

                <div className="diet-form-group">
                  <label>Total de Calorias desta Dieta:</label>
                  <Field
                    className="diet_total-calories"
                    name="diet_total_calories"
                    type="text"
                    onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'meal_total_calories')}
                    onChange={(e) => setFieldValue('meal_total_calories', e.target.value)}
                    readOnly
                    disabled
                  />
                  <ErrorMessage name="diet_total_calories" component="div" className="error-message" />
                </div>

              </div>



              <FieldArray name="meals">
                {({ push, remove }) => (
                  <>
                    {values.meals.map((meal, mealIndex) => (
                      <div key={mealIndex} className="meal-group">
                        <div className="dietLine">
                          <div className="diet-form-group">
                            <label>Refeição:</label>
                            <Field
                              as="select"
                              name={`meals[${mealIndex}].name`}
                            >
                              <option value="">Selecione</option>
                              {availableMeals.map((mealOption) => (
                                <option key={mealOption.value} value={mealOption.label}>
                                  {mealOption.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name={`meals[${mealIndex}].name`} component="div" className="error-message" />
                          </div>

                          <div className="diet-form-group" id="time-meal">
                            <label>Horário:</label>
                            <Field
                              id="time-meal"
                              name={`meals[${mealIndex}].time`}
                              type="time"
                            />
                            <ErrorMessage name={`meals[${mealIndex}].time`} component="div" className="error-message" />
                          </div>

                          <div className="diet-form-group">
                            <label>Total de Calorias desta Refeição:</label>
                            <Field
                              name={`meals[${mealIndex}].total_calories`}
                              type="number"
                              onKeyDown={(e) => onlyNumbers(e, setFieldValue, `meals[${mealIndex}].total_calories`)}
                              onChange={(e) => setFieldValue(`meals[${mealIndex}].total_calories`, e.target.value)}
                            />
                            <ErrorMessage name={`meals[${mealIndex}].total_calories`} component="div" className="error-message" />
                          </div>
                        </div>

                        <FieldArray name={`meals[${mealIndex}].foods`}>
                          {({ remove, push, replace }) => (
                            <>
                              {/* <div className="diet-form-group">
                                <label>Selecionar Alimento:</label>
                                <Select
                                  options={combinedFoods}
                                  value={values.meals[mealIndex].selectFood || null} // Vincule ao Formik
                                  onChange={(selectedOption) => {
                                    if (selectedOption) {
                                      // Verificar se existe um item vazio para substituir
                                      const emptyIndex = values.meals[mealIndex].foods.findIndex(
                                        (food) => !food.food_id
                                      );

                                      const newFood = {
                                        food_id: selectedOption.value,
                                        food_name: selectedOption.label,
                                        quantity: '',
                                        unit_measurement: '',
                                        kcal: selectedOption.kcal,
                                      };

                                      if (emptyIndex !== -1) {
                                        // Substituir o item vazio
                                        replace(emptyIndex, newFood);
                                      } else {
                                        // Adicionar um novo item
                                        push(newFood);
                                      }

                                      // Limpar o campo de seleção
                                      setFieldValue(`meals[${mealIndex}].selectFood`, null);
                                    }
                                  }}
                                  isClearable
                                  placeholder="Buscar alimento..."
                                />
                              </div> */}

                              <div className="diet-form-group">
                                <label htmlFor="foods">Selecionae um Alimento</label>
                                <Select
                                  options={combinedFoods}
                                  value={values.meals[mealIndex].selectFood || null} // Vincule ao Formik
                                  onChange={(selectedOption) => {
                                    if (selectedOption) {
                                      const newFood = {
                                        food_id: selectedOption.source === 'foods' ? selectedOption.value : null,
                                        taco_food_id: selectedOption.source === 'taco_food' ? selectedOption.value : null,
                                        food_name: selectedOption.label,
                                        quantity: '',
                                        unit_measurement: '',
                                        kcal: selectedOption.kcal || 0,
                                      };

                                      // Adicionar novo alimento ou substituir item vazio
                                      const emptyIndex = values.meals[mealIndex].foods.findIndex(
                                        (food) => !food.food_id && !food.taco_food_id
                                      );

                                      if (emptyIndex !== -1) {
                                        replace(emptyIndex, newFood);
                                      } else {
                                        push(newFood);
                                      }

                                      // Limpar o campo de seleção
                                      setFieldValue(`meals[${mealIndex}].selectFood`, null);
                                    }
                                  }}
                                  isClearable
                                  placeholder="Buscar alimento..."
                                />

                              </div>

                              {/* Exibir lista de alimentos adicionados */}
                              <ul>
                                {values.meals[mealIndex].foods.map((food, foodIndex) => (
                                  <li key={foodIndex}>
                                    <div className="dietLine">
                                      <div className="diet-form-group">
                                        <label>Quantidade:</label>
                                        <Field
                                          className="foodListField"
                                          name={`meals[${mealIndex}].foods[${foodIndex}].quantity`}
                                          type="number"
                                          onKeyDown={(e) => onlyNumbers(e, setFieldValue, `meals[${mealIndex}].foods[${foodIndex}].quantity`)}
                                          onChange={(e) => setFieldValue(`meals[${mealIndex}].foods[${foodIndex}].quantity`, e.target.value)}
                                        />
                                        <ErrorMessage
                                          name={`meals[${mealIndex}].foods[${foodIndex}].quantity`}
                                          component="div"
                                          className="error-message"
                                        />
                                      </div>

                                      <div className="diet-form-group">
                                        <label>Unid. Medida:</label>
                                        <Field
                                          className="foodListField"
                                          as="select"
                                          name={`meals[${mealIndex}].foods[${foodIndex}].unit_measurement`}
                                        >
                                          <option value="">Selecione</option>
                                          {measureUnits.map((units) => (
                                            <option key={units.value} value={units.label}>
                                              {units.label}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name={`meals[${mealIndex}].foods[${foodIndex}].unit_measurement`}
                                          component="div"
                                          className="error-message"
                                        />
                                      </div>

                                      <div className="diet-form-group foodName">
                                        <label>Alimento:</label>
                                        <strong>{`${food.food_name || "Novo Alimento"} - ${food.kcal || 0} kcal`}</strong>
                                      </div>
                                      <button
                                        className="btnIcoAddRm"
                                        type="button"
                                        onClick={() => remove(foodIndex)}
                                      >
                                        <FaMinusCircle /> Remover Alimento
                                      </button>

                                    </div>
                                  </li>
                                ))}
                              </ul>

                              {/* Botão para adicionar manualmente um novo grupo (opcional) */}
                              <button
                                className="btnIcoAddRm"
                                type="button"
                                onClick={() => {
                                  // Adicionar apenas se o último grupo já estiver preenchido
                                  if (
                                    values.meals[mealIndex].foods.length === 0 ||
                                    values.meals[mealIndex].foods.every((food) => food.food_id || food.taco_food_id)
                                  ) {
                                    push({ food_id: '', quantity: '', unit_measurement: '', kcal: '' });
                                  }
                                }}
                              >
                                <FaPlusCircle /> Adicionar Alimento
                              </button>
                            </>
                          )}
                        </FieldArray>


                        <div className='mealButtonLine'>
                          <button
                            className='removeMeal'
                            type="button"
                            onClick={() => remove(mealIndex)}>
                            Remover Refeição
                          </button>
                        </div>

                      </div>
                    ))}

                    <button
                      className='addMeal'
                      type="button"
                      onClick={() => push({ name: '', time: '', total_calories: '', foods: [] })}
                    >
                      Adicionar Refeição
                    </button>
                  </>
                )}
              </FieldArray>

              <div className="dietButtons">
                <button
                  className='sendBtn'
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Criando...' : 'Criar Dieta'}
                </button>

                <button
                  className='backBtn'
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => navigate(-1)}
                // onClick={handleCancelDiet}
                >
                  {isSubmitting ? 'Saindo...' : 'Cancelar'}
                </button>

                {/* <button
                  type="button"
                  onClick={() => console.log("Valores do Formik:", values)}
                >
                  Verificar Estado
                </button> */}
              </div>

            </Form>
          )}
        </Formik>
        {showModal && (
          <ModalForms
            message={message}
            onConfirm={() => setShowModal(false)}
          />
        )}
      </div>
    </div >
  );
};

export default CreateDiet;
