import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllFoodsAndTacoFoods } from '../../service/api';


// Fetch all Taco foods
export const fetchAllFoodsAndTacoFoods = createAsyncThunk(
  'food/fetchAllTacoFoods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllFoodsAndTacoFoods();
      // console.log(JSON.stringify(response));
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)
