
function dietBold(pdf, mealIndex, meal, currentY, formatTime) {
  pdf.text(`Refeição ${mealIndex + 1}: ${meal.name || 'N/A'}`, 12, currentY);
  pdf.text(`Hora: ${formatTime(meal.time) || 'N/A'}`, 55, currentY);
  pdf.text(`Total de Calorias desta Refeição: ${meal.total_calories || 'N/A'}`, 80, currentY);
};

function dietBold1(pdf, mealIndex, meal, currentY, formatTime) {
  pdf.setFont("helvetica", "bold");
  pdf.text(`Refeição ${mealIndex + 1}:`, 12, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${meal.name || 'N/A'}`, 28, currentY);

  pdf.setFont("helvetica", "bold");
  pdf.text(`Hora:`, 55, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${formatTime(meal.time) || 'N/A'}`, 65, currentY);

  pdf.setFont("helvetica", "bold");
  pdf.text(`Total de Calorias desta Refeição:`, 80, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${meal.total_calories || 'N/A'}`, 125, currentY);

}

function dietBold2(pdf, mealIndex, meal, currentY, formatTime) {
  pdf.setFont("helvetica", "normal");
  pdf.text(`Refeição ${mealIndex + 1}:`, 12, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${meal.name || 'N/A'}`, 28, currentY);

  pdf.setFont("helvetica", "normal");
  pdf.text(`Hora:`, 55, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${formatTime(meal.time) || 'N/A'}`, 65, currentY);

  pdf.setFont("helvetica", "normal");
  pdf.text(`Total de Calorias desta Refeição:`, 80, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${meal.total_calories || 'N/A'}`, 125, currentY);
  pdf.setFont("helvetica", "normal");
}

// ..................................................ANTROPOMETRIA.................................................................................

function anthropometryBold(pdf, data, currentY, lineSpacing, formatDate) {
  pdf.setFont("helvetica", "normal");
  pdf.text(`Data da Medição: ${formatDate(data.measurement_date)}`, 12, currentY);
  pdf.text(`Equação Utilizada: ${data.fat_calculate_equation}`, 65, currentY);
  currentY += lineSpacing;
  pdf.text(`Altura: ${data.height ? `${data.height} cm` : 'N/A'}`, 12, currentY);
  pdf.text(`Peso: ${data.current_weight ? `${data.current_weight} kg` : 'N/A'}`, 65, currentY);
  pdf.text(`IMC: ${data.bmi || 'N/A'} kg/m²`, 140, currentY);
  currentY += lineSpacing;
  pdf.text(`VET: ${data.vet || 'N/A'} kcal/dia`, 12, currentY);
  pdf.text(`Percentual Ideal de Gordura: ${data.ideal_fat_percentage || 'N/A'}`, 65, currentY);
  pdf.text(`Classif. Blackburn: ${data.blackburn_classification || 'N/A'}`, 140, currentY);
  currentY += lineSpacing;
  pdf.text(`MCM: ${data.mcm || 'N/A'} %`, 12, currentY);
  pdf.text(`Peso de Massa Muscular: ${data.muscle_mass_weight || 'N/A'} kg`, 65, currentY);
  pdf.text(`Classif. da Massa Muscular: ${data.muscle_mass_classification || 'N/A'}`, 140, currentY);
  currentY += lineSpacing;
  pdf.text(`Peso da Massa Corporal: ${data.fat_mass_weight || 'N/A'} kg`, 12, currentY);
  pdf.text(`Total de água Corporal: ${data.total_body_water || 'N/A'} %`, 65, currentY);
  pdf.text(`Classif. de Bray: ${data.bray_classification || 'N/A'}`, 140, currentY);
  currentY += lineSpacing;
  pdf.text(`Gordura Corporal: ${data.body_fat_percentage || 'N/A'} %`, 12, currentY);
  pdf.text(`Gordura Glicêmica: ${data.glycemic_fat_percentage || 'N/A'} %`, 65, currentY);
  pdf.text(`Classif. PG: ${data.pg_classification_code || 'N/A'}`, 140, currentY);
  currentY += lineSpacing;
  pdf.text(`AMB: ${data.amb || 'N/A'} %`, 12, currentY);
  pdf.text(`Taxa do Metabolismo Basal: ${data.tmb || 'N/A'} kcal/dia`, 65, currentY);
  pdf.text(`Classif. da Cintura: ${data.waist_classification || 'N/A'}`, 140, currentY);
};

function anthropometryBold1(pdf, data, currentY, lineSpacing, formatDate) {
  pdf.setFont("helvetica", "bold");
  pdf.text(`Data da Medição: `, 12, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${formatDate(data.measurement_date)}`, 36, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Equação Utilizada: `, 65, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.fat_calculate_equation}`, 92, currentY);
  pdf.setFont("helvetica", "bold");
  currentY += lineSpacing;
  pdf.text(`Altura: `, 12, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.height ? `${data.height} cm` : 'N/A'}`, 23, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Peso:`, 65, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.current_weight ? `${data.current_weight} kg` : 'N/A'}`, 75, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`IMC: `, 140, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.bmi || 'N/A'} kg/m²`, 150, currentY);
  pdf.setFont("helvetica", "bold");
  currentY += lineSpacing;
  pdf.text(`VET: `, 12, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.vet || 'N/A'} kcal/dia`, 23, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Percentual Ideal de Gordura: `, 65, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.ideal_fat_percentage || 'N/A'} `, 104, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Classif. Blackburn: `, 140, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.blackburn_classification || 'N/A'}`, 167, currentY);
  pdf.setFont("helvetica", "bold");
  currentY += lineSpacing;
  pdf.text(`MCM: `, 12, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.mcm || 'N/A'} %`, 23, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Peso de Massa Muscular: `, 65, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(` ${data.muscle_mass_weight || 'N/A'} kg`, 100, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Classif. da Massa Muscular: `, 140, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.muscle_mass_classification || 'N/A'}`, 178, currentY);
  pdf.setFont("helvetica", "bold");
  currentY += lineSpacing;
  pdf.text(`Peso da Massa Corporal: `, 12, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.fat_mass_weight || 'N/A'} kg`, 47, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Total de água Corporal: `, 65, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.total_body_water || 'N/A'} %`, 98, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Classif. de Bray OMS: `, 140, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.bray_classification || 'N/A'}`, 171, currentY);
  pdf.setFont("helvetica", "bold");
  currentY += lineSpacing;
  pdf.text(`Gordura Corporal: `, 12, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.body_fat_percentage || 'N/A'} %`, 38, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Gordura Glicêmica: `, 65, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.glycemic_fat_percentage || 'N/A'} %`, 92, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Classif. PG: `, 140, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.pg_classification_code || 'N/A'}`, 158, currentY);
  pdf.setFont("helvetica", "bold");
  currentY += lineSpacing;
  pdf.text(`AMB: `, 12, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.amb || 'N/A'} %`, 20, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Taxa do Metabolismo Basal: `, 65, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.tmb || 'N/A'} kcal/dia`, 104, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Classif. da Cintura: `, 140, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`${data.waist_classification || 'N/A'}`, 167, currentY);
  currentY += lineSpacing;
};

function anthropometryBold2(pdf, data, currentY, lineSpacing, formatDate) {
  pdf.setFont("helvetica", "normal");
  pdf.text(`Data da Medição: `, 12, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${formatDate(data.measurement_date)}`, 36, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Equação Utilizada: `, 65, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.fat_calculate_equation}`, 92, currentY);
  pdf.setFont("helvetica", "normal");
  currentY += lineSpacing;
  pdf.text(`Altura: `, 12, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.height ? `${data.height} cm` : 'N/A'}`, 23, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Peso:`, 65, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.current_weight ? `${data.current_weight} kg` : 'N/A'}`, 75, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`IMC: `, 140, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.bmi || 'N/A'} kg/m²`, 150, currentY);
  pdf.setFont("helvetica", "normal");
  currentY += lineSpacing;
  pdf.text(`VET: `, 12, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.vet || 'N/A'} kcal/dia`, 23, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Percentual Ideal de Gordura: `, 65, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.ideal_fat_percentage || 'N/A'} `, 104, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Classif. Blackburn: `, 140, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.blackburn_classification || 'N/A'}`, 167, currentY);
  pdf.setFont("helvetica", "normal");
  currentY += lineSpacing;
  pdf.text(`MCM: `, 12, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.mcm || 'N/A'} %`, 23, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Peso de Massa Muscular: `, 65, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(` ${data.muscle_mass_weight || 'N/A'} kg`, 100, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Classif. da Massa Muscular: `, 140, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.muscle_mass_classification || 'N/A'}`, 178, currentY);
  pdf.setFont("helvetica", "normal");
  currentY += lineSpacing;
  pdf.text(`Peso da Massa Corporal: `, 12, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.fat_mass_weight || 'N/A'} kg`, 47, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Total de água Corporal: `, 65, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.total_body_water || 'N/A'} %`, 98, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Classif. de Bray OMS: `, 140, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.bray_classification || 'N/A'}`, 171, currentY);
  pdf.setFont("helvetica", "normal");
  currentY += lineSpacing;
  pdf.text(`Gordura Corporal: `, 12, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.body_fat_percentage || 'N/A'} %`, 38, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Gordura Glicêmica: `, 65, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.glycemic_fat_percentage || 'N/A'} %`, 92, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Classif. PG: `, 140, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.pg_classification_code || 'N/A'}`, 158, currentY);
  pdf.setFont("helvetica", "normal");
  currentY += lineSpacing;
  pdf.text(`AMB: `, 12, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.amb || 'N/A'} %`, 20, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Taxa do Metabolismo Basal: `, 65, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.tmb || 'N/A'} kcal/dia`, 104, currentY);
  pdf.setFont("helvetica", "normal");
  pdf.text(`Classif. da Cintura: `, 140, currentY);
  pdf.setFont("helvetica", "bold");
  pdf.text(`${data.waist_classification || 'N/A'}`, 167, currentY);
};

function anthropometryBold3(pdf, data, currentY, lineSpacing, formatDate) {
  // Função auxiliar para aplicar o texto com estilos diferentes
  function addTextWithStyles(pdf, boldText, normalText, xBold, xNormal, y) {
    pdf.setFont('helvetica', 'bold');
    pdf.text(boldText, xBold, y);
    pdf.setFont('helvetica', 'normal');
    pdf.text(normalText, xNormal, y);
  }

  currentY += lineSpacing;
  addTextWithStyles(pdf, 'Data da Medição:', `${formatDate(data.measurement_date)}`, 12, 36, currentY);
  addTextWithStyles(pdf, 'Equação Utilizada:', `${data.fat_calculate_equation || 'N/A'}`, 80, 100, currentY);

  currentY += lineSpacing;
  addTextWithStyles(pdf, 'Altura:', `${data.height ? `${data.height} cm` : 'N/A'}`, 12, 36, currentY);
  addTextWithStyles(pdf, 'Peso:', `${data.current_weight ? `${data.current_weight} kg` : 'N/A'}`, 80, 105, currentY);
  addTextWithStyles(pdf, 'IMC:', `${data.bmi || 'N/A'} kg/m²`, 145, 175, currentY);

  currentY += lineSpacing;
  addTextWithStyles(pdf, 'VET:', `${data.vet || 'N/A'} kcal/dia`, 12, 36, currentY);
  addTextWithStyles(pdf, 'Percentual Ideal de Gordura:', `${data.ideal_fat_percentage || 'N/A'}`, 80, 145, currentY);
  addTextWithStyles(pdf, 'Classif. Blackburn:', `${data.blackburn_classification || 'N/A'}`, 145, 175, currentY);

  currentY += lineSpacing;
  addTextWithStyles(pdf, 'MCM:', `${data.mcm || 'N/A'} %`, 12, 36, currentY);
  addTextWithStyles(pdf, 'Peso de Massa Muscular:', `${data.muscle_mass_weight || 'N/A'} kg`, 80, 145, currentY);
  addTextWithStyles(pdf, 'Classif. da Massa Muscular:', `${data.muscle_mass_classification || 'N/A'}`, 145, 175, currentY);

  currentY += lineSpacing;
  addTextWithStyles(pdf, 'Peso da Massa Corporal:', `${data.fat_mass_weight || 'N/A'} kg`, 12, 55, currentY);
  addTextWithStyles(pdf, 'Total de Água Corporal:', `${data.total_body_water || 'N/A'} %`, 80, 145, currentY);
  addTextWithStyles(pdf, 'Classif. de Bray OMS:', `${data.bray_classification || 'N/A'}`, 145, 175, currentY);

  currentY += lineSpacing;
  addTextWithStyles(pdf, 'Gordura Corporal:', `${data.body_fat_percentage || 'N/A'} %`, 12, 45, currentY);
  addTextWithStyles(pdf, 'Gordura Glicêmica:', `${data.glycemic_fat_percentage || 'N/A'} %`, 80, 145, currentY);
  addTextWithStyles(pdf, 'Classif. PG:', `${data.pg_classification_code || 'N/A'}`, 145, 175, currentY);

  currentY += lineSpacing;
  addTextWithStyles(pdf, 'AMB:', `${data.amb || 'N/A'} %`, 12, 30, currentY);
  addTextWithStyles(pdf, 'Taxa do Metabolismo Basal:', `${data.tmb || 'N/A'} kcal/dia`, 80, 145, currentY);
  addTextWithStyles(pdf, 'Classif. da Cintura:', `${data.waist_classification || 'N/A'}`, 145, 175, currentY);

}

module.exports = {
  dietBold,
  dietBold1,
  dietBold2,
  anthropometryBold,
  anthropometryBold1,
  anthropometryBold2,
  anthropometryBold3,
};
