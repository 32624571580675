import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompany, getPatient, getAllProfessionals, getPerson, getPersonById } from '../../service/api';

// Fetch all companies
export const fetchCompanies = createAsyncThunk(
  'relatedEntities/fetchCompanies',
  async () => {
    const response = await getCompany();
    return response.data;
  }
);

// Fetch all patients
export const fetchPatients = createAsyncThunk(
  'relatedEntities/fetchPatients',
  async () => {
    const response = await getPatient();
    return response.data.data;
  }
);

// Fetch all professionals
export const fetchAllProfessionals = createAsyncThunk(
  'relatedEntities/fetchAllProfessionals',
  async () => {
    const response = await getAllProfessionals();
    return response.data;
  }
);

export const fetchAllPersons = createAsyncThunk(
  'relatedEntities/fetchAllPersons',
  async () => {
    const response = await getPerson();
    // console.log(`retorno do action person:${JSON.stringify(response.data)}`);
    return response.data;
  }
);


export const fetchPersonById = createAsyncThunk(
  'relatedEntities/fetchPersonById',
  async (person_id) => {
    const response = await getPersonById(person_id);
    // console.log("O FetchPersonById é:", JSON.stringify(response, null, 2));
    return response.data;
  }
);