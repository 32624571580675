import React from 'react';
import {
  MdDashboard,
  MdGroup,
  MdGroupAdd,
  MdDomainAdd,
  MdSchedule,
  MdAllInclusive,
  MdCalculate,
  MdExitToApp,
  MdAssignmentAdd,
  MdCurrencyExchange,
  MdOutlineCurrencyExchange,
  MdFactory,
} from 'react-icons/md';
import { FaBuilding } from "react-icons/fa";
import { GiFruitBowl } from "react-icons/gi";
import { TbRulerMeasure } from "react-icons/tb";
import { FaUserDoctor } from "react-icons/fa6";

export const linksConfig = [
  {
    type: 'link',
    label: 'Dashboard',
    icon: <MdDashboard className='icon' />,
    navigateTo: '/home/dashboard',
    requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
  },
  {
    type: 'dropdown',
    label: 'Agendamentos',
    icon: <MdSchedule className='icon' />,
    subItems: [
      {
        label: 'Todos os Agendamentos',
        icon: <FaBuilding className='icon' />,
        navigateTo: '/home/appointment/get',
        requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
      },
      {
        label: 'Novo Agendamento',
        icon: <MdDomainAdd className='icon' />,
        navigateTo: '/home/appointment/create',
        requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
      }
    ],
    requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
  },
  {
    type: 'dropdown',
    label: 'Consultas',
    icon: <FaUserDoctor className='icon' />,
    subItems: [
      {
        label: 'Todas as Consultas',
        icon: <FaUserDoctor className='icon' />,
        navigateTo: '/home/consultation/get',
        requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
      },
      {
        label: 'Nova Consulta',
        icon: <FaUserDoctor className='icon' />,
        navigateTo: '/home/appointment/get',
        requiredRoles: ['Admin', 'Manager']
      },
      {
        label: 'Todas as Consultas',
        icon: <FaUserDoctor className='icon' />,
        navigateTo: '/home/consultation/get/byPatient',
        requiredRoles: ['Admin', 'Manager']
      }
    ],
    requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
  },
  {
    type: 'dropdown',
    label: 'Antropometria',
    icon: <TbRulerMeasure className='icon' />,
    subItems: [
      {
        label: 'Por Paciente',
        icon: <TbRulerMeasure className='icon' />,
        navigateTo: '/home/anthropometry/get',
        requiredRoles: ['Admin', 'Manager', 'Coordinator']
      },
      {
        label: 'Nova Antropometria',
        icon: <TbRulerMeasure className='icon' />,
        navigateTo: '/home/anthropometry/create',
        requiredRoles: ['Admin', 'Manager', 'Coordinator']
      }
    ],
    requiredRoles: ['Admin', 'Manager', 'Coordinator']
  },
  {
    type: 'dropdown',
    label: 'Alimentos',
    icon: <GiFruitBowl className='icon' />,
    subItems: [
      {
        label: 'Todos os Alimentos',
        icon: <GiFruitBowl className='icon' />,
        navigateTo: '/home/nutridata/get/atfd',
        requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
      },
      {
        label: 'Novo Alimento',
        icon: <GiFruitBowl className='icon' />,
        // navigateTo: '/home/nutridata/create',
        navigateTo: '/home/dashboard',
        requiredRoles: ['Admin', 'Manager', 'Coordinator']
      },
      // {
      //   label: 'Alimento Dinâmico',
      //   icon: <GiFruitBowl className='icon' />,
      //   navigateTo: '/home/nutridata/get/dynamic',
      //   requiredRoles: ['Admin', 'Manager', 'Coordinator']
      // }
    ],
    requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
  },
  {
    type: 'dropdown',
    label: 'Pessoas',
    icon: <MdGroup className='icon' />,
    subItems: [
      {
        label: 'Todas as Pessoas',
        icon: <MdGroup className='icon' />,
        navigateTo: '/home/person/get',
        requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
      },
      {
        label: 'Nova pessoa',
        icon: <MdGroupAdd className='icon' />,
        navigateTo: '/home/person/create',
        requiredRoles: ['Admin', 'Manager', 'Coordinator']
      },
      {
        label: 'Lista todas Pessoas',
        icon: <MdGroup className='icon' />,
        navigateTo: '/home/person/list/get',
        requiredRoles: ['Admin']
      },
    ],
    requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
  },
  {
    type: 'dropdown',
    label: 'Empresas',
    icon: <MdFactory className='icon' />,
    subItems: [
      {
        label: 'Todas as Empresas',
        icon: <FaBuilding className='icon' />,
        navigateTo: '/home/company/get',
        requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
      },
      {
        label: 'Nova Empresa',
        icon: <MdDomainAdd className='icon' />,
        navigateTo: '/home/company/create',
        requiredRoles: ['Admin', 'Manager', 'Coordinator']
      }
    ],
    requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
  },
  {
    type: 'dropdown',
    label: 'Convênios',
    icon: <MdAllInclusive className='icon' />,
    subItems: [
      {
        label: 'Todos os convênios',
        icon: <MdAssignmentAdd className='icon' />,
        navigateTo: '/home/dashboard',
        requiredRoles: ['Admin']
      },
      {
        label: 'Novo convênio',
        icon: <MdAssignmentAdd className='icon' />,
        navigateTo: '/home/dashboard',
        requiredRoles: ['Admin']
      }
    ],
    requiredRoles: ['Admin']
  },
  {
    type: 'dropdown',
    label: 'Financeiro',
    icon: <MdCalculate className='icon' />,
    subItems: [
      {
        label: 'Contas a pagar',
        icon: <MdCurrencyExchange className='icon' />,
        navigateTo: '/home/dashboard',
        requiredRoles: ['Admin']
      },
      {
        label: 'Contas a receber',
        icon: <MdOutlineCurrencyExchange className='icon' />,
        navigateTo: '/home/dashboard',
        requiredRoles: ['Admin']
      }
    ],
    requiredRoles: ['Admin']
  },

  {
    type: 'dropdown',
    label: 'Usuários',
    icon: <MdGroup className='icon' />,
    subItems: [
      {
        label: 'Todos os usuários',
        icon: <MdGroup className='icon' />,
        navigateTo: '/home/user/get',
        requiredRoles: ['Admin']
      },
      {
        label: 'Novo usuário',
        icon: <MdGroupAdd className='icon' />,
        navigateTo: '/home/user/create',
        requiredRoles: ['Admin']
      }
    ],
    requiredRoles: ['Admin', 'Manager', 'Coordinator']
  },
  {
    type: 'link',
    label: 'Sair',
    icon: <MdExitToApp className='icon' />,
    action: 'logout',
    requiredRoles: ['User', 'Manager', 'Admin']
  }

];
// {
//   type: 'dropdown',
//   label: 'Pacientes',
//   icon: <MdGroup className='icon' />,
//   subItems: [
//     {
//       label: 'Todos os pacientes',
//       icon: <MdGroup className='icon' />,
//       navigateTo: '/home/patient/get',
//       requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
//     },
//     {
//       label: 'Novo paciente',
//       icon: <MdGroupAdd className='icon' />,
//       navigateTo: '/home/person/create',
//       requiredRoles: ['Admin', 'Manager', 'Coordinator']
//     }
//   ],
//   requiredRoles: ['Admin', 'Manager', 'Coordinator', 'User']
// },
