/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteConsultationById, fetchConsultations } from '../../../redux/actions/consultationActions';
import { setCurrentConsultation } from '../../../redux/slices/consultationSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { MdDelete, MdCreate } from 'react-icons/md';
import { FaUserMd, FaCalendar, FaHospitalAlt, FaUser } from "react-icons/fa";
import { FaUserClock, FaClipboardCheck, FaClock } from "react-icons/fa6";

import '../styles/GetConsultation.css';
import '../../../Mobile.css';

function GetConsultation() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const icoUpdate = <MdCreate className='icon' />;
  const icoTrash = <MdDelete className='icon' />;
  const icoDoctor = <FaUserMd className='mobileIcon' />;
  const icoTime = <FaUserClock className='mobileIcon' />;
  const icoStatus = <FaClock className='mobileIcon' />;
  const icoCalendar = <FaCalendar className='mobileIcon' />;
  const icoHospital = <FaHospitalAlt className='mobileIcon' />;
  const icoPatient = <FaUser className='mobileIcon' />;

  const [consultationIdToDelete, setConsultationIdToDelete] = useState('');
  const [filteredConsultations, setFilteredConsultations] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const updateBtn = "Editar";
  const deleteBtn = "Deletar";
  const detailsBtn = "Clique para detalhes";
  const { consultations = [], loading, error } = useSelector((state) => state.consultations);
  // console.log(consultations);

  useEffect(() => {
    dispatch(fetchConsultations());
  }, [dispatch]);

  useEffect(() => {
    if (consultations && consultations.length) {
      setFilteredConsultations(consultations);
    }
  }, [consultations]);

  const handleDelete = async (consultation_id) => {
    setConsultationIdToDelete(consultation_id);
    setMessage(`Tem certeza que deseja remover a consulta de ID: ${consultation_id}?`);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await dispatch(deleteConsultationById(consultationIdToDelete));
      setShowModal(true);
      setMessage(`Consulta ID: ${consultationIdToDelete} removida com sucesso!`);
      setTimeout(() => {
        setShowModal(false);
        setIsDeleting(false);
      }, 2000);
    } catch (error) {
      setMessage(`Erro ao tentar deletar a consulta: ${error}`);
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleUpdate = (consultation) => {
    // Navegar para UpdateConsultation enviando o state com patient_id e appointment_id
    navigate(`/home/consultation/update/${consultation.consultation_id}`, {
      state: {
        consultation_id: consultation.consultation_id,
        patient_id: consultation.patient_id,
        appointment_id: consultation.appointment_id,
        diet_id: consultation.diet_id,
      },
    });
  };

  const handleShowConsultation = (consultation) => {
    navigate(`/home/consultation/show/${consultation.consultation_id}`, {
      state: {
        consultation_id: consultation.consultation_id,
        patient_id: consultation.patient_id,
        appointment_id: consultation.appointment_id,
        diet_id: consultation.diet_id,
      },
    });
  };


  const formatStatus = (status) => {
    switch (status) {
      case 'scheduled':
        return 'Agendada';
      case 'confirmed':
        return 'Confirmada';
      case 'in_progress':
        return 'Em Andamento';
      case 'completed':
        return 'Concluída';
      case 'cancelled':
        return 'Cancelada';
      default:
        return 'Agendada';
    }
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    const filtered = consultations.filter(consultation =>
      `${consultation.company_name || ''} ${consultation.patient_name} ${consultation.professional_name} ${formatStatus(consultation.status)}`.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredConsultations(filtered);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const getPageButtonClass = (index) => (currentPage === index + 1 ? 'active' : '');

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }

    return () => clearInterval(interval);
  }, [loading]);

  const formatDate = (date) => {
    if (!date) return 'Data inválida';
    const d = new Date(date);
    return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
  };

  const formatTime = (time) => {
    if (!time) return '';
    return time.slice(0, 5);
  };

  const currentItems = filteredConsultations.slice(indexOfFirstItem, indexOfLastItem);

  const hasRole = (role) => loginUser.roles?.includes(role);

  return (
    <>
      {showModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          isDeleting={isDeleting}
        />
      )}

      <div className="col-md-12">
        <h3>Consultas</h3>
        <SearchBar onSearch={handleSearch} />
      </div>

      <div className="boxGetConsultation">
        <table className='getConsultationTable'>
          <thead>
            <tr>
              <td>Paciente</td>
              <td>Clínica</td>
              <td>Profissional</td>
              <td>Data da Consulta</td>
              <td>Status</td>
              {hasRole('Manager') && <td>Ações</td>}
              {hasRole('Admin') && <td>Ações</td>}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="rowMessage" colSpan="7">Aguarde{ellipsis}</td>
              </tr>
            ) : currentItems.length === 0 ? (
              <tr>
                <td className="colLine">nenhum registro localizado</td>
              </tr>
            ) : (

              currentItems.map((consultation) => (
                <tr key={consultation.consultation_id}
                  className={`tr 
                  ${consultation.status === 'cancelled' ? 'cancelled-line' : ''} 
                  ${consultation.status === 'changed' ? 'changed-line' : ''} 
                  ${consultation.status === 'completed' ? 'completed-line' : ''} 
                  ${consultation.status === 'confirmed' ? 'confirmed-line' : ''} 
                  ${consultation.status === 'in_progress' ? 'in_progress-line' : ''}`}
                >
                  <td>
                    <a
                      onClick={() => handleShowConsultation(consultation)}
                      data-tip={detailsBtn}
                    >
                      {icoPatient} {consultation.patient_name}
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={() => handleShowConsultation(consultation)}
                      data-tip={detailsBtn}
                    >
                      {icoHospital} {consultation.company_name || 'N/A'}
                    </a></td>
                  <td>
                    <a
                      onClick={() => handleShowConsultation(consultation)}
                      data-tip={detailsBtn}
                    >
                      {icoDoctor} {consultation.professional_name}
                    </a></td>
                  <td>
                    <a
                      onClick={() => handleShowConsultation(consultation)}
                      data-tip={detailsBtn}
                    >
                      {icoCalendar} {formatDate(consultation.consult_date)}
                    </a></td>
                  <td>{icoStatus} {formatStatus(consultation.status)}</td>
                  {hasRole('Manager') && (
                    <td className="update">
                      <button
                        className='icoBtn'
                        type="button"
                        onClick={() => handleUpdate(consultation)} // Passa o objeto completo
                        data-tip={updateBtn}
                      >
                        {icoUpdate}
                      </button>
                    </td>
                  )}
                  {hasRole('Admin') && (
                    <td className="delete">
                      <button
                        className={
                          consultation.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                        }
                        type="button"
                        onClick={() => handleUpdate(consultation)} // Passa o objeto completo
                        data-tip={updateBtn}
                      >
                        {icoUpdate}
                      </button>
                      <button
                        className={
                          consultation.status === 'completed' ? 'showActionBtn-disable' : 'icoBtn'
                        }
                        type="button"
                        onClick={() => handleDelete(consultation.consultation_id)}
                        data-tip={deleteBtn}
                      >
                        {icoTrash}
                      </button>
                    </td>
                  )}
                </tr>
              ))



            )}
          </tbody>
        </table>

        {/* Paginação */}
        <ul className="pagination">
          {Array.from({ length: Math.ceil(filteredConsultations.length / itemsPerPage) }, (_, index) => (
            <li key={index}>
              <button
                className={getPageButtonClass(index)}
                onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default GetConsultation;
