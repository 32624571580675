import { combineReducers } from '@reduxjs/toolkit';
import appointmentSlice from './slices/appointmentSlice';
import personSlice from './slices/personSlice';
import relatedEntitiesSlice from './slices/relatedEntitiesSlice';
import companySlice from './slices/companySlice';
import userSlice from './slices/userSlice';
import nutridataSlice from './slices/nutridataSlice';
import anthropometrySlice from './slices/anthropometrySlice';
import consultationSlice from './slices/consultationSlice';
import patientSlice from './slices/patientSlice';
import dietSlice from './slices/dietSlice';
import foodSlice from './slices/foodSlice';

const rootReducer = combineReducers({
  appointments: appointmentSlice,
  persons: personSlice,
  relatedEntities: relatedEntitiesSlice,
  companies: companySlice,
  users: userSlice,
  nutridata: nutridataSlice,
  anthropometry: anthropometrySlice,
  consultations: consultationSlice,
  patients: patientSlice,
  diets: dietSlice,
  foodsAndTacoFoods: foodSlice,
});

export default rootReducer;
