import React from 'react'

function CalendarInfo({ appointmentToday, appointmentTotal, totalMouthAppointments }) {
  return (
    <>
      <div className='calendarInfo groupBtn-G'>
        <div>Agendamentos de hoje: {appointmentToday}</div>
        <div>Total de Agendamentos este Mês: {totalMouthAppointments}</div>
        <div>Total de Agendamentos: {appointmentTotal}</div>
      </div>
    </>
  )
}

export default CalendarInfo