// src/redux/actions/consultationActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getConsultation,
  getConsultationById,
  deleteConsultation,
  updateConsultation,
  createConsultation,
  getConsultationByPersonId,
  getConsultationByPatientId,
  getConsultationByAppointmentId,
  completeConsultation,
} from '../../service/api';

// Fetch consultations
export const fetchConsultations = createAsyncThunk(
  'consultations/fetchConsultations',
  async () => {
    const response = await getConsultation();
    return response.data;
  }
);

// Delete consultation
export const deleteConsultationById = createAsyncThunk(
  'consultations/deleteConsultationById',
  async (consultation_id) => {
    await deleteConsultation(consultation_id);
    return consultation_id;
  }
);

// Update consultation
export const updateConsultationById = createAsyncThunk(
  'consultations/updateConsultationById',
  async ({ consultation_id, data }) => {
    try {
      const response = await updateConsultation(consultation_id, data);
      // console.log("Resposta da API para updateConsultationById:", response); 
      return response.data;
    } catch (error) {
      console.error("Erro na API:", error);
      throw error;
    }
  }
);


// Create consultation
export const createNewConsultation = createAsyncThunk(
  'consultations/createNewConsultation',
  async (formData, { dispatch, rejectWithValue }) => {
    try {
      const response = await createConsultation(formData);
      const consultationId = response.data.consultation_id;

      // Opcional: Buscar os detalhes completos da consulta recém-criada
      const consultationDetailsResponse = await getConsultationById(consultationId);
      return consultationDetailsResponse.data; // Retorna os detalhes completos

    } catch (error) {
      console.error("Error creating consultation:", error);
      return rejectWithValue(error.response.data);
    }
  }
);


// Get consultation by ID
export const fetchConsultationById = createAsyncThunk(
  'consultations/fetchConsultationById',
  async (consultation_id) => {
    const response = await getConsultationById(consultation_id);
    // console.log('Payload retornado:', response.data);
    return response.data;
  }
);

// GetById consultation by PersonId
export const fetchConsultationByPersonId = createAsyncThunk(
  'consultations/fetchConsultationByPersonId',
  async ({ person_id, person_type }) => {
    const response = await getConsultationByPersonId(person_id, person_type);
    return response.data;
  }
);

//Get consultaion By Patient Id
export const fetchConsultationByPatientId = createAsyncThunk(
  'consultations/fetchByPatientId',
  async (patient_id, { rejectWithValue }) => {
    try {
      const response = await getConsultationByPatientId(patient_id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


// GetByAppointmentId consultation
export const fetchConsultationByAppointmentId = createAsyncThunk(
  'consultations/fetchConsultationByAppointmentId',
  async (appointment_id) => {
    const response = await getConsultationByAppointmentId(appointment_id);
    // console.log("O FetchConsultationById é:", JSON.stringify(response, null, 2));
    return response.data;
  }
);

// export const putCompleteConsultation = createAsyncThunk(
//   'consultations/handleCompleteConsultation',
//   async (consultation_id) => {
//     const response = await completeConsultation(consultation_id);
//     // console.log("O FetchConsultationById é:", JSON.stringify(response, null, 2));
//     return response.data;
//   }
// );

export const putCompleteConsultation = createAsyncThunk(
  'consultations/handleCompleteConsultation',
  async ({ consultation_id, status }) => {
    const response = await completeConsultation(consultation_id, status);
    return response.data;
  }
);