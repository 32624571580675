import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllFoodsAndTacoFoods,
} from '../actions/foodActions';

const foodsAndTacoFoodsSlice = createSlice({
  name: 'foodsAndTacoFoods',
  initialState: {
    foodsAndTacoFoods: [],         // Lista de todos os alimentos Foods e Taco_Foods
    currentFoodOrTaco: null, // Alimento Taco atualmente selecionado
    loading: false,        // Estado de carregamento
    error: null,           // Estado de erro
  },
  reducers: {
    setCurrentFoodOrTaco: (state, action) => {
      state.currentFoodOrTaco = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFoodsAndTacoFoods.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllFoodsAndTacoFoods.fulfilled, (state, action) => {
        state.loading = false;
        state.foodsAndTacoFoods = Array.isArray(action.payload.foods) ? action.payload.foods : [];
      })
      .addCase(fetchAllFoodsAndTacoFoods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Erro ao carregar alimentos';
      });
  },
});

export const { setCurrentFoodOrTaco } = foodsAndTacoFoodsSlice.actions;
export default foodsAndTacoFoodsSlice.reducer;