import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { fetchAppointmentById, updateAppointmentById } from '../../../redux/actions/appointmentsActions';
import { fetchConsultationByAppointmentId } from '../../../redux/actions/consultationActions';
import { MdEmail, MdPrint, MdExitToApp, MdEdit } from 'react-icons/md';
import { FaUserMd } from "react-icons/fa";

import '../styles/ShowAppointment.css';
import { clearAppointment } from '../../../redux/slices/appointmentSlice';

function ShowAppointment() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appointment_id } = useParams();

  const icoDoctor2 = <FaUserMd className='icon' />
  const startConsult = "Iniciar Consulta";

  const { currentAppointment, loading, error } = useSelector((state) => state.appointments);
  const [appointmentData, setAppointmentData] = useState(null);

  const [consultationData, setConsultationData] = useState(null);

  useEffect(() => {
    if (appointment_id) {
      dispatch(fetchConsultationByAppointmentId(appointment_id))
        .then((response) => setConsultationData(response.payload))
        .catch((error) => console.error('Erro ao buscar consulta:', error));
    }
  }, [dispatch, appointment_id]);

  // window.history.replaceState(null, '', '/nutricaoapp'); Disfarça a rota real para esta página

  useEffect(() => {
    if (appointment_id) {
      dispatch(fetchAppointmentById(appointment_id))
        .then((response) => {
          setAppointmentData(response.payload);
        })
        .catch((error) => {
          console.error('Error fetching appointment:', error);
        });
    }
  }, [dispatch, appointment_id]);

  const formatDate = (dateString) => {
    if (!dateString) return 'Data inválida';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'Hora inválida';
    const timeParts = timeString.split(':');
    if (timeParts.length < 2) return 'Hora inválida';
    const [hour, minute] = timeParts;
    return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleUpdate = (appointment_id) => {
    dispatch(fetchAppointmentById(appointment_id));
    navigate(`/home/appointment/update/${appointment_id}`);
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    navigate("/home/appointment/get");
  };

  // const handleStartConsult = (appointment_id) => {
  //   if (!appointmentData?.appointment_id) {
  //     console.error("O appointment_id está indefinido.");
  //     return;
  //   }
  //   dispatch(clearAppointment()); // Limpa o agendamento anterior
  //   dispatch(fetchAppointmentById(appointment_id)); // Carrega o novo agendamento
  //   navigate(`/home/consultation/create/${appointment_id}`); // Navega para a nova consulta
  // };

  const handleStartConsult = async (appointment_id) => {
    if (!appointment_id) {
      console.error("Erro: ID do agendamento está indefinido.");
      return;
    }

    try {
      // console.log("ID do agendamento ao iniciar consulta:", appointment_id);

      // Atualize o status do agendamento (se necessário)
      // await api.patch(`/appointments/${appointment_id}`, { status: 'in_progress' });
      await updateAppointmentById(`/appointments/${appointment_id}`, { status: 'in_progress' });

      // Redirecione para a criação da consulta com o estado necessário
      navigate(`/home/consultation/create/${appointment_id}`, {
        state: { appointment_id }, // Inclua aqui os dados necessários
      });
    } catch (error) {
      console.error('Erro ao iniciar consulta:', error);
    }
  };



  const hasRole = (role) => loginUser.roles?.includes(role);
  const allRoles = ['admin', 'manager', 'coordinator', 'user'];
  const hasAllRoles = () => allRoles.every((role) => hasRole(role));

  const formatStatus = (status) => {
    switch (status) {
      case 'confirmed':
        return 'Confirmado';
      case 'scheduled':
        return 'Agendado';
      case 'changed':
        return 'Alterado';
      case 'in_progress':
        return 'Em Andamento';
      case 'completed':
        return 'Realizada';
      case 'cancelled':
        return 'Cancelado';
      default:
        return '';
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar os dados: {error}</div>;
  }

  if (!appointmentData) {
    return <div>Nenhum compromisso encontrado.</div>;
  }

  return (
    <>
      <h3>Detalhes do Compromisso</h3>
      <form className="boxShowAppointment">
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="company_name">Empresa:</label>
            <span className='spanData'>{appointmentData.company_name}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="professional_name">Profissional:</label>
            <span className='spanData'>{appointmentData.professional_name}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="patient_name">Paciente:</label>
            <span className='spanData'>{appointmentData.patient_name}</span>
          </div>
        </div>

        <h5>Data do Compromisso</h5>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="appointment_date">Data do Compromisso:</label>
            <span className='spanData'>{formatDate(appointmentData.appointment_date)}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="appointment_time">Hora do Compromisso:</label>
            <span className='spanData'>{formatTime(appointmentData.appointment_time)}</span>
          </div>
        </div>
        <div className='box-line'>
          <div className="boxCol1">
            <label htmlFor="status">Status:</label>
            <span className='spanData'>{formatStatus(appointmentData.status)}</span>
          </div>
        </div>

        <h5>Observações</h5>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="notes">Notas:</label>
            <span className='spanData'>{appointmentData.notes}</span>
          </div>
        </div>

        <div className="boxButtons1">
          <button className='showBoxBtn' type="button" onClick={() => handleNavigate(alert('Sending Email'))}>
            <MdEmail className='sideIcoBtn' />
            <span className='sideBtnSpan'>Email</span>
          </button>
        </div>
        <div className="boxButtons2">
          <button className='showBoxBtn' type="button" onClick={() => handleNavigate(alert('Printing'))}>
            <MdPrint className='sideIcoBtn' />
            <span className='sideBtnSpan'>Imprimir</span>
          </button>
        </div>
        <div className="boxButtons3">
          {(!hasRole(allRoles)) && (
            <button className='showBoxBtn' type="button" onClick={() => handleUpdate(appointment_id)}>
              <MdEdit className='sideIcoBtn' />
              <span className='sideBtnSpan'>Editar</span>
            </button>
          )}
        </div>
        <div className="boxButtons4">
          <button className='showBoxBtn' type="button" onClick={handleClickBack}>
            <MdExitToApp className='sideIcoBtn' />
            <span className='sideBtnSpan'>Voltar</span>
          </button>
        </div>
        {/* <div>
          {hasRole('Admin') ? (

            <div className="boxButtons5">
              {appointmentData.status !== 'completed' && appointmentData.status !== 'cancelled' && (
                <button className='showBoxBtn' type="button" onClick={() => handleStartConsult(appointmentData.appointment_id)}>
                  <FaUserMd className='sideIcoBtn' />
                  <span className='sideBtnSpan'>Nova Consulta</span>
                </button>
              )}
            </div>
          ) : null}
        </div> */}

        <div className="boxButtons5">
          {appointmentData.status !== 'completed' && appointmentData.status !== 'cancelled' && (
            <>
              {consultationData ? (
                // Se já existe uma consulta
                <button
                  // className="showBoxBtn"
                  className={
                    appointmentData.status === 'completed' || 'in_progress' ? 'showActionBtn-disable' : 'showBoxbtn'
                  }
                  type="button"
                  onClick={() => navigate(`/home/consultation/show/${consultationData.consultation_id}`)}
                >
                  <FaUserMd className="sideIcoBtn" />
                  <span className="sideBtnSpan">Acessar Consulta</span>
                </button>
              ) : (
                // Se não existe uma consulta
                <button
                  // className="showBoxBtn"
                  className={
                    appointmentData.status === 'completed' || 'in_progress' ? 'showActionBtn-disable' : 'showBoxbtn'
                  }
                  type="button"
                  onClick={() => handleStartConsult(appointmentData.appointment_id)}
                >
                  <FaUserMd className="sideIcoBtn" />
                  <span className="sideBtnSpan">Iniciar Consulta</span>
                </button>
              )}
            </>
          )}
        </div>

      </form>
      {/* {appointmentData.status !== 'completed' && appointmentData.status !== 'cancelled' && (
        <div className='btnNewConsult'>
          {appointmentData.status !== 'completed' && appointmentData.status !== 'cancelled' && (
            <button className='icoBtn' onClick={() => handleStartConsult(appointment_id)} data-tip={startConsult}>{icoDoctor2}</button>
          )}
        </div>
      )} */}


    </>
  );
}

export default ShowAppointment;
