import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAppointmentDetails } from '../../../redux/actions/appointmentsActions';
import { fetchAnthropometryData } from '../../../redux/actions/anthropometryActions';
import { createNewConsultation } from '../../../redux/actions/consultationActions';
import { createNewDiet, fetchDietByPatientId } from '../../../redux/actions/dietActions';
import { clearAnthropometry } from '../../../redux/slices/anthropometrySlice';
import { FaUserMd, FaUser, FaCoffee, FaAppleAlt, FaUtensils, FaPizzaSlice, FaIceCream, FaDrumstickBite, FaBreadSlice, FaClipboardList, FaPills, FaStethoscope, FaWeight, FaRulerVertical, FaCalculator, FaPercentage, FaTransgenderAlt, FaCalendar, FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../styles/CreateConsultation.css';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import { clearCurrentDiet } from '../../../redux/slices/dietSlice';


const CreateConsultation = () => {
  const { appointment_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const addBtn = "Adicionar";
  const remBtn = "Remover";

  const { currentAppointment: appointmentDetails, loading: appointmentLoading } = useSelector((state) => state.appointments);
  const { anthropometryData, loading: anthropometryLoading } = useSelector((state) => state.anthropometry);
  const { currentDiet, loading: currentDietLoading, error: dietError } = useSelector((state) => state.diets);

  const [totalCalories, setTotalCalories] = useState(0);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [dietId, setDietId] = useState([]);



  useEffect(() => {
    if (!location.state) {
      // console.error("Erro: location.state não está definido. Redirecionando para /home/appointment/get.");
      navigate(`/home/appointment/get/$`);
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (!appointment_id) {
      console.error("Erro: appointment_id não fornecido. Redirecionando para a lista de agendamentos.");
      navigate("/home/appointment/get");
      return;
    }
    // Fetch appointment details e configure o estado inicial
    dispatch(fetchAppointmentDetails(appointment_id));
  }, [appointment_id, dispatch, navigate]);


  useEffect(() => {
    const patientId = appointmentDetails?.patient_id || location.state?.patient_id || null;

    if (patientId) {
      setSelectedPatient({
        value: patientId,
        label: appointmentDetails?.patient_name || "Paciente não identificado",
      });
    }
  }, [appointmentDetails, location.state]);

  useEffect(() => {
    const patientIdFromState = location.state?.patient_id || null; // Verifica se existe location.state
    const patientIdFromAppointment = appointmentDetails?.patient_id || null;

    if (patientIdFromState) {
      setSelectedPatient({ value: patientIdFromState, label: appointmentDetails?.patient_name });
    } else if (patientIdFromAppointment) {
      setSelectedPatient({ value: patientIdFromAppointment, label: appointmentDetails?.patient_name });
    }
  }, [location.state, appointmentDetails]);


  useEffect(() => {
    dispatch(fetchAppointmentDetails(appointment_id));
  }, [dispatch, appointment_id]);

  useEffect(() => {
    if (appointmentDetails?.patient_id) {
      dispatch(clearAnthropometry());
      dispatch(fetchAnthropometryData(appointmentDetails.patient_id));
    }
  }, [dispatch, appointmentDetails?.patient_id]);


  // Initialize selectedPatient from location.state or appointmentDetails
  useEffect(() => {
    const patientIdFromState = location.state?.patient_id;
    const patientIdFromAppointment = appointmentDetails?.patient_id;

    if (patientIdFromState) {
      setSelectedPatient({ value: patientIdFromState, label: appointmentDetails?.patient_name });
    } else if (patientIdFromAppointment) {
      setSelectedPatient({ value: patientIdFromAppointment, label: appointmentDetails?.patient_name });
    }
  }, [location.state, appointmentDetails]);


  useEffect(() => {
    if (location.state?.appointmentDetails?.patient_id) {
      const patient = appointmentDetails?.patient_id === location.state.appointmentDetails.patient_id
        ? appointmentDetails
        : null;

      if (patient) {
        setSelectedPatient({ value: patient.patient_id, label: patient.patient_name });
      }
    }
  }, [location.state, appointmentDetails]);


  useEffect(() => {
    if (appointmentDetails?.patient_id) {
      dispatch(clearCurrentDiet());
      dispatch(fetchDietByPatientId(appointmentDetails.patient_id));
    }
  }, [dispatch, appointmentDetails?.patient_id]);

  useEffect(() => {
    if (currentDiet && Array.isArray(currentDiet)) {
      // Calcula o total de calorias das dietas
      const total = currentDiet.reduce((dietAcc, diet) => {
        const dietTotal = diet.meals?.reduce((mealAcc, meal) => {
          // Certifique-se de que total_calories é válido e convertível para número
          return mealAcc + (parseFloat(meal.total_calories) || 0);
        }, 0) || 0;
        return dietAcc + dietTotal;
      }, 0);

      setTotalCalories(total.toFixed(2)); // Limita a 2 casas decimais para exibição
    }
  }, [currentDiet]);

  if (appointmentLoading || anthropometryLoading) {
    return <div>Carregando...</div>;
  }
  if (!appointmentDetails) {
    return <div>Erro ao carregar os dados do agendamento.</div>;
  }


  // Função para formatar a Data DD/MM/YYYY
  const formatDate = (birth_date) => {
    if (!birth_date) return 'Data inválida';
    const date = new Date(birth_date);
    if (Number.isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year} `;
  };
  //
  const pluralize = (count, singular, plural) => {
    return `${count} ${count === 1 ? singular : plural}`;
  };
  // Função para calcular a Idade Ano Meses e Dias
  const calculateAge = (birth_date) => {
    const today = new Date();
    const birthDate = new Date(birth_date);
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (days < 0) {
      months--;
      const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      days += lastMonth.getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return `${pluralize(years, 'Ano', 'Anos')} ${pluralize(months, 'mês', 'meses')} e ${pluralize(days, 'dia', 'dias')}`;
  };
  // Função para formatar o Gênero
  const formatGender = (patientGender) => {
    switch (patientGender) {
      case 'male':
        return 'Masculino';
      case 'female':
        return 'Feminino';
      case 'others':
        return 'Outros';
      default:
        return '';
    }
  }
  //
  const formatToMySQLDatetime = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (isNaN(date)) return null;
    return date.toISOString().slice(0, 19).replace('T', ' ');
  };

  // Função para carregar todas as antropometrias do paciente
  const handleAllAnthropometry = () => {
    if (selectedPatient?.value) {
      navigate('/home/anthropometry/get', { state: { patient_id: selectedPatient.value } });
    } else {
      console.error('Nenhum paciente selecionado');
    }
  };
  // Função para redirecionar para a página de Nova Antropometria
  const handleNewAnthropometry = () => {
    if (appointmentDetails?.patient_id) {
      navigate('/home/anthropometry/create', { state: { patient_id: appointmentDetails.patient_id } });
      // console.log(appointmentDetails.patient_id);
    } else {
      alert('Paciente não encontrado.');
    }
  };
  //
  const handleNewDiet = () => {
    navigate('/home/diet/create', {
      state: {
        appointment_id,
        patient_id: appointmentDetails?.patient_id,
        consultation_id: appointmentDetails?.consultation_id,
        returnPath: `/home/consultation/create/${appointment_id}`,
      }
    });
  }
  //
  const handleAllDiet = () => {
    if (selectedPatient?.value) {
      navigate('/home/diet/get', { state: { patient_id: selectedPatient.value } });
    } else {
      console.error('Nenhum paciente selecionado');
    }
  };
  //
  const handleClickBack = () => {
    navigate("/home/appointment/get");
  };

  // apenas números nos campos
  function onlyNumbers(e, setFieldValue, fieldName) {
    const { value } = e.target;

    // Substituir vírgula por ponto
    if (e.key === ',') {
      e.preventDefault();
      setFieldValue(fieldName, `${value}.`);
      return;
    }

    // Permitir apenas números, ponto, backspace, delete, setas e tab
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', '.', 'Tab'];
    if (!((e.key >= '0' && e.key <= '9') || allowedKeys.includes(e.key))) {
      e.preventDefault();
      return;
    }

    // Evitar múltiplos pontos
    if (e.key === '.' && value.includes('.')) {
      e.preventDefault();
      return;
    }

    // Limitar a duas casas decimais
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      if (decimalPart.length >= 2 && !allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    }
  }

  // não permitir envio do form ao pressionar enter
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  //
  function formatTime(time) {
    if (!time) return '';  // Verifica se 'time' é indefinido ou null e retorna uma string vazia
    return time.slice(0, 5);
  }
  // Função para retornar o ícone baseado no nome da refeição
  const getMealIcon = (mealName) => {
    switch (mealName.toLowerCase()) {
      case "café da manhã":
        return <FaCoffee className="icon" />;
      case "lanche da manhã":
        return <FaAppleAlt className="icon" />;
      case "almoço":
        return <FaUtensils className="icon" />;
      case "lanche da tarde":
        return <FaPizzaSlice className="icon" />;
      case "jantar":
        return <FaDrumstickBite className="icon" />;
      case "ceia":
        return <FaIceCream className="icon" />;
      default:
        return <FaUtensils className="icon" />; // Ícone padrão
    }
  };
  const formatDietName = (diet_name) => {
    switch (diet_name) {
      case 'low_carb':
        return 'Dieta Low Carb';
      case 'keto':
        return 'Dieta Cetogênica';
      case 'caloric_deficit':
        return 'Dieta de Déficit Calórico';
      case 'intermittent_fasting':
        return 'Dieta do Jejum Intermitente';
      case '21_day_diet':
        return 'Dieta de 21 Dias';
      case 'detox':
        return 'Dieta Detox';
      case '7_day_detox':
        return 'Dieta Detox de 7 Dias';
      case 'hypercaloric':
        return 'Dieta Hipercalórica';
      case 'hypertrophic':
        return 'Dieta Hipertófica';
      case 'bulking':
        return 'Dieta Bulking';
      case 'protein_diet':
        return 'Dieta da Proteína';
      case 'lean_gain':
        return 'Dieta para Ganho Lean (Massa Magra)';
      case 'mediterranean':
        return 'Dieta Mediterrânea';
      case 'mediterranean_adapted':
        return 'Dieta Mediterrânea Adaptada';
      case 'antiinflammatory':
        return 'Dieta Antiinflamatória';
      case 'antioxidant':
        return 'Dieta Antioxidante';
      case 'high_fiber':
        return 'Dieta Rica em Fibras';
      case 'cardiovascular':
        return 'Dieta de Saúde Cardiovascular';
      case 'immune_boost':
        return 'Dieta para Imunidade';
      case 'vegan':
        return 'Dieta Vegana';
      case 'vegetarian':
        return 'Dieta Vegetariana';
      case 'flexitarian':
        return 'Dieta Flexitariana';
      case 'pescatarian':
        return 'Dieta Pescetariana';
      case 'paleo':
        return 'Dieta Paleo';
      case 'dash':
        return 'Dieta DASH (Hipertensão)';
      case 'fodmap':
        return 'Dieta FODMAP (Distúrbios Intestinais)';
      case 'diabetes':
        return 'Dieta para Diabetes';
      case 'low_sodium':
        return 'Dieta Hipossódica';
      case 'renal':
        return 'Dieta Renal (Insuficiência Renal)';
      default:
        return '';
    }
  };


  const initialValues = {
    appointment_id: appointmentDetails?.appointment_id || appointment_id || '',
    professional_id: appointmentDetails.professional_id || '',
    company_id: appointmentDetails.company_id || '',
    patient_id: appointmentDetails.patient_id || '',
    consult_date: new Date().toISOString().split('T')[0],
    start_time: new Date().toTimeString().slice(0, 5),
    end_time: new Date().toTimeString().slice(0, 5),
    diet_name: appointmentDetails.diet_name || '',
    procedures: '',
    diagnosis: '',
    current_weight: '',
    observations: '',
    late_measure: anthropometryData?.[0]?.measurement_date
      ? formatToMySQLDatetime(anthropometryData[0].measurement_date)
      : '',
    late_height: anthropometryData?.[0]?.height ? Number.parseFloat(anthropometryData[0].height) : '',
    late_weight: anthropometryData?.[0]?.current_weight ? Number.parseFloat(anthropometryData[0].current_weight) : '',
    late_bmi: anthropometryData?.[0]?.bmi ? Number.parseFloat(anthropometryData[0].bmi) : '',
    late_body_fat_percentage: anthropometryData?.[0]?.body_fat_percentage
      ? Number.parseFloat(anthropometryData[0].body_fat_percentage)
      : '',
    late_bray_classification: anthropometryData?.[0]?.bray_classification || '',
    current_illnesses: [''],
    past_illnesses: [''],
    allergies: [''],
    surgeries: [''],
    medications: [''],
    family_history: [''],

  };

  const validationSchema = Yup.object({
    consult_date: Yup.date().required('Data da consulta é necessário'),
    start_time: Yup.string().required('Hora de início é necessário'),
    procedures: Yup.string().required('Procedimentos são necessários'),
    diagnosis: Yup.string().required('Diagnóstico é necessário'),
    current_weight: Yup.number().min(1, 'Peso inválido').required('Peso é necessário'),
    // height: Yup.number().min(1, 'Altura inválida').required('Altura é necessário'),
    // observations: Yup.string(),
    // current_illnesses: Yup.array()
    //   .of(Yup.string().required('Informe uma doença atual ou remova o campo vazio.'))
    //   .min(1, 'Adicione ao menos uma doença atual ou deixe o campo vazio.'),
    // past_illnesses: Yup.array().of(Yup.string()),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formattedValues = {
        ...values,
        late_height: Number.parseFloat(values.late_height) || 0,
        late_weight: Number.parseFloat(values.late_weight) || 0,
        late_bmi: Number.parseFloat(values.late_bmi) || 0,
        late_body_fat_percentage: Number.parseFloat(values.late_body_fat_percentage) || 0,
        current_illnesses: values.current_illnesses.filter((item) => item.trim() !== ''),
        past_illnesses: values.past_illnesses.filter((item) => item.trim() !== ''),
        allergies: values.allergies.filter((item) => item.trim() !== ''),
        surgeries: values.surgeries.filter((item) => item.trim() !== ''),
        medications: values.medications.filter((item) => item.trim() !== ''),
        family_history: values.family_history.filter((item) => item.trim() !== ''),
      };

      // console.log('Dados enviados:', formattedValues);


      // Captura o payload e extrai a propriedade consultation_id
      const response = await dispatch(createNewConsultation(formattedValues)).unwrap();
      const consultation_id = response.consultation_id; // Extraindo corretamente o ID

      // Limpa o estado antes de redirecionar
      dispatch(clearAnthropometry());
      dispatch(clearCurrentDiet());

      setMessage("Consulta criada com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        resetForm();
        navigate(`/home/consultation/show/${consultation_id}`);
      }, 2000);
    } catch (error) {
      console.error("Erro ao criar consulta:", error);
      const errorMsg = (error.response?.data?.message)
        ? error.response.data.message
        : 'Erro ao enviar formulário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
    } finally {
      setSubmitting(false);
    }
  };

  if (!appointmentDetails) {
    return <div>Carregando os detalhes do agendamento...</div>;
  }

  return (
    <div className="consultationMain">
      <h3>Nova Consulta</h3>
      {/* <div>Agendamento id: {appointmentDetails.appointment_id}</div>
      <div>Paciente: {appointmentDetails.patient_id}</div>
      <div>Antropometria id: {anthropometryData.anthropometry_id}</div>
      <div>Dieta id: {dietId}</div> */}
      <div className="consultationForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >

          {({ isSubmitting, errors, touched, setFieldValue, values }) => (
            <Form onKeyDown={handleKeyDown}>
              <div className="consultationLine">
                <div className="consultation-form-group">
                  <label>Data da Consulta:</label>
                  <Field type="date" id="consultation_date" name="consult_date" />
                  {errors.consult_date && touched.consult_date && <div className="error">{errors.consult_date}</div>}
                </div>
                <div className="consultation-form-group">
                  <label>Hora de Início:</label>
                  <Field type="time" id="consultation_time" name="start_time" />
                  {errors.start_time && touched.start_time && <div className="error">{errors.start_time}</div>}
                </div>
              </div>

              <div className="consultation-form-group">
                <label>Paciente:</label>
                <div className='box-line'>
                  <div className="boxCol1">
                    <span className="spanDataTitle">{<FaUser className='icon' />} {appointmentDetails.patient_name}</span>
                    <span className="spanDataTitle">{<FaCalendar className='icon' />} {appointmentDetails.patient_age ? `${calculateAge(appointmentDetails.patient_age)}` : ''}</span>
                    <span className="spanDataTitle"> {<FaTransgenderAlt className='icon' />} {appointmentDetails.patient_gender ? `${formatGender(appointmentDetails.patient_gender)}` : ''}</span>
                  </div>
                </div>
              </div>
              <div className="consultation-form-group">
                <label>Profissional:</label>
                <div className='box-line'>
                  <div className="boxCol1">
                    <span className="spanDataTitle"><FaUserMd className="icon" /> {appointmentDetails.professional_name}</span>
                  </div>
                </div>
              </div>

              <div className="consultation-form-group">
                <label>Antropometria anterior do Paciente (mais recente disponível) :</label>
                <div className="consultationLine"></div>
                {anthropometryData && anthropometryData.length > 0 ? (
                  <div className="consultation-form-group">
                    <ul className="anthropometry-list">
                      <li className="anthropometry-item">
                        <FaCalendar className="icon" />
                        <span>Data: {formatDate(anthropometryData[0]?.measurement_date)}</span>
                      </li>
                      <li className="anthropometry-item">
                        <FaRulerVertical className="icon" />
                        <span>Altura: {anthropometryData[0]?.height} cm</span>
                      </li>
                      <li className="anthropometry-item">
                        <FaWeight className="icon" />
                        <span>Peso Atual: {anthropometryData[0]?.current_weight} kg</span>
                      </li>
                      <li className="anthropometry-item">
                        <FaCalculator className="icon" />
                        <span>IMC: {anthropometryData[0]?.bmi} kg/m²</span>
                      </li>
                      <li className="anthropometry-item">
                        <FaUserMd className="icon" />
                        <span>Classificação: {anthropometryData[0]?.bray_classification}</span>
                      </li>
                      <li className="anthropometry-item">
                        <FaPercentage className="icon" />
                        <span>P.G: {anthropometryData[0]?.body_fat_percentage} %</span>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <>
                    <div className="consultationLine">
                      <span className='spanDataTitle'>Dados antropométricos não disponíveis</span>

                      <div className="anthropometryButtons">
                        <button className="sendBtn" type="button" onClick={handleNewAnthropometry}>Nova Antropometria</button>
                        {/* <button className="sendBtn" type="button" onClick={handleAllAnthropometry}>Todas Antropometrias</button> */}
                        {/* <button className="sendBtn" onClick={() => navigate('/anthropometry-evolution')}>Evolução Antropométrica</button> */}
                      </div>
                    </div>
                  </>
                )}
              </div>


              {/* <div className="consultation-form-group">
                <label>Dietas anteriores do Paciente (mais recente disponível) :</label>
                <div className="consultationLine"></div>
                {dietData && dietData.length > 0 ? (
                  <div className="consultation-form-group">
                    <ul className="anthropometry-list">

                    </ul>
                  </div>
                ) : (
                  <p>Dietas não disponíveis</p>
                )}
                <div className="anthropometryButtons">
                  <button className="sendBtn" type="button" onClick={handleNewDiet}>Nova Dieta</button>
                  <button className="sendBtn" type="button" onClick={handleAllDiet}>Todas as Dietas</button>
                  <button className="sendBtn" onClick={() => navigate('/anthropometry-evolution')}>Evolução Antropométrica</button>
                </div>
              </div> */}

              {/* dietas */}
              <div className="consultation-form-group">
                <label>Dietas anteriores do Paciente (mais recente disponível) :</label>
              </div>
              {
                Array.isArray(currentDiet) && currentDiet.length > 0 ? (
                  <div className="consultation-form-group">
                    {currentDiet.map((diet) => (
                      <div className="box-diet" key={diet.diet_id}>
                        {setDietId(diet.diet_id)}
                        {/* Coluna A - Informações da dieta */}
                        <div className="box-diet-col-A" key={diet.diet_id}>
                          <div>Nome: <span className="spanDataTitle">{formatDietName(diet.name)}</span></div>
                          <div>Criada em: <span className="spanDataTitle">{formatDate(diet.created_at)}</span></div>
                          <div>Objetivo: <span className="spanDataTitle">{diet.objective}</span></div>
                          <div>Total de Calorias: <span className="spanDataTitle">{totalCalories}</span> kcal</div>
                        </div>

                        {/* Coluna B - Lista de Refeições */}
                        {diet.meals && diet.meals.length > 0 && (
                          <div className="box-diet-col-B">
                            {diet.meals.map((meal) => (
                              <div key={meal.meal_id} className='meal-card'>
                                <div className="meal-line">
                                  <div className="label-card"><div className="label-icon">{getMealIcon(meal.name)}</div></div>
                                  <div className="meal-title"><span className="spanDataTitle">{meal.name} {" "}</span> </div>
                                  <div className="meal-time">Hora: <span className="spanDataTitle">{formatTime(meal.time)} {" "}</span></div>
                                  <div className="meal-kcal">Calorias: <span className="spanDataTitle">{meal.total_calories}</span> kcal</div>
                                </div>

                                {/* Lista de Alimentos */}
                                {meal.foods && meal.foods.length > 0 && (
                                  <div>
                                    {meal.foods.map((food, index) => (
                                      <div key={index.food_name} className="food-line">
                                        <ul className='dietFoodLine'>
                                          <li>
                                            <div className="liCol">
                                              <label htmlFor="">Quant</label>
                                              <div><span className="spanDataTitle">{food.quantity}{" "} </span>{food.unit_measurement} {" "}</div>
                                            </div>
                                            <div className="liCol">
                                              <label htmlFor="">Alimento: </label>
                                              <div><span className="spanDataTitle">{food.food_name} {" "}</span></div>
                                            </div>
                                            <div className="liCol liCalories">
                                              <label htmlFor="">Calorias: </label>
                                              <div><span className="spanDataTitle">{food.calories}</span> kcal</div>
                                            </div>
                                          </li>

                                          {/* <li>
                                            <label htmlFor="">Quant</label>
                                            <div><span className="spanDataTitle">{food.quantity}{" "} {food.unit_measurement} {" "}</span></div>
                                          </li>
                                          <li>
                                            <label htmlFor="">Alimento: </label>
                                            <div><span className="spanDataTitle">{food.food_name} {" "}</span></div>
                                          </li>
                                          <li>
                                            <label htmlFor="">Calorias: </label>
                                            <div><span className="spanDataTitle">{food.calories}</span> kcal</div>
                                          </li> */}
                                        </ul>
                                      </div>
                                    ))}
                                  </div>
                                )
                                }
                              </div>
                            ))}
                          </div>
                        )}

                      </div>
                    ))}
                  </div>

                ) : (
                  <>
                    <div className="consultationLine">

                      <span className='spanDataTitle'>Não há dietas cadastradas para este paciente.</span>

                      <div className="anthropometryButtons">
                        <button className="sendBtn" type="button" onClick={handleNewDiet}>Nova Dieta</button>

                        {/* <button className="sendBtn" type="button" onClick={handleAllDiet}>Todas as Dietas</button> */}
                        {/* <button className="sendBtn" onClick={() => navigate('/anthropometry-evolution')}>Evolução Antropométrica</button> */}
                      </div>
                    </div>
                  </>
                )
              }


              <h3>Nesta Consulta</h3>
              <div className="consultationLine">
                {/* <div className="consultation-form-group">
                  <label htmlFor="current_weight">Peso Atual (kg):</label>
                  <Field
                    type="number"
                    name="current_weight"
                    id="current_weight"
                    value={values.current_weight}
                    onKeyDown={(e) => {
                      // Permitir apenas números, backspace, delete, setas e ponto
                      if (
                        !(
                          (e.key >= '0' && e.key <= '9') ||
                          ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', '.'].includes(e.key)
                        )
                      ) {
                        e.preventDefault();
                      }
                      // Impedir múltiplos pontos
                      if (e.key === '.' && e.target.value.includes('.')) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value === '' ? '' : parseFloat(e.target.value);
                      setFieldValue('current_weight', value); // Atualiza no Formik
                    }}
                  />

                  {errors.current_weight && touched.current_weight && (
                    <div className="error-message">{errors.current_weight}</div>
                  )}
                </div> */}
                <div className="consultation-form-group">
                  <label htmlFor="current_weight">Peso Atual (kg):</label>
                  <Field
                    type="number"
                    name="current_weight"
                    id="current_weight"
                    onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'current_weight')}
                  />
                  {errors.current_weight && touched.current_weight && <div className="error">{errors.current_weight}</div>}

                </div>
              </div>

              <h3>Histórico Clínico</h3>

              {/* Doenças Atuais */}
              <div className="consultation-form-group">
                <label>Doenças Atuais</label>
                <FieldArray name="current_illnesses">
                  {({ push, remove }) => (
                    <div>
                      {values.current_illnesses && values.current_illnesses.length > 0 ? (
                        values.current_illnesses.map((illness, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`current_illnesses[${index}]`}
                              placeholder={`Doença ${index + 1}`}
                            />
                            <button className='btnIcoAddRm' type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className='iconPlus' />
                            </button>
                            <button className='btnIcoAddRm' type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className='iconMinus' />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhuma doença atual adicionada.</p>
                          <button className='btnIcoAddRm' type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className='iconPlus' />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
              </div>

              {/* Doenças Prévias ou Crônicas */}
              <div className="consultation-form-group">
                <label>Doenças Prévias ou Crônicas</label>
                <FieldArray name="past_illnesses" id="past_illnesses">
                  {({ push, remove }) => (
                    <div>
                      {values.past_illnesses && values.past_illnesses.length > 0 ? (
                        values.past_illnesses.map((illness, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`past_illnesses[${index}]`}
                              placeholder={`Doença ${index + 1}`}
                            />
                            <button className='btnIcoAddRm' type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className='iconPlus' />
                            </button>
                            <button className='btnIcoAddRm' type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className='iconMinus' />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhuma doença atual adicionada.</p>
                          <button className='btnIcoAddRm' type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className='iconPlus' />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.past_illnesses && touched.past_illnesses && (
                  <div className="error-message">{errors.past_illnesses}</div>
                )}
              </div>

              {/* Alergias e Intolerâncias */}
              <div className="consultation-form-group">
                <label>Alergias e Intolerâncias</label>
                <FieldArray name="allergies" id="allergies">
                  {({ push, remove }) => (
                    <div>
                      {values.allergies && values.allergies.length > 0 ? (
                        values.allergies.map((allergy, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`allergies[${index}]`}
                              placeholder={`Alergia ${index + 1}`}
                            />
                            <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className="iconPlus" />
                            </button>
                            <button className="btnIcoAddRm" type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className="iconMinus" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhuma alergia adicionada.</p>
                          <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className="iconPlus" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.allergies && touched.allergies && (
                  <div className="error-message">{errors.allergies}</div>
                )}
              </div>

              {/* Cirurgias e Hospitalizações */}
              <div className="consultation-form-group">
                <label>Cirurgias e Hospitalizações</label>
                <FieldArray name="surgeries" id="surgeries">
                  {({ push, remove }) => (
                    <div>
                      {values.surgeries && values.surgeries.length > 0 ? (
                        values.surgeries.map((surgery, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`surgeries[${index}]`}
                              placeholder={`Cirurgia ${index + 1}`}
                            />
                            <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className="iconPlus" />
                            </button>
                            <button className="btnIcoAddRm" type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className="iconMinus" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhuma cirurgia ou hospitalização adicionada.</p>
                          <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className="iconPlus" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.surgeries && touched.surgeries && (
                  <div className="error-message">{errors.surgeries}</div>
                )}
              </div>

              {/* Uso de Medicamentos */}
              <div className="consultation-form-group">
                <label>Uso de Medicamentos</label>
                <FieldArray name="medications" id="medications">
                  {({ push, remove }) => (
                    <div>
                      {values.medications && values.medications.length > 0 ? (
                        values.medications.map((medication, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`medications[${index}]`}
                              placeholder={`Medicamento ${index + 1}`}
                            />
                            <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className="iconPlus" />
                            </button>
                            <button className="btnIcoAddRm" type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className="iconMinus" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhum medicamento adicionado.</p>
                          <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className="iconPlus" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.medications && touched.medications && (
                  <div className="error-message">{errors.medications}</div>
                )}
              </div>

              {/* Histórico Familiar */}
              <div className="consultation-form-group">
                <label>Histórico Familiar</label>
                <FieldArray name="family_history" id="family_history">
                  {({ push, remove }) => (
                    <div>
                      {values.family_history && values.family_history.length > 0 ? (
                        values.family_history.map((history, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`family_history[${index}]`}
                              placeholder={`Histórico ${index + 1}`}
                            />
                            <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className="iconPlus" />
                            </button>
                            <button className="btnIcoAddRm" type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className="iconMinus" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhum histórico familiar adicionado.</p>
                          <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className="iconPlus" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.family_history && touched.family_history && (
                  <div className="error-message">{errors.family_history}</div>
                )}
              </div>


              <div className="consultation-form-group">
                <label>Procedimentos:</label>
                <Field as="textarea" name="procedures" />
                {errors.procedures && touched.procedures && <div className="error">{errors.procedures}</div>}
              </div>

              <div className="consultation-form-group">
                <label>Diagnóstico:</label>
                <Field as="textarea" name="diagnosis" />
                {errors.diagnosis && touched.diagnosis && <div className="error">{errors.diagnosis}</div>}
              </div>

              <div className="consultation-form-group">
                <label>Observações:</label>
                <Field as="textarea" name="observations" />
              </div>

              <div className="consultationButtons">
                <button className="sendBtn" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Salvando...' : 'Salvar Consulta'}
                </button>
                <button className="backBtn" type="button" onClick={() => navigate('/home/appointment/get')}>
                  Cancelar
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {showModal && (
          <ModalForms
            message={message}
            onConfirm={() => setShowModal(false)}
          />
        )}
      </div>
    </div >
  );
};

export default CreateConsultation;
