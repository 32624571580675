// src/redux/actions/appointmentActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAppointment, getAppointmentById, deleteAppointment, updateAppointment, createAppointment, getAppointmentByPersonId, getPerson } from '../../service/api';

// Fetch appointments
export const fetchAppointments = createAsyncThunk(
  'appointments/fetchAppointments',
  async () => {
    const response = await getAppointment();
    return response.data.data;
  }
);

// Delete appointment
export const deleteAppointmentById = createAsyncThunk(
  'appointments/deleteAppointmentById',
  async (appointment_id) => {
    await deleteAppointment(appointment_id);
    return appointment_id;
  }
);

// Update appointment
export const updateAppointmentById = createAsyncThunk(
  'appointments/updateAppointmentById',
  async ({ appointment_id, data }) => {
    try {
      const response = await updateAppointment(appointment_id, data);
      // console.log("Resposta da API para updateAppointmentById:", response); // Verifique o que está sendo retornado
      return response.data; // Verifique se o response.data é o objeto esperado
    } catch (error) {
      console.error("Erro na API:", error);
      throw error;
    }
  }
);


// Create appointment
export const createNewAppointment = createAsyncThunk(
  'appointments/createNewAppointment',
  async (formData) => {
    const response = await createAppointment(formData);
    return response.data;
  }
);

// GetById appointment
export const fetchAppointmentById = createAsyncThunk(
  'appointments/fetchAppointmentById',
  async (appointment_id) => {
    const response = await getAppointmentById(appointment_id);
    // console.log("O FetchAppointmentById é:", JSON.stringify(response, null, 2));
    return response.data;
  }
);

// GetById appointment
export const fetchAppointmentByPersonId = createAsyncThunk(
  'appointments/fetchAppointmentByPersonId',
  async ({ person_id, person_type }) => {
    const response = await getAppointmentByPersonId(person_id, person_type);
    return response.data;
  }
);


// Get Appointment Details Action
export const fetchAppointmentDetails = createAsyncThunk(
  'appointments/fetchAppointmentDetails',
  async (appointment_id) => {
    // console.log("Enviando appointment_id para API:", appointment_id);
    const response = await getAppointmentById(appointment_id);
    return response.data;
  }
);

