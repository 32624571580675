// src/redux/actions/appointmentActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAnthropometry, getAnthropometryByPatientId } from '../../service/api';

// Create appointment
export const createNewAnthropometry = createAsyncThunk(
  'anthropometry/createNewAnthropometry',
  async (formData) => {
    const response = await createAnthropometry(formData);
    return response.data;
  }
);


//Get Anthropometry By Patient ID
export const fetchAnthropometryByPatientId = createAsyncThunk(
  'anthropometry/fetchAnthropometryByPatientId',
  async (patient_id, { rejectWithValue }) => {
    try {
      const data = await getAnthropometryByPatientId(patient_id);
      if (data && Array.isArray(data)) {
        // console.log("Dados processados da API:", data); // Verifique o que está sendo retornado
        return data; // Retorna diretamente o array de antropometrias
      }
      return []; // Retorna um array vazio se não houver dados
    } catch (error) {
      console.error("Erro ao buscar antropometria:", error);
      return rejectWithValue(error.message);
    }
  }
);


// Get Anthropometry Data (Reestruturado para usar createAsyncThunk)
export const fetchAnthropometryData = createAsyncThunk(
  'anthropometry/fetchAnthropometryData',
  async (patient_id, { rejectWithValue }) => {
    try {
      const data = await getAnthropometryByPatientId(patient_id);
      if (data && Array.isArray(data)) {
        // console.log("Dados processados da API:", data); // Verifique o que está sendo retornado
        return data; // Retorna diretamente o array de antropometrias
      }
      return []; // Retorna um array vazio se não houver dados
    } catch (error) {
      // console.error("Error fetching anthropometryData:", error);
      if (error.response?.status === 404) {
        console.info(`Nenhuma antropometria encontrada para o paciente ID ${patient_id}.`);
        return null; // Retorna null para indicar ausência de dados
      }
      console.error("Erro ao buscar antropometria:", error);
      return rejectWithValue(error.response?.data || error.message || "Erro desconhecido");; // Retorna o erro para ser tratado no extraReducers
    }
  }
);