import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountNewUser } from '../../redux/actions/userActions';
import { AuthContext } from '../../context/auth';

const CountNewUser = () => {
  const { loginUser } = useContext(AuthContext);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const newUserCount = useSelector((state) => state.users.newUserCount);
  const totalUserCount = useSelector((state) => state.users.totalUserCount);



  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCountNewUser());
      setLoading(false);
    };

    fetchData();

    const interval = setInterval(fetchData, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval);
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const hasRole = (roles) => {
    if (!Array.isArray(roles)) {
      roles = [roles];
    }
    return loginUser.roles && roles.some(role => loginUser.roles.includes(role));
  };


  return (
    <>
      {hasRole(['Admin', 'Manager']) && <div className="groupBtn-G">

        <div>Usuários / 24 Horas: {newUserCount}</div>
        <div>Total Usuários: {totalUserCount}</div>
      </div>}


    </>
  );
};

export default CountNewUser;
