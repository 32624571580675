function calculateAge(birthDateString) {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();
  let days = today.getDate() - birthDate.getDate();

  if (days < 0) {
    months--;
    const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    days += lastMonth.getDate();
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  return `${pluralize(years, 'Ano', 'Anos')} ${pluralize(months, 'mês', 'meses')} e ${pluralize(days, 'dia', 'dias')}`;
};

function calculateAgeOnlyYear(birthDateString) {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();
  let days = today.getDate() - birthDate.getDate();

  if (days < 0) {
    months--;
    const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    days += lastMonth.getDate();
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  return `${pluralize(years, 'Ano', 'Anos')}`;
};

function pluralize(count, singular, plural) {
  return `${count} ${count === 1 ? singular : plural}`;
};

function formatDate(dateString) {
  if (!dateString) return 'Data inválida';
  const date = new Date(dateString);
  if (isNaN(date)) return 'Data inválida';
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
function formatAnyDate(dateString) {
  if (!dateString) return 'Data inválida';

  try {
    // Verifica se a string já está no formato ISO ou se pode ser convertida para uma data válida
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return 'Data inválida';
    }

    const day = date.getUTCDate().toString().padStart(2, '0'); // Utiliza getUTCDate para garantir a compatibilidade com Z
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  } catch (error) {
    return 'Data inválida';
  }
}


function formatTime(timeString) {
  if (!timeString) return 'Hora inválida';
  const timeParts = timeString.split(':');
  if (timeParts.length < 2) return 'Hora inválida';
  const [hour, minute] = timeParts;
  return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
};

function formatGender(gender) {
  switch (gender) {
    case 'female':
      return 'Feminino';
    case 'male':
      return 'Masculino';
    case 'others':
      return 'Outros';
    default:
      return 'Não especificado';
  }
};

function formatPersonType(person_type) {
  switch (person_type) {
    case 'user':
      return 'Usuário';
    case 'nutritionist':
      return 'Nutricionista';
    case 'doctor':
      return 'Médico';
    case 'employee':
      return 'Colaborador';
    case 'supplier':
      return 'Fornecedor';
    case 'professional':
      return 'Profissional';
    case 'partner':
      return 'Parceiro';
    case 'owner':
      return 'Proprietário';
    case 'patient':
      return 'Paciente';
    case 'other':
      return 'Registro';
    default:
      return '';
  }
};

function formatSpeciality(speciality) {
  switch (speciality) {
    case 'nutritionist':
      return 'Nutrição';
    case 'general_doctor':
      return 'Clínico Geral';
    case 'physiotherapist':
      return 'Fisioterapia';
    default:
      return '';
  }
};

function formatMobileNumber(mobile) {
  if (!mobile) return '';
  return mobile.replace(/\D/g, '');
};

function formatStatus(status) {
  switch (status) {
    case 'scheduled':
      return "Agendada";
    case 'confirmed':
      return "Confirmada";
    case 'changed':
      return "Alterada";
    case 'in_progress':
      return "Em Progresso";
    case 'completed':
      return "Finalizda";
    case 'cancelled':
      return "Cancelada";
    default:
      return '';
  }
};
const formatDietName = (diet_name) => {
  switch (diet_name) {
    case 'low_carb':
      return 'Dieta Low Carb';
    case 'keto':
      return 'Dieta Cetogênica';
    case 'caloric_deficit':
      return 'Dieta de Déficit Calórico';
    case 'intermittent_fasting':
      return 'Dieta do Jejum Intermitente';
    case '21_day_diet':
      return 'Dieta de 21 Dias';
    case 'detox':
      return 'Dieta Detox';
    case '7_day_detox':
      return 'Dieta Detox de 7 Dias';
    case 'hypercaloric':
      return 'Dieta Hipercalórica';
    case 'hypertrophic':
      return 'Dieta Hipertófica';
    case 'bulking':
      return 'Dieta Bulking';
    case 'protein_diet':
      return 'Dieta da Proteína';
    case 'lean_gain':
      return 'Dieta para Ganho Lean (Massa Magra)';
    case 'mediterranean':
      return 'Dieta Mediterrânea';
    case 'mediterranean_adapted':
      return 'Dieta Mediterrânea Adaptada';
    case 'antiinflammatory':
      return 'Dieta Antiinflamatória';
    case 'antioxidant':
      return 'Dieta Antioxidante';
    case 'high_fiber':
      return 'Dieta Rica em Fibras';
    case 'cardiovascular':
      return 'Dieta de Saúde Cardiovascular';
    case 'immune_boost':
      return 'Dieta para Imunidade';
    case 'vegan':
      return 'Dieta Vegana';
    case 'vegetarian':
      return 'Dieta Vegetariana';
    case 'flexitarian':
      return 'Dieta Flexitariana';
    case 'pescatarian':
      return 'Dieta Pescetariana';
    case 'paleo':
      return 'Dieta Paleo';
    case 'dash':
      return 'Dieta DASH (Hipertensão)';
    case 'fodmap':
      return 'Dieta FODMAP (Distúrbios Intestinais)';
    case 'diabetes':
      return 'Dieta para Diabetes';
    case 'low_sodium':
      return 'Dieta Hipossódica';
    case 'renal':
      return 'Dieta Renal (Insuficiência Renal)';
    default:
      return '';
  }
};
module.exports = {
  calculateAge,
  calculateAgeOnlyYear,
  pluralize,
  formatDate,
  formatAnyDate,
  formatTime,
  formatGender,
  formatPersonType,
  formatSpeciality,
  formatMobileNumber,
  formatStatus,
  formatDietName,
};




