import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br'; // Adicionando o locale para pt-BR

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AuthContext } from '../../context/auth';
import { fetchAppointments, fetchAppointmentById } from '../../redux/actions/appointmentsActions';
import CalendarInfo from './components/CalendarInfo';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles/CalendarDashboard.css';
import '../../Mobile.css';


const localizer = momentLocalizer(moment);

const CalendarDashboard = () => {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAppointments());
  }, [dispatch]);

  const { appointments = [], loading, error } = useSelector(state => state.appointments);

  const [formattedAppointments, setFormattedAppointments] = useState([]);


  useEffect(() => {
    if (Array.isArray(appointments)) {
      const formatted = appointments.map(appointment => {
        // Extrair data e hora
        const appointmentDate = moment(appointment.appointment_date).format('YYYY-MM-DD');
        const appointmentStartTime = moment(appointment.appointment_time, 'HH:mm:ss').format('HH:mm:ss');

        // Criar objetos Date para start e end
        const start = new Date(`${appointmentDate}T${appointmentStartTime}`);
        const end = new Date(moment(start).add(1, 'hours')); // Adiciona 1 hora para o fim, ou ajusta conforme a duração real

        return {
          ...appointment,
          start: start,
          end: end,
          title: appointment.patient_name || 'No Title',
        };
      });
      setFormattedAppointments(formatted);
      // console.log("Agendamentos formatados:", formatted);
    }
  }, [appointments]);

  const appointmentsThisMonth = () => {
    const startOfMonth = moment().startOf('month').toDate();
    const endOfMonth = moment().endOf('month').toDate();
    return formattedAppointments.filter(appointment =>
      appointment.start >= startOfMonth && appointment.start <= endOfMonth
    );
  };


  const handleSelectEvent = (event) => {
    const { appointment_id } = event; // Capturando o ID do agendamento do evento selecionado
    dispatch(fetchAppointmentById(appointment_id));
    navigate(`/home/appointment/show/${appointment_id}`);
  }

  const appointmentToday = () => {
    return formattedAppointments.filter(appointment => {
      const startOfDay = moment().startOf('day').toDate();
      const endOfDay = moment().endOf('day').toDate();
      return appointment.start >= startOfDay && appointment.start <= endOfDay;
    });
  };

  // console.log(`Total de agendamentos mês: ${formattedAppointments.length}`);
  // console.log(`Total de agendamentos de hoje: ${appointmentToday().length}`);

  const hasRole = (role) => loginUser.roles && loginUser.roles.includes(role);

  return (
    <>
      <div className='calendarInfo groupBtn-G'>
        <div>Agendamentos de hoje: {appointmentToday().length}</div>
        <div>Agendamentos do mês: {appointmentsThisMonth().length}</div>
        {hasRole('Admin') ? (
          <div>Total de Agendamentos: {formattedAppointments.length}</div>
        ) : null}
      </div>

      <div className='calendarDashboard'>
        <Calendar
          messages={{
            next: "Próximo",
            previous: "Anterior",
            today: "Hoje",
            month: "Mês",
            week: "Semana",
            day: "Dia",
            agenda: "Agenda",
            date: "Data",
            time: "Hora",
            event: "Evento",
            noEventsInRange: "Não há eventos neste intervalo.",
            showMore: (total) => `+ Ver mais (${total})`
          }}
          localizer={localizer}
          events={formattedAppointments}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title" // Usando a propriedade `title` para exibir o nome do evento
          onSelectEvent={handleSelectEvent}
          formats={{
            dateFormat: 'DD',
            dayFormat: (date, culture, localizer) => localizer.format(date, 'DD/MM/YYYY', culture),
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'ddd', culture),
            timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'HH:mm', culture),
            eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
              `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
            agendaHeaderFormat: ({ start, end }, culture, localizer) =>
              `${localizer.format(start, 'DD/MM/YYYY', culture)} - ${localizer.format(end, 'DD/MM/YYYY', culture)}`,
            agendaDateFormat: (date, culture, localizer) => localizer.format(date, 'DD/MM/YYYY', culture),
            agendaTimeFormat: (date, culture, localizer) => localizer.format(date, 'HH:mm', culture),
            agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
              `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`
          }}
        />
      </div>
    </>
  );

};

export default CalendarDashboard;
