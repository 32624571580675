import React, { useState, useEffect, useContext, useRef } from 'react';
import { singnUp } from '../../service/api';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './styles/Sign.css';

import bckgVideo from '../../assets/video/water2.mp4';
import fallbackVideo from '../../assets/video/lemons.mp4';
import fallbackImg from '../../assets/img/fallback.png';
import fallbackMobileImg from '../../assets/img/ban1.png';

function Sign() {
  const [sign, setSign] = useState({
    email: '',
    first_name: '',
    last_name: '',
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');

  const videoRef = useRef();
  const [videoError, setVideoError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  // Função para validar o email
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email) && email.indexOf('@') > 2;
  };

  const handleVideoError = () => {
    setVideoError(true);
  };


  const validateFirstName = (first_name) => {
    return first_name.length >= 3;
  };

  const validateLastName = (last_name) => {
    return last_name.length >= 3;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Verifica se os campos email e senha estão preenchidos corretamente
    if (!validateEmail(sign.email)) {
      setMessage('O campo email deve ser preenchido corretamente');
      return;
    } else if (!validateFirstName(sign.first_name)) {
      setMessage('O campo nome deve ter ao menos 3 caracteres');
      return;
    } else if (!validateLastName(sign.last_name)) {
      setMessage('O campo sobrenome deve ter ao menos 3 caracteres');
      return;
    }
    setLoading(true); // Define estado de carregamento como verdadeiro
    try {
      const response = await singnUp(sign.email, sign.first_name, sign.last_name);
      setMessage(response.data.message || 'Formulário enviado com sucesso!');
      setSign({ email: '', first_name: '', last_name: '' });
      setTimeout(() => {
        // Redireciona para a página principal após 2 segundos
        window.location.href = '/';
      }, 2000); // Redireciona após 2 segundos
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data);

      } else {
        setMessage('Erro ao enviar o formulário');
      }
      console.error(error);
    } finally {
      setLoading(false);
      setSign({ email: '', first_name: '', last_name: '' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSign(prevState => ({
      ...prevState,
      [name]: value,
    }));

    setMessage("");
  };

  const resetMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  useEffect(() => {
    if (message) {
      resetMessage();
    }
  }, [message]);

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500); // Atualiza a cada 500ms
    } else {
      setEllipsis('.....'); // Reseta as reticências quando não está carregando
    }

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar ou quando loading mudar
  }, [loading]);

  return (
    <>
      <div className="signVideoBox">
        {isMobile ? (
          <img src={fallbackMobileImg} className="loginFallbackImg" alt="Fallback Mobile Image" />
        ) : !videoError ? (
          <video ref={videoRef} muted autoPlay loop onError={handleVideoError}>
            <source src={bckgVideo} type="video/mp4" onError={() => videoRef.current.src = fallbackVideo} />
            <source src={fallbackVideo} type="video/mp4" />
          </video>
        ) : (
          <img src={fallbackImg} className="loginFallbackImg" alt="Fallback Image" />
        )}
      </div>

      <div className='sign-container'>
        <div className="title">
          <span>Bem-vindo comece aqui seu registro</span>
        </div>
        <div className="signBox">
          <span> Comece aqui o seu Registro </span>
          {loading && <div className="messages"> Aguarde{ellipsis}</div>}
          {message && <div className="messages"> {message} </div>}
          <Form onSubmit={handleSubmit} className="">
            <Form.Group>
              <Form.Label className="form-label" htmlFor="email"> Usuário </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                className="form-control"
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                value={sign.email}
                onChange={handleChange}
                required
              />

              <Form.Label className="form-label" htmlFor="first_name"> Nome </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                className="form-control"
                id="first_name"
                name="first_name"
                placeholder="Nome"
                autoComplete=""
                value={sign.first_name}
                onChange={handleChange}
                required
              />

              <Form.Label className="form-label" htmlFor="last_name"> Sobrenome </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                placeholder="Sobrenome"
                autoComplete=""
                value={sign.last_name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group>
              <div className="exitLink">
                <a href="/">sair</a>
              </div>
            </Form.Group>

            <Form.Group className="loginBtn">
              <Button className="form-btn btn" variant="primary" type="submit"> Enviar </Button>
            </Form.Group>
          </Form>
        </div>
      </div >

      <div className="footerLogin">
        <div className='year'>2007 - <span>{currentYear}</span></div> {/* Usando o ano atual */}
        <div>&copy; todos os direitos reservados</div>
        <div>powered by <span className='brand'>apit solutions</span></div>
      </div>
    </>
  );
}

export default Sign;
