import { createSlice } from '@reduxjs/toolkit';
import {
  // fetchDiets,
  // fetchDietById,
  fetchDietByPatientId,
  deleteDietById,
  // updateDietById,
  createNewDiet,
} from '../actions/dietActions';

const dietSlice = createSlice({
  name: 'diets',
  initialState: {
    diets: [],
    currentDiet: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentDiet: (state, action) => {
      state.currentDiet = action.payload;
    },
    clearCurrentDiet: (state) => {
      state.currentDiet = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Buscar lista de dietas
      // .addCase(fetchDiets.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchDiets.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.diets = action.payload;
      // })
      // .addCase(fetchDiets.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })

      // // Buscar uma dieta por ID
      // .addCase(fetchDietById.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchDietById.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.currentDiet = action.payload;
      // })
      // .addCase(fetchDietById.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })

      .addCase(fetchDietByPatientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDietByPatientId.fulfilled, (state, action) => {
        state.loading = false;
        state.currentDiet = action.payload.data;
      })
      .addCase(fetchDietByPatientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Criar nova dieta
      .addCase(createNewDiet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewDiet.fulfilled, (state, action) => {
        state.loading = false;
        state.diets.push(action.payload);
      })
      .addCase(createNewDiet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Atualizar dieta
      // .addCase(updateDietById.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(updateDietById.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.diets = state.diets.map(diet =>
      //     diet.id === action.payload.id ? action.payload : diet
      //   );
      // })
      // .addCase(updateDietById.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })

      // // Deletar dieta
      .addCase(deleteDietById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDietById.fulfilled, (state, action) => {
        state.loading = false;
        state.diets = state.diets.filter(diet => diet.diet_id !== action.payload);
      })
      .addCase(deleteDietById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      ;
  },
});

export const { setCurrentDiet, clearCurrentDiet } = dietSlice.actions;
export default dietSlice.reducer;
