import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchConsultationById, putCompleteConsultation } from '../../../redux/actions/consultationActions';
import { fetchAnthropometryData } from '../../../redux/actions/anthropometryActions';
import { fetchPatients, fetchPersonById } from '../../../redux/actions/relatedEntitiesActions';
import { fetchDietById, fetchDietByPatientId } from '../../../redux/actions/dietActions';
import { clearAnthropometry } from '../../../redux/slices/anthropometrySlice';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';

import { MdEmail, MdPrint, MdExitToApp, MdEdit, MdDangerous, MdDoorBack, MdFireExtinguisher, MdMedicalServices } from 'react-icons/md';
import { FaUserMd, FaUser, FaCoffee, FaAppleAlt, FaUtensils, FaPizzaSlice, FaIceCream, FaDrumstickBite, FaBreadSlice, FaClipboardList, FaPills, FaStethoscope, FaWeight, FaRulerVertical, FaCalculator, FaPercentage, FaTransgenderAlt, FaCalendar, FaPlusCircle, FaMinusCircle } from "react-icons/fa";

import '../styles/ShowConsultation.css';
import '../../../Mobile.css';
import { calculateAge } from '../../../utils/Functions/formatPersons';
import showConsultationPdf from '../../../utils/PDF/showConsultationPdf';

const ShowConsultation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { consultation_id } = useParams();
  const { loginUser } = useContext(AuthContext);

  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { currentConsultation, loading: consultationLoading, error: consultationError } = useSelector(state => state.consultations);
  const { anthropometryData, loading: anthropometryLoading, error: anthropometryError } = useSelector((state) => state.anthropometry);
  const { person, loading: personLoading, error: personError } = useSelector((state) => state.relatedEntities);
  const { currentDiet, loading: dietLoading, error: dietError } = useSelector((state) => state.diets);

  const addressDetails = "Clique para ver no mapa";

  const [totalCalories, setTotalCalories] = useState(0);


  // Fetch consultation details
  useEffect(() => {
    if (consultation_id) {
      dispatch(fetchConsultationById(consultation_id));
    }
  }, [dispatch, consultation_id]);

  //Fetch para Antropometria
  useEffect(() => {
    if (currentConsultation?.patient_id) {
      dispatch(clearAnthropometry());
      dispatch(fetchAnthropometryData(currentConsultation.patient_id));
    }
  }, [dispatch, currentConsultation?.patient_id]);

  // Fetch person details based on patient_id from the consultation
  useEffect(() => {
    if (currentConsultation?.patient_id) {
      dispatch(fetchPersonById(currentConsultation.patient_id));
    }
  }, [dispatch, currentConsultation?.patient_id]);

  useEffect(() => {
    if (currentDiet && Array.isArray(currentDiet)) {
      // Calcula o total de calorias das dietas
      const total = currentDiet.reduce((dietAcc, diet) => {
        const dietTotal = diet.meals?.reduce((mealAcc, meal) => {
          // Certifique-se de que total_calories é válido e convertível para número
          return mealAcc + (parseFloat(meal.total_calories) || 0);
        }, 0) || 0;
        return dietAcc + dietTotal;
      }, 0);

      setTotalCalories(total.toFixed(2)); // Limita a 2 casas decimais para exibição
    }
  }, [currentDiet]);

  // Fetch diets for the patient
  useEffect(() => {
    if (currentConsultation?.patient_id) {
      dispatch(fetchDietByPatientId(currentConsultation.patient_id)); // Busca as dietas pelo patient_id
    }
  }, [dispatch, currentConsultation?.patient_id]);


  if (consultationLoading || personLoading || anthropometryLoading) {
    return <div>Carregando...</div>;
  }

  if (consultationError || personError) {
    return <div>Erro: {consultationError || personError}</div>;
  }

  if (!currentConsultation) {
    return <div>Nenhuma consulta encontrada.</div>;
  }

  if (!person) {
    // console.error("Paciente não encontrado:", currentConsultation?.patient_id);
    return <div>Paciente não encontrado.</div>;
  }


  const formatGender = (gender) => {
    switch (gender) {
      case 'female':
        return 'Feminino';
      case 'male':
        return 'Masculino';
      case 'others':
        return 'Outros';
      default:
        return 'Não especificado';
    }
  };

  const handleEdit = () => {
    // Verifica se há uma dieta associada
    const dietId = Array.isArray(currentDiet) && currentDiet.length > 0 ? currentDiet[0].diet_id : null;

    navigate(`/home/consultation/update/${consultation_id}`, {
      state: {
        consultation_id: currentConsultation?.consultation_id,
        patient_id: currentConsultation?.patient_id,
        appointment_id: currentConsultation?.appointment_id,
        diet_id: dietId,
      },
    });
  };


  const handleCancelFinishConsultation = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmFinishConsultation = async () => {
    try {
      await dispatch(putCompleteConsultation(currentConsultation.consultation_id)).unwrap();
      setMessage('Consulta finalizada com sucesso!');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        navigate('/home/consultation/get');
      }, 2000);

    } catch (error) {
      console.error('Erro ao finalizar consulta:', error);
      setMessage("Erro ao finalizar consulta: " + error.message);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        navigate('/home/consultation/get');
      }, 2000);
    }
  };

  const handleFinishOrReopen = async () => {
    try {
      const action =
        currentConsultation.status === 'completed'
          ? { consultation_id, status: 'in_progress' } // Reabrir consulta
          : { consultation_id, status: 'completed' }; // Finalizar consulta

      await dispatch(putCompleteConsultation(action)).unwrap();
      const newStatusMessage = action.status === 'completed' ? 'finalizada' : 'reaberta';
      setShowConfirmationModal(false);
      setMessage(`Consulta ${newStatusMessage} com sucesso!`);
      setShowModal(true);

      setTimeout(() => {
        setShowModal(false);
        navigate('/home/consultation/get');
      }, 2000);
    } catch (error) {
      console.error('Erro ao alterar status da consulta:', error);
      setMessage(`Erro ao alterar status da consulta: ${error.message}`);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        navigate('/home/consultation/get');
      }, 2000);
    }
  };

  const handleConfirmAction = () => {
    const actionMessage =
      currentConsultation.status === 'completed'
        ? 'Deseja reabrir esta consulta?'
        : 'Deseja finalizar esta consulta?';

    setMessage(actionMessage);
    setShowConfirmationModal(true);
  };

  const handleCancelAction = () => {
    setShowConfirmationModal(false);
  };



  // Função para redirecionar para a página de Nova Antropometria
  const handleNewAnthropometry = () => {
    navigate('/home/anthropometry/create');
  };

  const handlePrint = (currentConsultation, person, dietData, anthropometryData) => {
    // console.log('log da consulta: ' + JSON.stringify(currentConsultation));
    // console.log('log da pessoa: ' + JSON.stringify(person));
    // console.log('log da dieta: ' + JSON.stringify(dietData));
    // console.log('log da antropometria: ' + JSON.stringify(anthropometryData));
    showConsultationPdf(currentConsultation, person, dietData, anthropometryData);
  };

  const handleBack = () => {
    navigate(`/home/consultation/get`);
  };

  const hasRole = (role) => loginUser.roles?.includes(role);

  const formatDate = (dateString) => {
    if (!dateString) return 'Data inválida';
    const date = new Date(dateString);
    if (Number.isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  function formatTime(time) {
    if (!time) return '';  // Verifica se 'time' é indefinido ou null e retorna uma string vazia
    return time.slice(0, 5);
  }

  // Função para retornar o ícone baseado no nome da refeição
  const getMealIcon = (mealName) => {
    switch (mealName.toLowerCase()) {
      case "café da manhã":
        return <FaCoffee className="icon" />;
      case "lanche da manhã":
        return <FaAppleAlt className="icon" />;
      case "almoço":
        return <FaUtensils className="icon" />;
      case "lanche da tarde":
        return <FaPizzaSlice className="icon" />;
      case "jantar":
        return <FaDrumstickBite className="icon" />;
      case "ceia":
        return <FaIceCream className="icon" />;
      default:
        return <FaUtensils className="icon" />; // Ícone padrão
    }
  };

  function formatStatus(status) {
    switch (status) {
      case 'scheduled':
        return "Agendada";
      case 'confirmed':
        return "Confirmada";
      case 'changed':
        return "Alterada";
      case 'in_progress':
        return "Em Progresso";
      case 'completed':
        return "Finalizda";
      case 'cancelled':
        return "Cancelada";
      default:
        return '';
    }
  };
  const formatDietName = (diet_name) => {
    switch (diet_name) {
      case 'low_carb':
        return 'Dieta Low Carb';
      case 'keto':
        return 'Dieta Cetogênica';
      case 'caloric_deficit':
        return 'Dieta de Déficit Calórico';
      case 'intermittent_fasting':
        return 'Dieta do Jejum Intermitente';
      case '21_day_diet':
        return 'Dieta de 21 Dias';
      case 'detox':
        return 'Dieta Detox';
      case '7_day_detox':
        return 'Dieta Detox de 7 Dias';
      case 'hypercaloric':
        return 'Dieta Hipercalórica';
      case 'hypertrophic':
        return 'Dieta Hipertófica';
      case 'bulking':
        return 'Dieta Bulking';
      case 'protein_diet':
        return 'Dieta da Proteína';
      case 'lean_gain':
        return 'Dieta para Ganho Lean (Massa Magra)';
      case 'mediterranean':
        return 'Dieta Mediterrânea';
      case 'mediterranean_adapted':
        return 'Dieta Mediterrânea Adaptada';
      case 'antiinflammatory':
        return 'Dieta Antiinflamatória';
      case 'antioxidant':
        return 'Dieta Antioxidante';
      case 'high_fiber':
        return 'Dieta Rica em Fibras';
      case 'cardiovascular':
        return 'Dieta de Saúde Cardiovascular';
      case 'immune_boost':
        return 'Dieta para Imunidade';
      case 'vegan':
        return 'Dieta Vegana';
      case 'vegetarian':
        return 'Dieta Vegetariana';
      case 'flexitarian':
        return 'Dieta Flexitariana';
      case 'pescatarian':
        return 'Dieta Pescetariana';
      case 'paleo':
        return 'Dieta Paleo';
      case 'dash':
        return 'Dieta DASH (Hipertensão)';
      case 'fodmap':
        return 'Dieta FODMAP (Distúrbios Intestinais)';
      case 'diabetes':
        return 'Dieta para Diabetes';
      case 'low_sodium':
        return 'Dieta Hipossódica';
      case 'renal':
        return 'Dieta Renal (Insuficiência Renal)';
      default:
        return '';
    }
  };



  return (
    <>
      <h3>Detalhes da Consulta</h3>

      {/* <div>
        <div>Agendamento Id: <span className="spanDataTitle">{currentConsultation.appointment_id}</span></div>
        <div>Paciente Id: <span className="spanDataTitle">{currentConsultation.patient_id}</span></div>
        <div>Consulta Id: <span className="spanDataTitle">{currentConsultation.consultation_id}</span></div>
        <div>Diet Id: <span className="spanDataTitle">{currentConsultation.diet_id}</span></div>
      </div> */}

      <form className="boxShowConsultation">
        {/* Dados da Consulta */}
        <h3>Consulta</h3>

        <div className="lineBoxTop">
          <div className="box-line">
            <div className="boxCol1">
              <label htmlFor="consult_date">Data da Consulta:</label>
              <span className="spanData">{formatDate(currentConsultation.consult_date)}</span>
            </div>
            <div className="boxCol1">
              <label htmlFor="status">Status da Consulta:</label>
              <span className="spanData">{formatStatus(currentConsultation.status)}</span>
            </div>
            {/* <div className="boxCol1">
              <label htmlFor="consult_date">Início da Consulta:</label>
              <span className="spanData">{formatTime(currentConsultation.start_time)}</span>
            </div>
            <div className="boxCol1">
              <label htmlFor="consult_date">Encerramento da Consulta:</label>
              <span className="spanData">{formatTime(currentConsultation?.end_time)}</span>
            </div> */}
          </div>
          <div className="box-line">
            <div className="boxCol1">
              <label htmlFor="company_name">Empresa:</label>
              <span className="spanData">{currentConsultation.company_name}</span>
            </div>
            <div className="boxCol1">
              <label htmlFor="professional_name">Profissional:</label>
              <span className="spanData">{currentConsultation.professional_name}</span>
            </div>
          </div>
        </div>


        <h3>Paciente</h3>
        <div className="lineBoxTop">
          <div className="box-line">
            <div className="boxCol1">
              <label htmlFor="patient_name">Paciente:</label>
              <span className="spanData">{currentConsultation.patient_name}</span>
            </div>
            <div className="boxCol1">
              <label htmlFor="patient_name">Idade:</label>
              <span className="spanData">{calculateAge(person?.birth_date) || 'N/A'}</span>
            </div>
            <div className="boxCol1">
              <label htmlFor="patient_name">Gênero:</label>
              <span className="spanData">{formatGender(person?.gender) || 'N/A'}</span>
            </div>
            <div className="boxCol1">
              <label htmlFor="patient_name">CPF:</label>
              <span className="spanData">{person?.cpf || 'N/A'}</span>
            </div>
          </div>
        </div>

        <h3>Endereço</h3>
        <div className="lineBoxTop">
          <div className="boxCol1">
            {/* <label className='spanData' htmlFor="street">Logradouro:</label> */}
            <span className="spanData">
              <a
                data-tip={addressDetails}
                href={
                  person?.address?.street && person?.address?.number
                    ? `https://www.google.com/maps?q=${person?.address?.street} + ${person?.address?.number}`
                    : '#'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {person?.address
                  ? `${person.address.street || ''}, ${person.address.number || ''} - 
        ${person.address.complement || ''} - 
        ${person.address.neighborhood || ''} - 
        ${person.address.city || ''} - 
        ${person.address.uf || ''} - 
        ${person.address.zipcode || ''}`
                  : 'Endereço não disponível'}
              </a>
            </span>

          </div>
        </div>

        {/* Conexões aos Databases */}
        <h3>Antropometria</h3>
        <div className="consultationLine" />
        {anthropometryData && anthropometryData.length > 0 ? (
          <div className="consultation-form-group">
            <ul className="anthropometry-list">
              <li className="anthropometry-item">
                <FaCalendar className="icon" />
                <span>Data: {formatDate(anthropometryData[0]?.measurement_date)}</span>
              </li>
              <li className="anthropometry-item">
                <FaRulerVertical className="icon" />
                <span>Altura: {anthropometryData[0]?.height} cm</span>
              </li>
              <li className="anthropometry-item">
                <FaWeight className="icon" />
                <span>Peso Atual: {anthropometryData[0]?.current_weight} kg</span>
              </li>
              <li className="anthropometry-item">
                <FaCalculator className="icon" />
                <span>IMC: {anthropometryData[0]?.bmi} kg/m²</span>
              </li>
              <li className="anthropometry-item">
                <FaUserMd className="icon" />
                <span>Classificação: {anthropometryData[0]?.bray_classification}</span>
              </li>
              <li className="anthropometry-item">
                <FaPercentage className="icon" />
                <span>PG: {anthropometryData[0]?.body_fat_percentage} %</span>
              </li>
            </ul>
          </div>
        ) : (
          <>

            <span className='spanDataTitle'>Dados antropométricos não disponíveis. </span>

            <button className="sendBtnSections" type="button" onClick={handleNewAnthropometry}>Nova Antropometria</button>
            {/* <button className="sendBtn" type="button" onClick={handleAllAnthropometry}>Todas Antropometrias</button> */}
            {/* <button className="sendBtn" onClick={() => navigate('/anthropometry-evolution')}>Evolução Antropométrica</button> */}


          </>
        )}

        {/* dietas */}
        <h3>Dietas</h3>
        {
          Array.isArray(currentDiet) && currentDiet.length > 0 ? (
            <div className="consultation-form-group">
              {currentDiet.map((diet) => (
                <div className="box-diet" key={diet.diet_id}>
                  {/* Coluna A - Informações da dieta */}
                  <div className="box-diet-col-A" key={diet.diet_id}>
                    <div>Nome: <span className="spanDataTitle">{formatDietName(diet.name)}</span></div>
                    <div>Criada em: <span className="spanDataTitle">{formatDate(diet.created_at)}</span></div>
                    <div>Objetivo: <span className="spanDataTitle">{diet.objective}</span></div>
                    <div>Total de Calorias: <span className="spanDataTitle">{totalCalories}</span> kcal/dia</div>
                  </div>

                  {/* Coluna B - Lista de Refeições */}
                  {diet.meals && diet.meals.length > 0 && (
                    <div className="box-diet-col-B">
                      {diet.meals.map((meal) => (
                        <div key={meal.meal_id} className='meal-card'>
                          <div className="meal-line">
                            <div className="label-card"><div className="label-icon">{getMealIcon(meal.name)}</div></div>
                            <div className="meal-title"><span className="spanDataTitle">{meal.name} {" "}</span> </div>
                            <div className="meal-time">Hora: <span className="spanDataTitle">{formatTime(meal.time)} {" "}</span></div>
                            <div className="meal-kcal">Calorias: <span className="spanDataTitle">{meal.total_calories}</span> kcal</div>
                          </div>

                          {/* Lista de Alimentos */}
                          {meal.foods && meal.foods.length > 0 && (
                            <div>
                              {meal.foods.map((food, food_id) => (
                                <div key={`${food.food_id}-${food.food_name}`} className="food-line">
                                  <ul className='dietFoodLine'>
                                    <li>
                                      <div className="liCol">
                                        <label htmlFor="">Quant</label>
                                        <div><span className="spanDataTitle">{food.quantity}{" "} </span>{food.unit_measurement} {" "}</div>
                                      </div>
                                      <div className="liCol">
                                        <label htmlFor="">Alimento: </label>
                                        <div><span className="spanDataTitle">{food.food_name} {" "}</span></div>
                                      </div>
                                      <div className="liCol liCalories">
                                        <label htmlFor="">Calorias: </label>
                                        <div><span className="spanDataTitle">{food.calories}</span> kcal</div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                </div>
              ))}
            </div>

          ) : (
            <>
              <span className='spanDataTitle'>Não há dietas cadastradas para este paciente. </span>
              {/* Botão para criar dieta através do ID do agendamento */}
              <button
                className="sendBtnSections"
                type="button"
                onClick={() =>
                  navigate('/home/diet/create', {
                    state: {
                      appointment_id: currentConsultation?.appointment_id,
                      patient_id: currentConsultation?.patient_id,
                      consultation_id: currentConsultation?.consultation_id,
                    },
                  })
                }
              >
                Criar Nova Dieta
              </button>
            </>
          )
        }

        <h3>Procedimento e Diagnóstico</h3>

        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="procedures">Doenças Atuais:</label>
            <div className="spanDataProcedures">
              {Array.isArray(currentConsultation?.current_illnesses) && currentConsultation.current_illnesses.length > 0 ? (
                currentConsultation.current_illnesses.map((illness) => (
                  <div key={illness} className="anthropometry-item">
                    {illness}
                  </div>
                ))
              ) : (
                <div className="anthropometry-item">N/A</div>
              )}
            </div>
          </div>
        </div>

        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="procedures">Doenças Prévias ou Crônicas:</label>
            <div className="spanDataProcedures">
              {Array.isArray(currentConsultation?.past_illnesses) && currentConsultation.past_illnesses.length > 0 ? (
                currentConsultation.past_illnesses.map((illness) => (
                  <div key={illness} className="anthropometry-item">
                    {illness}
                  </div>
                ))
              ) : (
                <div className="anthropometry-item">N/A</div>
              )}
            </div>
          </div>
        </div>

        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="procedures">Alergias e Intolerâncias:</label>
            <div className="spanDataProcedures">
              {Array.isArray(currentConsultation?.allergies) && currentConsultation.allergies.length > 0 ? (
                currentConsultation.allergies.map((allergy) => (
                  <div key={allergy} className="anthropometry-item">
                    {allergy}
                  </div>
                ))
              ) : (
                <div className="anthropometry-item">N/A</div>
              )}
            </div>
          </div>
        </div>

        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="procedures">Cirurgias e Hospitalizações:</label>
            <div className="spanDataProcedures">
              {Array.isArray(currentConsultation?.surgeries) && currentConsultation.surgeries.length > 0 ? (
                currentConsultation.surgeries.map((surgery) => (
                  <div key={surgery} className="anthropometry-item">
                    {surgery}
                  </div>
                ))
              ) : (
                <div className="anthropometry-item">N/A</div>
              )}
            </div>
          </div>
        </div>

        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="procedures">Medicamentos:</label>
            <div className="spanDataProcedures">
              {Array.isArray(currentConsultation?.medications) && currentConsultation.medications.length > 0 ? (
                currentConsultation.medications.map((medication) => (
                  <div key={medication} className="anthropometry-item">
                    {medication}
                  </div>
                ))
              ) : (
                <div className="anthropometry-item">N/A</div>
              )}
            </div>
          </div>
        </div>

        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="procedures">Histórico Familiar:</label>
            <div className="spanDataProcedures">
              {Array.isArray(currentConsultation?.family_history) && currentConsultation.family_history.length > 0 ? (
                currentConsultation.family_history.map((history) => (
                  <div key={history} className="anthropometry-item">
                    {history}
                  </div>
                ))
              ) : (
                <div className="anthropometry-item">N/A</div>
              )}
            </div>
          </div>
        </div>


        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="procedures">Procedimentos:</label>
            <span className="spanData">{currentConsultation?.procedures || 'N/A'}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="diagnosis">Diagnóstico:</label>
            <span className="spanData">{currentConsultation?.diagnosis || 'N/A'}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="diagnosis">Observações:</label>
            <span className="spanData">{currentConsultation?.observations || 'N/A'}</span>
          </div>
        </div>

        {/* Conexões Fictícias */}
        <h3>Medicações</h3>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="medicacoes">Medicações:</label>
            <span className="spanData">Em desenvolvimento...</span>
            <FaPills className="icon" />
          </div>
        </div>
        {/* Conexões Fictícias */}
        <h3>Tratamentos</h3>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="tratamentos">Tratamentos:</label>
            <span className="spanData">Em desenvolvimento...</span>
            <FaStethoscope className="icon" />
          </div>
        </div>


        {/* Botões de Ação */}
        <div className="boxButtons1">
          <button className="showBoxBtn" type="button" onClick={() => alert('Enviar email')}>
            <MdEmail className="sideIcoBtn" />
            <span className="sideBtnSpan">Email</span>
          </button>
        </div>
        <div className="boxButtons2">
          <button
            className="showBoxBtn"
            type="button"
            onClick={() => handlePrint(currentConsultation, person, currentDiet, anthropometryData)}
          >
            <MdPrint className="sideIcoBtn" />
            <span className="sideBtnSpan">Imprimir</span>
          </button>
        </div>
        {
          hasRole('Admin' || 'Manager') && (
            <>
              <div className="boxButtons3">
                <button
                  // className="showBoxBtn"
                  className={
                    currentConsultation.status === 'completed' ? 'showBoxBtn-disable' : 'showBoxBtn'
                  }
                  type="button"
                  onClick={handleEdit}
                  disabled={currentConsultation.status === 'completed'} // Condição para desabilitar

                  // Estilização visual para indicar o estado
                  style={{
                    cursor: currentConsultation.status === 'completed' ? 'not-allowed' : 'pointer',
                    opacity: currentConsultation.status === 'completed' ? 0.6 : 1,
                  }}
                >
                  <MdEdit className="sideIcoBtn" />
                  <span className="sideBtnSpan">Editar</span>
                </button>
              </div>
              {/* <div className="boxButtons4">
                <button className="showBoxBtn" type="button" onClick={handleConfirmFinish}>
                  <MdMedicalServices className="sideIcoBtn" />
                  <span className="sideBtnSpan">Finalizar</span>
                </button>
              </div> */}
              <div className="boxButtons4">
                <button className="showBoxBtn" type="button" onClick={handleConfirmAction}>
                  <MdMedicalServices className="sideIcoBtn" />
                  <span className="sideBtnSpan">
                    {currentConsultation.status === 'completed' ? 'Reabrir Consulta' : 'Finalizar Consulta'}
                  </span>
                </button>
              </div>
            </>
          )
        }
        <div className="boxButtons5">
          <button className="showBoxBtn" type="button" onClick={handleBack}>
            <MdExitToApp className="sideIcoBtn" />
            <span className="sideBtnSpan">Voltar</span>
          </button>
        </div>
      </form >
      {showConfirmationModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmFinishConsultation}
          onCancel={handleCancelFinishConsultation}
        />
      )
      }
      {
        showModal && (
          <ModalForms message={message} />
        )
      }
      {
        showConfirmationModal && (
          <ConfirmationModal
            message={message}
            onConfirm={handleFinishOrReopen}
            onCancel={handleCancelAction}
          />
        )
      }
    </>
  );
};

export default ShowConsultation;
