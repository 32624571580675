// src/redux/reducers/anthropometryReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { createNewAnthropometry, fetchAnthropometryByPatientId, fetchAnthropometryData } from '../actions/anthropometryActions';

const anthropometrySlice = createSlice({
  name: 'anthropometry',
  initialState: {
    anthropometry: [],
    loading: false,
    error: null,
    currentAnthropometry: [],
  },
  reducers: {
    setCurrentAnthropometry: (state, action) => {
      state.currentAnthropometry = action.payload;
    },
    clearAnthropometry: (state) => {
      state.currentAnthropometry = [];
      state.anthropometryData = []; // Limpa também o estado de anthropometryData
      state.error = null; // Limpa erros anteriores, se necessário
      state.loading = false; // Garante que não há um estado de carregamento residual
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewAnthropometry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewAnthropometry.fulfilled, (state, action) => {
        state.loading = false;
        // state.anthropometry.push(action.payload);
        state.anthropometry = [...state.anthropometry, action.payload]; // Atualizando de forma imutável
      })
      .addCase(fetchAnthropometryByPatientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnthropometryByPatientId.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAnthropometry = action.payload || [];
        // console.log("Payload recebido no fulfilled:", action.payload); // Debug
      })
      .addCase(fetchAnthropometryByPatientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAnthropometryData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnthropometryData.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload && Array.isArray(action.payload)) {
          state.anthropometryData = action.payload || []; // Armazena o array de dados
        } else {
          state.anthropometryData = []; // Define um array vazio se não houver dados
        }
      })
      .addCase(fetchAnthropometryData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      ;
  }
});
export const { setCurrentAnthropometry, clearAnthropometry, anthropometryData } = anthropometrySlice.actions;
export default anthropometrySlice.reducer;