import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTacoFoods } from '../../../redux/actions/nutridataActions';
import { useNavigate } from 'react-router-dom';

import SearchBar from '../../../components/SearchBar/SearchBar';
import Pagination from '../../../components/Pagination/Pagination';
import SearchBarFoods from '../../../components/SearchBarFoods/SearchBarFoods';

import '../styles/Nutridata.css';
import { setCurrentTacoFood } from '../../../redux/slices/nutridataSlice';

const GetAllTacoFoods = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchText, setSearchText] = useState("");

  const { tacoFoods = [], loading, error } = useSelector((state) => state.nutridata);

  useEffect(() => {
    dispatch(fetchAllTacoFoods());
  }, [dispatch]);

  const handleSearch = (searchText) => {
    setSearchText(searchText);
  };

  const handleShowFoodDetails = (taco_food_id) => {
    const food_id = taco_food_id;
    // console.log("Selected food_id:", food_id);
    dispatch(setCurrentTacoFood(food_id))
    navigate(`/home/nutridata/show/${food_id}`);
  };

  const filteredFoods = tacoFoods.filter((food) =>
    `${food.food_name} ${food.category_name}`
      .toLowerCase()
      .includes(searchText.trim().toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredFoods.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div className="col-md-12">
        <h3>Lista de Alimentos <span className="title-span">(referênciada na tabela *TACO)</span></h3>
        {/* <SearchBarFoods onSearch={handleSearch} /> */}
        <SearchBar onSearch={handleSearch} />
      </div>

      <div className="main-food">
        <div className="food-content">
          {loading ? (
            <div className="rowMessage">Carregando...</div>
          ) : currentItems.length === 0 ? (
            <div className="rowMessage">Nenhum alimento encontrado</div>
          ) :
            <ul>
              {currentItems.map((food, key) => (
                <li key={food.food_id} >
                  <a onClick={(event) => { event.preventDefault(); handleShowFoodDetails(food.taco_food_id) }} href="#" className='custom-cursor-li'>
                    <div className="row-food">
                      <div className="col1">
                        <label htmlFor="alimento">Alimento</label>
                        <div>{food.food_name}</div>
                      </div>
                      <div className="col2">
                        <label htmlFor="categoria">Categoria</label>
                        <div>{food.category_name}</div>
                      </div>
                      <div className="col3">
                        <label htmlFor="calorias">Calorias</label>
                        <div>{food.kcal ?? "0.00"}</div>
                      </div>
                      <div className="col4">
                        <label htmlFor="carboidratos">Carboidratos</label>
                        <div>{food.carbohydrates ?? "0.00"}</div>
                      </div>
                      <div className="col5">
                        <label htmlFor="proteinas">Proteínas</label>
                        <div>{food.protein ?? "0.00"}</div>
                      </div>
                      <div className="col6">
                        <label htmlFor="gorduras_aturadas">G. Saturadas</label>
                        <div>{food.saturated ?? "0.00"}</div>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          }
        </div>
      </div>
      <Pagination
        totalItems={filteredFoods.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        paginate={paginate}
      />

    </>
  );
};

export default GetAllTacoFoods;
