import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchAnthropometryByPatientId } from '../../../redux/actions/anthropometryActions';
import { fetchPatients, fetchAllProfessionals } from '../../../redux/actions/relatedEntitiesActions';
import { fetchPersons } from '../../../redux/actions/personActions';
import { clearAnthropometry } from '../../../redux/slices/anthropometrySlice';
import Select from 'react-select';
import Pagination from '../../../components/Pagination/Pagination';
import { AuthContext } from '../../../context/auth';

import { FaUserDoctor } from "react-icons/fa6";
import { MdOutlineSick } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";
import { BiBody } from "react-icons/bi";
import { MdHome } from 'react-icons/md';

import babyComming from "../../../assets/img/cegonha.png";
import '../styles/GetAnthropometry.css';
import '../../../Mobile.css';

import Accordion from '../../../components/Accordion/Accordion';

function GetAnthropometryByPatientId() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const returnBtn = "Retornar";

  // Obtendo pacientes, profissionais e estado de antropometria
  const { patients = [], loadingPatients, errorPatients } = useSelector((state) => state.relatedEntities);
  const { professionals = [], loadingProfessionals } = useSelector((state) => state.relatedEntities);
  const { persons = [], loadingPersons } = useSelector((state) => state.persons);
  const { currentAnthropometry = [], loadingAnthropometry, errorAnthropometry } = useSelector((state) => state.anthropometry || { currentAnthropometry: [] });

  // Paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = (currentAnthropometry || []).slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Variáveis que precisam ser calculadas
  // 
  const [patientGender, setPatientGender] = useState('');
  const [patientBirthDate, setPatientBirthDate] = useState('');
  const [loadedProfessionals, setLoadedProfessionals] = useState({});
  const [selectedPatient, setSelectedPatient] = useState(null);

  // Carregar pacientes, pessoas e profissionais ao montar o componente
  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchPersons());
    dispatch(fetchAllProfessionals());
    dispatch(clearAnthropometry());
  }, [dispatch]);


  useEffect(() => {
    if (!loadingPersons && !loadingProfessionals && persons.length > 0 && professionals.length > 0) {
      const professionalMap = {};

      // Mapeia todos os profissionais com base no person_id
      // biome-ignore lint/complexity/noForEach: <explanation>
      persons.forEach(person => {
        if (['nutritionist', 'professional', 'doctor'].includes(person.person_type)) {
          professionalMap[person.person_id] = `${person.first_name} ${person.last_name}`;
        }
      });

      // Adiciona ou atualiza com informações adicionais dos professionals, se necessário
      // biome-ignore lint/complexity/noForEach: <explanation>
      professionals.forEach(professional => {
        if (!professionalMap[professional.person_id]) {
          professionalMap[professional.person_id] = professional.name || 'Nome não disponível';
        }
      });

      setLoadedProfessionals(professionalMap);
      // console.log('Profissionais mapeados:', professionalMap);
    }
  }, [loadingPersons, loadingProfessionals, persons, professionals]);

  // Função para lidar com a seleção de paciente e atualizar gênero e data de nascimento
  const handlePatientChange = (selectedOption) => {
    setSelectedPatient(selectedOption);

    // Limpa o estado de antropometria antes de buscar novos dados
    dispatch(clearAnthropometry());

    if (selectedOption) {
      const patientDetails = persons.find(person => person.person_id === selectedOption.value);
      if (patientDetails) {
        setPatientGender(patientDetails.gender);
        setPatientBirthDate(patientDetails.birth_date);
      }
      dispatch(fetchAnthropometryByPatientId(selectedOption.value));
    }
  };

  // Função para buscar o nome do nutricionista pelo id
  const findNutritionistName = (nutritionist_id) => {
    if (!nutritionist_id) return 'ID do nutricionista não fornecido';
    return loadedProfessionals[nutritionist_id] || 'Nutricionista não encontrado';
  };

  useEffect(() => {
    // Capturar o patient_id do estado enviado pela navegação
    const patientIdFromState = location.state?.patient_id;
    if (patientIdFromState) {
      setSelectedPatient({ value: patientIdFromState, label: 'Paciente Selecionado' }); // Substitua por um nome adequado, se disponível
      dispatch(fetchAnthropometryByPatientId(patientIdFromState));
    }
  }, [location.state, dispatch]);

  // useEffect(() => {
  //   console.log('Dados de antropometria:', currentAnthropometry);
  //   console.log('Dados do Paciente: ', patients);
  //   console.log('Dados do Paciente:', persons);
  //   console.log('Professionals:', professionals);
  // }, [currentAnthropometry, patients, persons, professionals]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/home/dashboard');
  }
  // Função para calcular a Idade Ano Meses e Dias
  const calculateAge = (patientBirthDate) => {
    const today = new Date();
    const birthDate = new Date(patientBirthDate);
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (days < 0) {
      months--;
      const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      days += lastMonth.getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return `${pluralize(years, 'Ano', 'Anos')} ${pluralize(months, 'mês', 'meses')} e ${pluralize(days, 'dia', 'dias')}`;
  };
  const pluralize = (count, singular, plural) => {
    return `${count} ${count === 1 ? singular : plural}`;
  };
  // Função para calcular a Idade Anos
  const calculateAgeYear = (patientBirthDate) => {
    const today = new Date();
    const birthDate = new Date(patientBirthDate);
    let years = today.getFullYear() - birthDate.getFullYear();
    const months = today.getMonth() - birthDate.getMonth();
    const days = today.getDate() - birthDate.getDate();

    // Ajuste para meses e dias se o aniversário ainda não foi completado no ano corrente
    if (months < 0 || (months === 0 && days < 0)) {
      years--;
    }

    return years; // Retorna apenas os anos
  }
  // Função para formatar a Data DD/MM/YYYY
  const formatDate = (patientBirthDate) => {
    if (!patientBirthDate) return 'Data inválida';
    const date = new Date(patientBirthDate);
    if (Number.isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  // Função para formatar o Gênero
  const formatGender = (patientGender) => {
    switch (patientGender) {
      case 'male':
        return 'Masculino';
      case 'female':
        return 'Feminino';
      case 'others':
        return 'Outros';
      default:
        return '';
    }
  }

  //Função para formatar o Nível de Atividade
  const formatPhysicalActivityLevel = (physical_activity_level) => {
    switch (physical_activity_level) {
      case 'light':
        return 'Leve';
      case 'moderate':
        return 'Moderada';
      case 'intense':
        return 'Intensa';
      default:
        return '';
    }
  }

  //Função para formatar a Frequência de Atividade
  const formatPhysicalActivityFrequency = (physical_activity_frequency) => {
    switch (physical_activity_frequency) {
      case 'unable':
        return 'Nenhuma';
      case 'sedentary':
        return 'Sedentário';
      case 'occasional':
        return 'Ocasional';
      case 'frequent':
        return 'Frequente';
      case 'regular':
        return 'Regular';
      default:
        return '';
    }
  }

  return (
    <>
      <h3>Buscar Medições Antropométricas por Paciente</h3>

      <div className="getAnthropometryForm">
        <div className="getAnthropometry-form-group">
          <h5 className='anthropometryTitle'>Paciente:</h5>
          <Select
            name="patient_id"
            options={patients.map(patient => ({
              value: patient.person_id,
              label: patient.name,
            }))}
            onChange={handlePatientChange}
            classNamePrefix="custom-select"
            value={selectedPatient} // Atualizar para o estado selecionado
            placeholder={!selectedPatient ? "Selecione o paciente" : ""} // Exibir o placeholder quando não há paciente selecionado
            isLoading={loadingPatients}
            isClearable // Permite limpar o valor selecionado e ver o placeholder
          />
          {errorPatients && <div className="error-message">Erro ao carregar pacientes: {errorPatients}</div>}
          <button type="button" className='returnDashboard' onClick={handleBack}
            data-tip={returnBtn}><MdHome /></button>
        </div>
      </div>
      <div className="getAccordion">
        {loadingAnthropometry ? (
          <div>Carregando medições...</div>
        ) : errorAnthropometry ? (
          <div className="error-message">Erro ao carregar medições antropométricas: {errorAnthropometry}</div>
        ) : currentAnthropometry.length === 0 && selectedPatient ? (
          <div>Nenhuma medição encontrada para o paciente selecionado.</div>
        ) : selectedPatient && (
          <div>
            <h5 className='anthropometryTitle'>Medições Anteriores</h5>
            <div className="row-getMeasures">
              {currentItems.map((anthropometry) => {
                return (
                  <Accordion
                    key={anthropometry.measurement_date}
                    title={
                      <div className="row-getAnthropometryBox">
                        <div className="colAntBox">
                          <label htmlFor="date" className="labelAnt">Data</label>
                          <div className="rowAntBox">
                            <LuCalendarClock /> {formatDate(anthropometry.measurement_date)}
                          </div>
                        </div>
                        <div className="colAntBox">
                          <label htmlFor="date" className="labelAnt">Paciente</label>
                          <div className="rowAntBox">
                            <MdOutlineSick /> {selectedPatient ? selectedPatient.label : ''}
                          </div>
                        </div>
                        <div className="colAntBox">
                          <label htmlFor="date" className="labelAnt">Profissional</label>
                          <div className="rowAntBox">
                            <FaUserDoctor /> {findNutritionistName(anthropometry.nutritionist_id)}
                          </div>
                        </div>
                        <div className="colAntBox">
                          <label htmlFor="date" className="labelAnt">IMC</label>
                          <div className="rowAntBox">
                            {anthropometry.bmi} |  <BiBody /> | {anthropometry.bmi_classification_code} | {anthropometry.bray_classification}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div className="row-Anthropometry">
                      {selectedPatient && (
                        <>
                          <h5 className='anthropometryTitle'>Resultados da Avaliação</h5>
                          <div className="row-box">
                            <div className="row-line">
                              <label>Gênero</label>
                              <div className="return-data">{formatGender(patientGender)}</div>
                            </div>

                            <div className="row-line">
                              <label>Data de Nascimento</label>
                              <div className="return-data">{formatDate(patientBirthDate)}</div>
                            </div>

                            <div className="row-line">
                              <label>Idade</label>
                              <div className="return-data">{calculateAge(patientBirthDate)}</div>
                            </div>

                            <div className="row-line">
                              <label>Altura</label>
                              <div className="return-data">{anthropometry.height ? `${anthropometry.height} cm` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>Peso Atual</label>
                              <div className="return-data">{anthropometry.current_weight ? `${anthropometry.current_weight} kg` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>IMC</label>
                              <div className="return-data">{anthropometry.bmi ? `${anthropometry.bmi} kg/m²` : 'N/A'} | {anthropometry.bmi_classification_code ? `${anthropometry.bmi_classification_code}` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>quetelet </label>
                              <div className="return-data">{anthropometry.bmi ? `${anthropometry.bmi} kg/m²` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>Peso Teórico (PT)</label>
                              <div className="return-data">{anthropometry.theoretical_weight ? `${anthropometry.theoretical_weight} kg` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>Classificação Biotipo (Bernadinelli)</label>
                              <div className="return-data">{anthropometry.pg_classification_code ? `${anthropometry.pg_classification_code}` : 'N/A'}</div>
                            </div>
                            {/* 
                            <div className="row-line">
                              <label>% PT (Blackburn)</label>
                              <div className="return-data">{porcentagemPT}%</div>
                            </div> */}

                            <div className="row-line">
                              <label>Classificação OMS (Gerorge A. Bray)</label>
                              <div className="return-data">{anthropometry.bray_classification ? `${anthropometry.bray_classification}` : 'N/A'}</div>
                            </div>


                            <div className="row-line">
                              <label>Classificação Nutricional (Blackburn)</label>
                              <div className="return-data">{anthropometry.blackburn_classification ? `${anthropometry.blackburn_classification}` : 'N/A'}</div>
                            </div>


                            <div className="row-line">
                              <label>CMB (Circunferência Muscular do Braço)</label>
                              <div className="return-data">{anthropometry.arm_circumference ? `${anthropometry.arm_circumference} cm` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>AMB (Área Muscular do Braço)</label>
                              <div className="return-data">{anthropometry.arm_muscle_area ? `${anthropometry.arm_muscle_area} cm²` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>AMBc (Área Muscular do Braço Corrigida)</label>
                              <div className="return-data">{anthropometry.arm_muscle_area_adj ? `${anthropometry.arm_muscle_area_adj} cm²` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>AB (Área do Braço)</label>
                              <div className="return-data">{anthropometry.arm_area ? `${anthropometry.arm_area} mm²` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>AGB (Área Gordurosa do Braço)</label>
                              <div className="return-data">{anthropometry.arm_fat_area ? `${anthropometry.arm_fat_area} mm²` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>Classificação Musculatura</label>
                              <div className="return-data">{anthropometry.muscle_mass_classification ? `${anthropometry.muscle_mass_classification}` : 'N/A'}</div>
                            </div>

                            <div className="row-line">
                              <label>Circunferência / Classificação da Cintura</label>
                              <div className="return-data">{anthropometry.waist_circumference ? `${anthropometry.waist_circumference} cm` : 'N/A'} | {anthropometry.waist_classification ? `${anthropometry.waist_classification}` : 'N/A'}</div>
                            </div>
                            <div className="row-line">
                              <label>Altura Estimada</label>
                              <div className="return-data">{anthropometry.estimated_height ? `${anthropometry.estimated_height} cm` : 'N/A'}</div>
                            </div>
                            <div className="row-line">
                              <label>TMB (Taxa de Metabolismo Basal)</label>
                              <div className="return-data">{anthropometry.tmb ? `${anthropometry.tmb} kcal/dia` : 'N/A'}</div>
                            </div>
                            <div className="row-line">
                              <label>VET (Valor Energético Total)</label>
                              <div className="return-data">{anthropometry.vet ? `${anthropometry.vet} kcal/dia` : 'N/A'}</div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>


                    {patientGender === 'female' && anthropometry.gestational_age && (
                      <div className="row-Anthropometry">
                        <div className="titleLine">
                          <h5 className='anthropometryTitle'>Informações da Gestação</h5>
                          <div className="sliding-image-container">
                            <img src={babyComming} alt="cegonha" />
                          </div>
                        </div>

                        <div className="row-box">
                          <>
                            <div className="row-line">
                              <label>Idade Gestacional</label>
                              <div className="return-data">
                                {anthropometry.gestational_age
                                  ? `${anthropometry.gestational_age} semanas`
                                  : 'N/A'}
                              </div>
                            </div>

                            <div className="row-line">
                              <label>Data Provável do Parto - DPP</label>
                              <div className="return-data">
                                {anthropometry.ddp_date
                                  ? formatDate(anthropometry.ddp_date)
                                  : 'N/A'}
                              </div>
                            </div>

                            <div className="row-line">
                              <label>Última Menstruação - DUM</label>
                              <div className="return-data">
                                {anthropometry.last_period_date
                                  ? formatDate(anthropometry.last_period_date)
                                  : 'N/A'}
                              </div>
                            </div>


                            <div className="row-line">
                              <label>Peso Pré Gestacional</label>
                              <div className="return-data">
                                {anthropometry.ppg_weight
                                  ? `${anthropometry.ppg_weight} kg`
                                  : 'N/A'}
                              </div>
                            </div>

                            <div className="row-line">
                              <label>IMC Gestacional</label>
                              <div className="return-data">
                                {anthropometry.gestational_imc
                                  ? `${anthropometry.gestational_imc} kg/m²`
                                  : 'N/A'}
                              </div>
                            </div>

                            <div className="row-line">
                              <label>Ganho de Peso Recomendado</label>
                              <div className="return-data">
                                {anthropometry.recomended_weight_gain
                                  ? `${anthropometry.recomended_weight_gain} kg`
                                  : 'N/A'}
                              </div>
                            </div>
                            <div className="row-line"></div>
                            <div className="row-line"></div>
                          </>
                        </div>
                      </div>
                    )}


                    <Accordion title={"Classificações"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='pg_classification_code'>Código da Classificação PG</label>
                          <div>{anthropometry.pg_classification_code ?? 'N/A'}</div>
                        </div>
                        {/* <div className="colAnt">
                          <label htmlFor='formula_pt_code'>Código da Fórmula Peso Teórico - PT</label>
                          <div>{anthropometry.formula_pt_code ?? 'N/A'}</div>
                        </div> */}
                        <div className="colAnt">
                          <label htmlFor='body_fat_percentage'>Percentual de Gordura Corporal</label>
                          <div>{anthropometry.body_fat_percentage ?? 'N/A'} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='ideal_fat_percentage'>Percentual Ideal de Gordura</label>
                          <div>{anthropometry.ideal_fat_percentage ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='gi_percentage_type'>Tipo de Percentual GI</label>
                          <div>{anthropometry.gi_percentage_type ?? 'N/A'} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='total_body_water'>Total Corporal de Água</label>
                          <div>{anthropometry.total_body_water ?? 'N/A'} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='lean_weight_type'>Tipo de Peso Magro</label>
                          <div>{anthropometry.lean_weight_type ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='muscle_mass_weight'>Peso da Massa Corporal</label>
                          <div>{anthropometry.muscle_mass_weight ?? 'N/A'} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='physical_activity'>Frequência da Atividade Física</label>
                          <div>{formatPhysicalActivityFrequency(anthropometry.physical_activity_frequency) ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='physical_activity_level'>Nível da Atividade Física</label>
                          <div>{formatPhysicalActivityLevel(anthropometry.physical_activity_level) ?? 'N/A'}</div>
                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Medidas Essênciais"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor="measurement_date">Data da Medição</label>
                          <div>{formatDate(anthropometry.measurement_date)}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="height">Altura</label>
                          <div className="return-data">{anthropometry.height ? `${anthropometry.height} cm` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="weight">Peso Atual</label>
                          <div className="return-data">{anthropometry.current_weight ? `${anthropometry.current_weight} kg` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="imcClassificacao">IMC</label>
                          <div className="return-data">{anthropometry.bmi ? `${anthropometry.bmi} kg/m²` : 'N/A'} | {anthropometry.bmi_classification_code ? `${anthropometry.bmi_classification_code}` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="estimated_height">Altura Estimada</label>
                          <div className="return-data">{anthropometry.estimated_height ? `${anthropometry.estimated_height} cm` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="estimated_current_weight">Peso Estimado Atual</label>
                          <div className="return-data">{anthropometry.estimated_current_weight ? `${anthropometry.estimated_current_weight} kg` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="estimated_current_weight">Peso Estimado Atual (Peso Teórico)</label>
                          <div className="return-data">{anthropometry.theoretical_weight ? `${anthropometry.theoretical_weight} kg` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="desired_weight">Peso Desejado</label>
                          <div className="return-data">{anthropometry.desired_weight ? `${anthropometry.desired_weight} kg` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="usual_weight">Peso Habitual</label>
                          <div className="return-data">{anthropometry.usual_weight ? `${anthropometry.usual_weight} kg` : 'N/A'}</div>
                        </div>
                        <div className="colEmpty">
                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Braços"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='left_arm'>Braço Esquerdo</label>
                          <div>{anthropometry.left_arm ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_arm'>Braço Direito</label>
                          <div>{anthropometry.right_arm ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='left_forearm'>Antebraço Esquerdo</label>
                          <div>{anthropometry.left_forearm ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_forearm'>Antebraço Direito</label>
                          <div>{anthropometry.right_forearm ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='left_hand'>Mão Esquerda</label>
                          <div>{anthropometry.left_hand ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_hand'>Mão Direita</label>
                          <div>{anthropometry.right_hand ?? 'N/A'} cm</div>
                        </div>
                        <div className="colEmpty">
                        </div>
                        <div className="colEmpty">
                        </div>
                      </div>

                    </Accordion>

                    <Accordion title={"Pernas"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='left_leg'>Perna Esquerda</label>
                          <div>{anthropometry.left_leg ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_leg'>Perna Direita</label>
                          <div>{anthropometry.right_leg ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='left_lower_leg'>Perna Inferior Esquerda</label>
                          <div>{anthropometry.left_lower_leg ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_lower_leg'>Perna Inferior Direita</label>
                          <div>{anthropometry.right_lower_leg ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='left_foot'>Pé Esquerdo</label>
                          <div>{anthropometry.left_foot ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='right_foot'>Pé Direito</label>
                          <div>{anthropometry.right_foot ?? 'N/A'} cm</div>
                        </div>
                        <div className="colEmpty">

                        </div>
                        <div className="colEmpty">

                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Circunferências"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='head_circumference'>Circunferência da Cabeça</label>
                          <div>{anthropometry.head_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='arm_circumference'>Circunferência do Braço</label>
                          <div>{anthropometry.arm_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='waist_circumference'>Circunferência da Cintura</label>
                          <div>{anthropometry.waist_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='chest_circumference'>Circunferência do Tórax</label>
                          <div>{anthropometry.chest_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='hip_circumference'>Circunferência do Quadril</label>
                          <div>{anthropometry.hip_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='gluteal_max_circumference'>Circunferência Máxima do Glúteo</label>
                          <div>{anthropometry.gluteal_max_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='thigh_max_circumference'>Circunferência Máxima da Coxa</label>
                          <div>{anthropometry.thigh_max_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='forearm_circumference'>Circunferência Máxima do Antebraço</label>
                          <div>{anthropometry.forearm_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='wrist_circumference'>Circunferência do Pulso</label>
                          <div>{anthropometry.wrist_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='calf_circumference'>Circunferência da Panturrilha</label>
                          <div>{anthropometry.calf_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='ankle_circumference'>Circunferência do Tornozelo</label>
                          <div>{anthropometry.ankle_circumference ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='abdominal_circumference'>Circunferência Abdominal</label>
                          <div>{anthropometry.abdominal_circumference ?? 'N/A'} cm</div>
                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Diâmetros e Alturas Osseas"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='knee_height'>Altura do Joelho</label>
                          <div>{anthropometry.knee_height ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='radial_diameter'>Diâmetro do Radial</label>
                          <div>{anthropometry.radial_diameter ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='femur_diameter'>Diâmetro do Fêmur</label>
                          <div>{anthropometry.femur_diameter ?? 'N/A'} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='humeral_diameter'>Diâmetro do Úmero</label>
                          <div>{anthropometry.humeral_diameter ?? 'N/A'} cm</div>
                        </div>
                      </div>
                    </Accordion>

                    <Accordion title={"Dobras Cutâneas"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='measurement_type'>Tipo de Medição</label>
                          <div>{anthropometry.fat_calculate_equation ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='suprailiac_skinfold'>Dobra Suprailíaca</label>
                          <div>{`${anthropometry.suprailiac_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='subscapular_skinfold'>Dobra Subescapular</label>
                          <div>{`${anthropometry.subscapular_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='supraespinal_skinfold'>Dobra Supraespinhal</label>
                          <div>{`${anthropometry.supraespinal_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='chest_skinfold'>Dobra Peitoral</label>
                          <div>{`${anthropometry.chest_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='triceps_skinfold'>Dobra Trícepes</label>
                          <div>{`${anthropometry.triceps_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='biceps_skinfold'>Dobra Bícepes</label>
                          <div>{`${anthropometry.biceps_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='thigh_skinfold'>Dobra da Coxa</label>
                          <div>{`${anthropometry.thigh_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='calf_skinfold'>Dobra da Panturrilha</label>
                          <div>{`${anthropometry.calf_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='abdominal_skinfold'>Dobra Abdominal</label>
                          <div>{`${anthropometry.abdominal_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='axillary_skinfold'>Dobra Axilar</label>
                          <div>{`${anthropometry.axillary_skinfold} mm` ?? 'N/A'}</div>
                        </div>
                        <div className="colEmpty"></div>
                      </div>
                    </Accordion>

                    {/* <Accordion title={"Fatores VET"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='vet_final'>VET Final</label>
                          <div>{anthropometry.vet_final ?? 'N/A'} kcal</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='vet_final_equation'>VET Final Equação</label>
                          <div>{anthropometry.vet_final_equation ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='vet_final_weight'>VET Final Peso</label>
                          <div>{anthropometry.vet_final_weight ?? 'N/A'} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='formula_vet_code'>Código da Fórmula VET</label>
                          <div>{anthropometry.formula_vet_code ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='activity_factor_code'>Código do Fator de Atividade</label>
                          <div>{anthropometry.activity_factor_code ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='formula_fat_percentage_code'>Código da Fórmula do Percentual de Gordura</label>
                          <div>{anthropometry.formula_fat_percentage_code ?? 'N/A'}</div>
                        </div>
                        <div className="colEmpty"></div>
                        <div className="colEmpty"></div>
                      </div>
                    </Accordion> */}

                    < Accordion title={"Outros Índices"} >
                      <div className="row-getAnthropometry">
                        {/* <div className="colAnt">
                          <label htmlFor='age_years'>Idade / Anos</label>
                          <div>{anthropometry.age_years ?? 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='age_months'>Idade / Meses</label>
                          <div>{anthropometry.age_months ?? 'N/A'}</div>
                        </div> */}
                        <div className="colAnt">
                          <label htmlFor='reference_estimated_weight'>Peso de Referência Esimado</label>
                          <div>{anthropometry.reference_estimated_weight ?? 'N/A'} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='fat_mass_weight'>Peso da Massa Gorda</label>
                          <div>{anthropometry.fat_mass_weight ?? 'N/A'} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor='ppr_time'>Perda de Peso Recente - PPR</label>
                          <div>{anthropometry.ppr_time ?? 'N/A'} kg</div>
                        </div>
                        {/* <div className="colAnt">
                          <label htmlFor='vet_id'>VET ID</label>
                          <div>{anthropometry.vet_id ?? 'N/A'}</div>
                        </div> */}
                        {/* <div className="colAnt">
                          <label htmlFor='vet_reduction_id'>VET Redução ID</label>
                          <div>{anthropometry.vet_reduction_id ?? 'N/A'}</div>
                        </div> */}
                        <div className="colAnt">
                          <label htmlFor='bray_classification'>Classificação OMS (Gerorge A. Bray)</label>
                          <div className="return-data">{anthropometry.bray_classification ? `${anthropometry.bray_classification}` : 'N/A'}</div>
                        </div>
                        {/* <div className="colAnt">
                          <label htmlFor='mcm'>MCM</label>
                          <div>{anthropometry.mcm ?? 'N/A'} kg</div>
                        </div> */}
                      </div>
                    </Accordion>

                    <Accordion title={"Diagnóstico"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor='diagnosis'>Diagnóstico</label>
                          <div>{anthropometry.diagnosis ?? 'N/A'}</div>
                        </div>
                        <div className="colEmpty"></div>
                        <div className="colEmpty"></div>
                      </div>
                    </Accordion>

                    {/* <Accordion title={"Resultado da Avaliação - Todos os campos da tabela"}>
                      <div className="row-getAnthropometry">
                        <div className="colAnt">
                          <label htmlFor="gender">Gênero</label>
                          <div>{formatGender(patientGender)}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="gender">Data de Nascimento</label>
                          <div>{formatDate(patientBirthDate)}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="gender">Idade</label>
                          <div>{calculateAge(patientBirthDate)}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="imc_classification">IMC Classificação</label>
                          <div>{imc} | {imcClassificacao}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="quetelet ">quetelet </label>
                          <div>{quetelet}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="theoretical_weight">Peso Teórico</label>
                          <div>{pesoTeorico} kg</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="classificacaoBernadinelli">Classificação Biotipo - Bernadinelli</label>
                          <div>{classificacaoBernadinelli}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="porcentagemPT">Percentual PT - Blackburn</label>
                          <div>{porcentagemPT} %</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="classificacaoBlackburn">Classificação Nutricional - Blackburn</label>
                          <div>{classificacaoBlackburn}</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="cmb_arm">Circunferência Muscular do Braço</label>
                          <div>{cmb} cm</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="amb_arm">Área Muscular do Braço</label>
                          <div>{amb} cm²</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="ambc_arm">Área Muscular do Braço Corrigido</label>
                          <div>{ambc} cm²</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="arm_area">Área do Braço</label>
                          <div>{ab} mm²</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="agb">Área Gordurosa do Braço</label>
                          <div>{agb} mm²</div>
                        </div>
                        <div className="colAnt">
                          <label htmlFor="amb_arm">Classificação da Musculatura</label>
                          <div>{classificacaoMusculatura}</div>
                        </div>
                        <div className="colAnt">
                          <label>Circunferência da Cintura</label>
                          <div>{waist ? `${waist} cm | ${waistClassification}` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label>VET (Valor Energético Total)</label>
                          <div>{vet ? `${vet} kcal` : 'N/A'}</div>
                        </div>
                        <div className="colAnt">
                          <label>Altura Estimada</label>
                          <div>{estimated_height ? `${estimated_height} cm` : 'N/A'}</div>
                        </div>
                        <div className="colAnt"></div>
                        <div className="colAnt"></div>
                      </div>
                    </Accordion> */}

                  </Accordion>
                );
              })}
            </div>
          </div >
        )
        }

        {
          currentAnthropometry.length > 0 && (
            <Pagination
              totalItems={currentAnthropometry.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              paginate={paginate}
            />
          )
        }
      </div >
    </>
  );
}

export default GetAnthropometryByPatientId;
